import React, { useContext, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SubscriptionsIcon from "@mui/icons-material/YouTube";

import MarkunreadIcon from "@mui/icons-material/Markunread";
import Grid from "@mui/material/Grid";
import AuthContext from "../../../Context/Context";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import styles from "./TopNavbar.module.css";

import Loader from "../../../loader/Loader";
import { Update } from "../../../loader/Update";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import Select from "react-select";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

//import mui styles
const useStyles = makeStyles(() =>
  createStyles({
    editable: {
      textAlign: "center",
      color: "black",
      background: "transparent",
      outline: "0",
      border: "none",
    },

    editableAlign: {
      textAlign: "left",
      color: "black",
      background: "transparent",
      outline: "0",
      border: "none",
      width: "50%",
    },
  })
);

// we use props for pass this properties data to another component.
function Topbar1(props) {
  const ctx = useContext(AuthContext);
  //initialize mui Styles inside component
  const classes = useStyles();

  //this state is for loading component
  const [loading, setloading] = useState(false);
  const [defaultData, setDefaultdata] = useState();

  const [style, setStyle] = React.useState("full");

  //save all data here as object
  const data = {
    backgroundColor: "",
    menucolor: "",
    fontFamily: "",
    email: "campuzone@gmail.com",
    phoneNo: "00-9999-6666",
    twitterLink: "https://www.mockplus.com/blog/post/website-footer-design ",
    instagramLink: "https://www.godaddy.com/en-in ",
    facebookLink: "https://www.shopify.in/",
    linkedinLink: "https://www.flipkart.com/",
    youtubeLink: "https://www.youtube.com/",
  };

  //accessing local data using useState and useContext. initially data is accessed from firebase db.
  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : data
  );
  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  //this function is for edit property.when changes happening on screen this data is updated to localData.
  const onChange = (event) => {
    let val = event.target.value; // here we get input value from user.

    setLocalData((prevState) => {
      // here we update that value to localData.
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div></div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                menucolor: "",
                fontFamily: "",
                backgroundColor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className="my-2">
            Background Styles
          </p>

          <HexColorPicker
            color={localData.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <p variant="p" className="my-2">
            Text Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>

          <HexColorPicker
            color={localData.menucolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                menucolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.menucolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                menucolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              // setCard(defaultData);
              toggleDrawer(e, false);
            } else {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  let editable = (
    <>
      <div
        style={{
          color: localData?.menucolor ? localData?.menucolor : "black",
          background: localData?.backgroundColor
            ? localData?.backgroundColor
            : "F9F9F9",
          fontSize: "20px",
        }}
        className={clsx(
          localData?.fontFamily,
          "row py-3 row justify-content-center"
        )}
      >
        <div className="row border-bottom py-2  mt-2 ">
          <div className="col-md-4 d-flex py-1 ">
            <Grid item xs={1} className={styles.mail}>
              <MarkunreadIcon />
            </Grid>

            <input
              className={classes.editable}
              value={localData.email}
              maxLength={50}
              style={{
                color: localData?.menucolor ? localData?.menucolor : "black",
              }}
              onChange={onChange}
              id="email"
              placeholder="email"
            />
          </div>

          <div className="col-md-4 d-flex py-1">
            <Grid item xs={1} className={styles.phoneedit}>
              <LocalPhoneIcon />
            </Grid>

            <input
              className={classes.editable}
              value={localData.phoneNo}
              style={{
                color: localData?.menucolor ? localData?.menucolor : "black",
              }}
              onChange={onChange}
              id="phoneNo"
              maxLength={15}
              placeholder="phoneNo"
            />
          </div>

          <div className="col-md-4 d-flex  align-item-center py-1 justify-content-md-end">
            {localData.twitterLink !== "" && (
              <div className="mx-1">
                <a href={localData.twitterLink} className={styles.link1}>
                  <TwitterIcon />
                </a>
              </div>
            )}
            {localData.instagramLink !== "" && (
              <div className="mx-1">
                <a href={localData.instagramLink} className={styles.link2}>
                  <InstagramIcon />
                </a>
              </div>
            )}
            {localData.facebookLink !== "" && (
              <div className="mx-1">
                <a href={localData.facebookLink} className={styles.link3}>
                  <FacebookIcon />
                </a>
              </div>
            )}
            {localData.linkedinLink !== "" && (
              <div className="px-1">
                <a href={localData.linkedinLink} className={styles.link4}>
                  <LinkedInIcon />
                </a>
              </div>
            )}
            {localData.youtubeLink !== "" && (
              <div className="mx-1">
                <a href={localData.youtubeLink} className={styles.link5}>
                  <SubscriptionsIcon />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className={clsx(classes.editFont, "col m-1")}>
          <div className="p-3 ">
            <h3 className=" mb-3">Social media Links</h3>
            <span className="mx-2">Twitter Link</span>{" "}
            <input
              type="text"
              id="twitterLink"
              value={localData.twitterLink}
              onChange={onChange}
              className="w-50 form-control"
            ></input>
          </div>

          <div className="p-3 ">
            <span className="mx-2">Instagram Link</span>{" "}
            <input
              type="text"
              id="instagramLink"
              value={localData.instagramLink}
              onChange={onChange}
              className="w-50 form-control"
            ></input>
          </div>

          <div className="p-3 ">
            <span className="mx-2">Facebook Link</span>{" "}
            <input
              type="text"
              id="facebookLink"
              value={localData.facebookLink}
              onChange={onChange}
              className="w-50 form-control"
            ></input>
          </div>

          <div className="p-3 ">
            <span className="mx-2">Linkedin Link</span>{" "}
            <input
              type="text"
              id="linkedinLink"
              value={localData.linkedinLink}
              onChange={onChange}
              className="w-50 form-control"
            ></input>
          </div>
          <div className="p-3 ">
            <span className="mx-2">Youtube Link</span>{" "}
            <input
              type="text"
              id="youtubeLink"
              value={localData.youtubeLink}
              onChange={onChange}
              className="w-50 form-control"
            ></input>
          </div>
        </div>
      </div>
      ;
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success(" Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {" "}
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div className={clsx(classes.modalBox, "")}>
        {ctx.isEditable ? (
          editable
        ) : (
          <div
            style={{
              color: localData?.menucolor ? localData?.menucolor : "black",
              background: localData?.backgroundColor
                ? localData?.backgroundColor
                : "#F9F9F9",
              fontSize: "20px",
            }}
            className={localData?.fontFamily}
          >
            <div className="row border-bottom ml-4 py-2  pt-2 my-0">
              <div
                className={clsx(
                  "col-md-4 d-flex justify-content-md-center py-1",
                  styles.colTp
                )}
              >
                <Grid item xs={1} className={styles.mail}>
                  <MarkunreadIcon />
                </Grid>

                <span className="mx-2">{localData.email} </span>
              </div>

              <div
                className={clsx(
                  "col-md-4 d-flex py-1 justify-content-md-center",
                  styles.colTp
                )}
              >
                <Grid item xs={1} className={styles.phone}>
                  <LocalPhoneIcon />
                </Grid>

                <span className="mx-2 ">{localData.phoneNo}</span>
              </div>
              <div
                className={clsx(
                  "col-md-4 d-flex align-item-center py-1 justify-content-lg-end",
                  styles.colTp
                )}
              >
                {localData.twitterLink !== "" && (
                  <div className="mx-1">
                    <a href={localData.twitterLink} className={styles.link1}>
                      <TwitterIcon />
                    </a>
                  </div>
                )}
                {localData.instagramLink !== "" && (
                  <div className="mx-1">
                    <a href={localData.instagramLink} className={styles.link2}>
                      <InstagramIcon />
                    </a>
                  </div>
                )}
                {localData.facebookLink !== "" && (
                  <div className="mx-1">
                    <a href={localData.facebookLink} className={styles.link3}>
                      <FacebookIcon />
                    </a>
                  </div>
                )}
                {localData.linkedinLink !== "" && (
                  <div className="px-1">
                    <a href={localData.linkedinLink} className={styles.link4}>
                      <LinkedInIcon />
                    </a>
                  </div>
                )}
                {localData.youtubeLink !== "" && (
                  <div className="mx-1">
                    <a href={localData.youtubeLink} className={styles.link5}>
                      <SubscriptionsIcon />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Topbar1;
