import { useState, useContext } from "react";
import AuthContext from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../../services/firebase";
import Loader from "../../loader/Loader";
import { setDoc, doc } from "firebase/firestore";
import Logo from "../../Assests/fickle1.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import validator from "validator";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Bgicon1 } from "../../Assests/xd1.svg";
import { ReactComponent as Bgicon2 } from "../../Assests/xd2.svg";
// import { ReactComponent as Bgicon3 } from "../../Assests/xd3.svg";
import { ReactComponent as Bgicon3 } from "../../Assests/xd4.svg";
const useStyles = makeStyles({
  Bgicon1: {
    position: "fixed",
    top: "33%",
    left: "9%",
  },
  Bgicon2: {
    top: "-10%",
    left: "67%",
    position: "fixed",
  },
  Bgicon3: {
    position: "fixed",
    bottom: "-8%",
    left: "-8%",
  },

  Bgicon4: {
    position: "fixed",
    right: "33%",
    bottom: "6%",
    zoom: "0.6",
    transform: "rotate(204deg)",
  },
  Bgicon5: {
    position: "fixed",
    right: "5%",
    bottom: "27%",
  },

  "@media (max-width: 1200px)": {
    Bg2: {
      left: "-73% ",
    },
  },
});
const Signup = (props) => {
  const classes = useStyles();
  const ctx = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(null);
  const [uid, setUid] = useState();
  const [showpassword, setShowpassword] = useState(false);

  const [userCred, setUserCred] = useState({
    Mail_id: "",
    password: "",
    confirmpassword: "",
    Contestant_name: "",
    websitename: "",
    Domain_name: "",
    Mobile_no: "",
  });

  const navigate = useNavigate();
  const titleCase = (str) => {
    return str
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setloading(true);
    if (userCred.Mail_id === "") {
      setError("Enter Email");
      setloading(false);
    } else if (validator.isEmail(userCred.Mail_id) === false) {
      setError("Enter Valid Email ");
      setloading(false);
    } else if (validator.isMobilePhone(userCred.Mobile_no) === false) {
      setError("Please enter valid mobile number");
      setloading(false);
    } else if (userCred.password === "") {
      setError("Enter Password");
      setloading(false);
    } else if (userCred.password !== userCred.confirmpassword) {
      setError("password and confirm password not match");
      setloading(false);
    } else if (
      userCred.Contestant_name === "" ||
      userCred.websitename === "" ||
      userCred.Mobile_no === "" ||
      userCred.Domain_name === ""
    ) {
      setError("please enter the remaining fields");
      setloading(false);
    } else {
      createUserWithEmailAndPassword(auth, userCred.Mail_id, userCred.password)
        .then((res) => {
          var user = auth.currentUser;

          sendEmailVerification(user).then(() => {});

          setDoc(doc(db, "users", user.uid), {
            Contestant_name: userCred.Contestant_name,
            Mail_id: userCred.Mail_id,
            Mobile_no: userCred.Mobile_no,
            Domain_name: userCred.Domain_name,
            School_name: userCred.websitename,
            password: userCred.password,
          });
          setDoc(doc(db, "layout", user.uid), {
            layout: {},
            pages: [],
          });
          setDoc(doc(db, "websitedata", user.uid), {
            websiteData: {},
            Domain_name: userCred.Domain_name,
            emailcred: {
              from_email: "",
              email_password: "",
            },
          });
          setDoc(doc(db, "formdata", ctx.userId), {
            message: [],
            applicationform: [],
          });
          setTimeout(() => {
            navigate("/login");
            alert("sucessfully registered & Verify your email and login");
            setloading(false);
          }, 3000);
        })
        .catch((error) => {
          setloading(false);
          // const errorCode = error.code;
          // console.log(errorCode, error);
          const message = error.message;
          setError(titleCase(message));
        });
    }
  };
  const onChangeHandler = (event) => {
    event.preventDefault();
    let val = event.target.value;
    setUserCred((prevState) => {
      return {
        ...prevState,
        [event.target.name]: val,
      };
    });
  };
  return (
    <>
      {loading && (
        <>
          <Loader />
        </>
      )}
      <div className=" Login-Edit overlay" style={{ height: "100vh" }}>
        <Bgicon1 className={classes.Bgicon1} />
        <Bgicon1 className={classes.Bgicon2} />
        <Bgicon3 className={classes.Bgicon3} />
        <Bgicon3 className={classes.Bgicon4} />
        <Bgicon3 className={classes.Bgicon5} />

        <nav className="navbar navbar-expand-lg navbar-light  headercol ">
          <a className="navbar-brand position-relative" href="#">
            <img
              style={{ width: "8%" }}
              className="img-thumbnail d-inline"
              src={Logo}
              alt=""
            />

            <h2
              style={{ top: "0", bottom: "0", left: "0", right: "0" }}
              className=" mt-2 text-white position-absolute   Login-name text-center"
            >
              Fickle
            </h2>
          </a>
        </nav>

        <div className="row">
          <div className="col-md-5 marg">
            <div className="col-md-7 m-auto">
              <h2 className="content text-center">
                Your
                <br /> Business
                <br /> At
                <br /> Your
                <br /> Fingertips
              </h2>
            </div>
          </div>
          <div className="col-md-7   ">
            <div className="shadow">
              <div className="mt-4 modal-signup" style={{ maxWidth: "562px" }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Hello Buddy!</h4>
                  </div>
                  <div className="modal-body">
                    <form
                      action="/examples/actions/confirmation.php"
                      method="post"
                    >
                      <div className="row">
                        <div className="form-group col-sm-6 ">
                          <input
                            type="text"
                            name="Contestant_name"
                            required="required"
                            autocomplete="off"
                            maxLength={30}
                            className="form-control"
                            value={userCred.Contestant_name}
                            id="Inputusername"
                            placeholder="Contestant_name"
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <input
                            type="text"
                            name="Domain_name"
                            required="required"
                            maxLength={50}
                            className="form-control"
                            value={userCred.Domain_name}
                            autocomplete="off"
                            id="exampleInputschoolname"
                            placeholder="Domain Name"
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className="form-group col-sm-12">
                          <input
                            type="text"
                            name="websitename"
                            required="required"
                            className="form-control"
                            maxLength={50}
                            value={userCred.websitename}
                            autocomplete="off"
                            id="exampleInputschoolname"
                            placeholder="company / Institute Name"
                            onChange={onChangeHandler}
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <input
                            type="email"
                            name="Mail_id"
                            required="required"
                            value={userCred.Mail_id}
                            maxLength={50}
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            autocomplete="off"
                            onChange={onChangeHandler}
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <input
                            type="text"
                            name="Mobile_no"
                            maxLength="10"
                            required="required"
                            className="form-control"
                            value={userCred.Mobile_no}
                            id="exampleInputschoolname"
                            placeholder="Phone no"
                            autocomplete="off"
                            onChange={onChangeHandler}
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <input
                            type={showpassword ? "text" : "password"}
                            name="password"
                            required="required"
                            maxLength={20}
                            className="form-control"
                            value={userCred.password}
                            id="InputPassword1"
                            placeholder="Password"
                            onChange={onChangeHandler}
                            autocomplete="off"
                          />
                        </div>
                        <div className="form-group col-sm-6 ">
                          <input
                            autocomplete="off"
                            type={showpassword ? "text" : "password"}
                            name="confirmpassword"
                            required="required"
                            maxLength={20}
                            className="form-control"
                            value={userCred.confirmpassword}
                            id="exampleInputPassword1"
                            placeholder="Confirm Password"
                            onChange={onChangeHandler}
                          />
                        </div>

                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked={showpassword} />}
                            label="Show Password"
                            onChange={() => setShowpassword(!showpassword)}
                          />
                        </FormGroup>

                        {error && (
                          <small className="text-danger text-right d-block pt-2">
                            {error}
                          </small>
                        )}
                      </div>

                      <div className="form-group  text-center">
                        <button
                          onClick={handleSignup}
                          type="submit"
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            borderRadius: "20px",
                            boxShadow: "0 3px 6px #00000036",
                          }}
                          className="btn mt-3 px-5 btntclr "
                        >
                          Register Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <img src="https://s3-alpha.figma.com/hub/file/314494079/976a59d3-cf17-4dcc-a3d8-6651da344ee4-cover" /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Signup;
