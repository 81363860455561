import React, { useContext, useState } from "react";
import styles from "./Footer.module.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import { createStyles, makeStyles } from "@mui/styles";

import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";
import clsx from "clsx";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#555",
    },
    editable: {
      textAlign: "center",
      color: "white",
      background: "transparent",
      outline: "0",
      border: "none",
    },

    "@media (max-width: 600px)": {
      col: {
        width: "100%",
      },
    },
  })
);

const Footer2 = (props) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const data = {
    header: "Copyrights©Divicare",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
  };

  let pageData = ctx.websiteData[ctx.selectedPage];

  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : data
  );
  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const onChangeHandler = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div></div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Footer background
          </p>

          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Footer Text Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData.f}
            onChange={(e) =>
              setLocalData({
                ...localData,
                f: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  let editable = (
    <>
      <input
        onChange={(e) => onChangeHandler(e)}
        className={clsx(classes.editable, localData.f)}
        style={{
          color: localData?.headerColor,
          width: "75%",
          fontSize: "30px",
        }}
        id="header"
        placeholder="header"
        value={localData.header}
      />
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success(" Sucessfully Updated");
    }, 2000);
  };
  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <footer>
        <div
          className={styles.ftcol}
          style={{ background: localData?.backgroundColor }}
        >
          {ctx.isEditable ? (
            editable
          ) : (
            <>
              <h2
                className={localData?.fontFamily}
                style={{ color: localData?.headerColor }}
              >
                {localData.header}
              </h2>
            </>
          )}
        </div>
      </footer>
    </>
  );
};

export default Footer2;
