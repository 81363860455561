import React, { useContext, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import Loader from "../../../loader/Loader";
import HeaderLogo from "../../../Assests/images/headerlogo.png";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import Select from "react-select";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import AuthContext from "../../../Context/Context";
import clsx from "clsx";
import { Update } from "../../../loader/Update";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import $ from "jquery";
import FormControl from "@mui/material/FormControl";

import { useLocation } from "react-router-dom";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const useStyles = makeStyles(() =>
  createStyles({
    rootNav: {
      backgroundColor: "#fff",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2rem",
      // boxShadow: "0px 3px 17px #fff5ff",
    },
    logoContainer: {
      width: "10%",
      position: "relative",
      "& img": {
        width: "100%",
        height: "4rem",
        objectFit: "contain",
      },
    },
    menuItems: {
      fontItems: "0.9rem",
      color: "#000",

      textTransform: "uppercase",
      padding: "0.5rem 0.75rem",
      marginRight: "0.5rem",
      marginLeft: "40px",
    },
    menuList: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      width: "65%",
    },
    menuItems: {
      fontItems: "0.9rem",
      color: "#000",

      textTransform: "uppercase",
      padding: "0.5rem 0.75rem",
      marginRight: "0.5rem",
    },
    inputHeader: {
      width: "100%",
      background: "none",
      border: 0,
      outline: 0,
      fontItems: "0.9rem",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    pathSelector: {
      padding: "1rem 0",
    },
    btndeco: {
      width: "20px",
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      padding: "1rem",
      "& p": {
        fontSize: "1.2rem",
        width: "15%",
        textTransform: "uppercase",
        color: "#000",
      },
    },

    "@media (max-width: 600px)": {
      rootNav: {
        padding: "0",
        paddingLeft: "2px",
      },
      menuItem: {
        fontSize: "8px",
      },
      logoContainer: {
        width: "25%",
      },
    },
  })
);

export const Navbar2 = (props) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const location = useLocation();
  const [defaultData, setDefaultdata] = useState();

  const [style, setStyle] = React.useState("full");
  const headerData = {
    logo: HeaderLogo,
    backgroundColor: "#fff",
    menucolor: "black",
    hoverColor: "violet",
    fontFamily: "",
    iconColor: "black",
    sgubmenubackround: "white",
    submenucolor: "black",
    menuItem: [
      {
        label: "Home",
        path: "",
        id: 1,
      },
      {
        label: "About",
        path: "",
        id: 2,
      },
      {
        label: "Women",
        path: "",
        id: 3,
      },

      {
        label: "DropDown1",
        dropdown: true,
        id: 4,
        path: "",
        subMenu: [
          {
            label: "Sub Menu1",
            path: "",
            id: 1281576200,
          },
          {
            label: "Sub Menu2",
            path: "",
            id: 1253036033,
          },
        ],
      },
      {
        label: "DropDown2",
        dropdown: true,
        path: "",
        id: 5,
        subMenu: [
          {
            label: "Sub Menu1",
            path: "",
            id: 1783393659,
          },
          {
            label: "Sub Menu2",
            path: "",
            id: 1165461734,
          },
        ],
      },
    ],
  };
  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined
      ? headerData
      : ctx.websiteData[props.id]
  );
  const [pageArray, setPageArray] = useState([]);
  const [menuData, setMenuData] = useState({});
  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      // setloading(true);
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? headerData : pageData[props.id]
      );
      // setData(pageData).then(
      //   (onResolved) => {
      //     setloading(false);
      //   },
      //   (onRejected) => {
      //     // Some task on failure
      //   }
      // );
    }
  }, [ctx.selectedPage]);

  const [menuItem, setMenuItem] = useState(localData?.menuItem);

  // function setData(pageData) {
  //   setLocalData(
  //     pageData[props.id] === undefined ? headerData : pageData[props.id]
  //   );
  //   return Promise.resolve("Success");
  // }

  React.useEffect(() => {
    setMenuItem(localData?.menuItem);
  }, [localData?.menuItem]);

  // React.useEffect(() => {
  //   if (ctx.selectedPage !== "") {
  //     if (ctx.layoutData[ctx.selectedPage] !== undefined) {
  //       var tempArray = ctx.pageFlow?.map(function (mopt) {
  //         var info = {
  //           value: mopt,
  //           label: mopt,
  //         };
  //         return info;
  //       });
  //       setPageArray(tempArray);
  //     }
  //   }
  // }, []);

  React.useEffect(() => {
    if (ctx.selectedPage !== "") {
      if (ctx.pageState !== "single") {
        if (ctx.layoutData[ctx.selectedPage] !== undefined) {
          let newData = {};
          var layout = Object.keys(ctx.layoutData);

          for (const objData of layout) {
            var newObj = ctx.layoutData[objData];
            var tempArray = newObj.map(function (mopt) {
              var info = {
                value: mopt.uniqId,
                label: rename(mopt.uniqId),
              };
              return info;
            });

            newData[objData] = tempArray;
          }
          setMenuData(newData);
        }
      }
    }
  }, [ctx.selectedPage, ctx.layoutData, ctx.pageState]);

  React.useEffect(() => {
    if (ctx.selectedPage !== "") {
      if (ctx.layoutData[ctx.selectedPage] !== undefined) {
        if (ctx.pageState === "single") {
          var newObj = ctx.layoutData[ctx.selectedPage];
          var tempArray = newObj.map(function (mopt) {
            var info = {
              value: mopt.uniqId,
              label: rename(mopt.uniqId),
            };
            return info;
          });

          setPageArray(tempArray);
        } else if (
          ctx.pageState === "multiple" ||
          ctx.pageState === undefined
        ) {
          var tempArray = ctx.pageFlow?.map(function (mopt) {
            var info = {
              value: mopt,
              label: mopt,
            };
            return info;
          });
          setPageArray(tempArray);
        }
      }
    }
  }, [ctx.pageState, ctx.selectedPage]);

  const [menu, setMenu] = useState("");
  const rename = (name, i) => {
    let firstName = name.substring(0, name.length - 2);
    let lastName = name.charAt(name.length - 1);
    let newName = firstName + " " + lastName;
    return newName;
  };

  // const menuArray = ["Menu", "Submenu"];
  const onImageChange = (e, i) => {
    // setError(null);

    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;

    if (!selected) {
      // setError("Please select file");
      return;
    }

    if (!selected.type.includes("image")) {
      // setError("Please select image file");
      return;
    }
    if (imagesize < ctx.user?.Image_size) {
      const storage = getStorage();
      const uploadPath = ctx.userId + "/nav2f54656"; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data

      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            return {
              ...prevState,
              logo: url,
            };
          });
        });
      });
    } else {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    }

    // setError(null);
  };
  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.name] = e.target.value;
    } else {
      tempEventInputs["path"] = e.value;
    }
    setMenuItem((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  // submenu
  const onSubMenuChangeHandler = (e, details, index, item, itemIndex) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.name] = e.target.value;
    } else {
      tempEventInputs["path"] = e.value;
    }
    item.subMenu[index] = tempEventInputs;
    setMenuItem((prevState) => {
      prevState[itemIndex] = item;
      return [...prevState];
    });
  };

  // Menu  Add function
  function addMenu() {
    let d = new Date();
    let updatedData = null;
    if (menu === "") {
      toast.warn("Select Menu Type");
    } else if (menu === "menu") {
      updatedData = {
        label: "Menu",
        path: "",
        id: menuItem.length + 1,
      };
      setMenuItem((prevState) => {
        return [...prevState, updatedData];
      });
    } else {
      updatedData = {
        label: "DropDown",
        dropdown: true,
        id: menuItem.length + 1,
        subMenu: [],
      };
      setMenuItem((prevState) => {
        return [...prevState, updatedData];
      });
    }
  }

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                backgroundColor: "#fff",
                menucolor: "black",
                hoverColor: "violet",
                fontFamily: "",
                iconColor: "black",
                sgubmenubackround: "white",
                submenucolor: "black",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Navbar background
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Menu Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData?.menucolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                menucolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.menucolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                menucolor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <small>Active Color</small>
          <HexColorPicker
            color={localData.hoverColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                hoverColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.hoverColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                hoverColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <small>Icon Color</small>
          <HexColorPicker
            color={localData.iconColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                iconColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.iconColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                iconColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />

          <p variant="p" className=" my-2">
            Submenu style
          </p>
          <small>Submenu Background</small>
          <HexColorPicker
            color={localData.submenubackground}
            onChange={(color) =>
              setLocalData({
                ...localData,
                sgubmenubackround: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.sgubmenubackround}
            onChange={(e) => {
              setLocalData({
                ...localData,
                sgubmenubackround: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <small>Submenu color</small>
          <HexColorPicker
            color={localData.submenucolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                submenucolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.submenucolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                submenucolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  // sub menu  Remove function
  const removeMenu = (id) => {
    setMenuItem((prevState) => {
      prevState = prevState.filter((item) => item.id !== id);
      return [...prevState];
    });
  };
  // sub menu  Add function
  const addSubmenu = (item, subMenu, index) => {
    let d = new Date();
    let updatedData = {
      label: "Sub Menu",
      path: "",
      id: d.getTime(),
    };

    setMenuItem((prevState) => {
      prevState[index].subMenu = [...subMenu, updatedData];
      return [...prevState];
    });
  };

  // sub menu  Remove function
  const removeSubmenu = (item, subMenu, index, id) => {
    setMenuItem((prevState) => {
      prevState[index].subMenu = subMenu.filter((x) => x.id !== id);
      return [...prevState];
    });
  };

  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      menuItem: menuItem,
    };
    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "white",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && <Loader />}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <nav
        className={clsx(
          classes.rootNav,
          "navbar navbar-expand-lg  navbar-light px-2 "
        )}
        style={{
          position: "sticky",
          top: "0px",
          position: "-webkit-sticky",
          background: localData?.backgroundColor,
        }}
      >
        <div className={classes.logoContainer}>
          <img src={localData.logo} alt="headerLogo" />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={clsx(
            "collapse navbar-collapse text-center ",
            localData?.fontFamily
          )}
          id="navbarTogglerDemo01"
        >
          {menuItem.map((item, index) =>
            item.dropdown ? (
              <div className="nav-item mx-2 dropdown" key={index}>
                <span
                  onClick={() => {
                    if (ctx.pageState !== "single") {
                      if (!location.pathname.includes("layout")) {
                        if (ctx.pageFlow.includes(item.path))
                          ctx.setLoading(true);

                        ctx.updateSelectedPage(item.path);
                      }
                    }
                  }}
                  key={index}
                  className={clsx(
                    "nav-link mx-0 p-0 d-inline",
                    classes.menuItems
                  )}
                  style={
                    ctx.selectedPage === item.path
                      ? { color: localData.hoverColor, cursor: "pointer" }
                      : { color: localData?.menucolor, cursor: "pointer" }
                  }
                >
                  {item.label}
                </span>
                <a
                  className={clsx(
                    "nav-link dropdown-toggle p-0 d-inline",
                    classes.menuItems
                  )}
                  id="navbardrop"
                  data-toggle="dropdown"
                  style={{ color: localData?.iconColor }}
                ></a>

                <div
                  className="dropdown-menu"
                  style={{ background: localData?.sgubmenubackround }}
                >
                  {item.subMenu.map((data, subIndex) => (
                    <a
                      key={subIndex}
                      className="dropdown-item"
                      onClick={() => {
                        if (ctx.pageState !== "single") {
                          if (!location.pathname.includes("layout")) {
                            if (ctx.pageFlow.includes(item.path)) {
                              ctx.setLoading(true);
                              ctx.updateSelectedPage(item.path);
                            }
                          }
                        }
                      }}
                      style={{ color: localData?.submenucolor }}
                      href={
                        location.pathname.includes("layout")
                          ? "#"
                          : `#${data.path}`
                      }
                    >
                      {data.label}
                    </a>
                  ))}
                </div>
              </div>
            ) : (
              // ui for dropdown
              <div key={index}>
                {ctx.pageState === "single" ? (
                  <HashLink
                    to={
                      location.pathname.includes("layout")
                        ? "#"
                        : `#${item.path}`
                    }
                  >
                    <p
                      style={
                        ctx.selectedPage === item.path
                          ? { color: localData.hoverColor, cursor: "pointer" }
                          : { color: localData?.menucolor, cursor: "pointer" }
                      }
                      className={classes.menuItems}
                    >
                      {item.label}
                    </p>
                  </HashLink>
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!location.pathname.includes("layout")) {
                        if (ctx.pageFlow.includes(item.path))
                          ctx.setLoading(true);
                        ctx.updateSelectedPage(item.path);
                      }
                    }}
                    key={index}
                  >
                    <p
                      style={
                        ctx.selectedPage === item.path
                          ? { color: localData.hoverColor, cursor: "pointer" }
                          : { color: localData?.menucolor, cursor: "pointer" }
                      }
                      className={classes.menuItems}
                    >
                      {item.label}
                    </p>
                  </span>
                )}
              </div>
            )
          )}
        </div>
      </nav>

      {ctx.isEditable ? (
        <>
          <div className={classes.rootNav}>
            <div className={classes.logoContainer}>
              <input
                type="file"
                onChange={(e) => onImageChange(e)}
                className={classes.inputFile}
                id="logo"
                name="logo"
              />
              <label className={classes.inputLabel} htmlFor="logo">
                <i className="fa fa-upload"></i>
              </label>
              <img src={localData?.logo} alt="headerLogo" />
            </div>
          </div>
          <InputLabel id="demo-simple-select-helper-label">
            Select Menu
          </InputLabel>
          <div className="d-flex">
            <div className="w-50">
              <select
                className="form-select form-control"
                aria-label="Default select example"
                onChange={(e) => {
                  setMenu(e.target.value);
                }}
              >
                <option>Choose Your Menu</option>
                <option value="menu">Menu</option>
                <option value="dropdownmenu">Dropdown Menu</option>
              </select>
            </div>
            <div>
              <button
                disabled={menuItem?.length === 5}
                className="btn text-white mx-2"
                style={{
                  background: "#9e3a8ccc",
                }}
                onClick={addMenu}
              >
                Add menu
              </button>
            </div>
          </div>

          <div className={classes.pathSelector}>
            {menuItem.map((item, index) =>
              item.dropdown ? (
                <div key={index}>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() => addSubmenu(item, item.subMenu, index)}
                  >
                    Add SubMenu
                  </button>
                  <div className={clsx(classes.row, "flex-wrap")} key={index}>
                    <Typography>
                      {" "}
                      <i
                        className="fa fa-minus-square mx-2"
                        onClick={() => removeMenu(item.id)}
                      ></i>
                      <div className={classes.menuItems}>
                        <input
                          className={classes.inputHeader}
                          id="label"
                          name="label"
                          onChange={(e) => onChangeHandler(e, item, index)}
                          value={item.label}
                        />
                      </div>
                    </Typography>
                    <div
                      className={clsx(classes.btndeco, "form-group col-md-4")}
                    >
                      <Select
                        className="form-control"
                        id="path"
                        name="path"
                        options={pageArray}
                        value={pageArray.filter(function (option) {
                          return option.value === item.path;
                        })}
                        onChange={(e) => onChangeHandler(e, item, index)}
                      />
                    </div>
                  </div>
                  <div
                    className={clsx(
                      classes.row,
                      "flex-wrap border-bottom mb-3"
                    )}
                  >
                    {item.subMenu.map((subItem, i) => (
                      <div
                        className="pl-0 pr-3"
                        style={{ width: "17%" }}
                        key={i}
                      >
                        {/* <Typography className="w-75">{subItem.label}</Typography> */}
                        <input
                          className={classes.inputHeader}
                          id="label"
                          name="label"
                          onChange={(e) =>
                            onSubMenuChangeHandler(e, subItem, i, item, index)
                          }
                          value={subItem.label}
                        />

                        <i
                          className="fa fa-minus-square mx-2"
                          onClick={() =>
                            removeSubmenu(item, item.subMenu, index, subItem.id)
                          }
                        ></i>
                        <Select
                          id="path"
                          name="path"
                          options={
                            ctx.pageState === "single"
                              ? pageArray
                              : menuData[item.path]
                          }
                          value={
                            ctx.pageState === "single"
                              ? pageArray?.filter(function (option) {
                                  return option.value === subItem.path;
                                })
                              : menuData[item.path]?.filter(function (option) {
                                  return option.value === subItem.path;
                                })
                          }
                          onChange={(e) =>
                            onSubMenuChangeHandler(e, subItem, i, item, index)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  className={clsx("mb-3 border-bottom", classes.row)}
                  key={index}
                >
                  <Typography>
                    {" "}
                    <i
                      className="fa fa-minus-square mx-2"
                      onClick={() => removeMenu(item.id)}
                    ></i>
                    <input
                      className={classes.inputHeader}
                      id="label"
                      name="label"
                      onChange={(e) => onChangeHandler(e, item, index)}
                      value={item.label}
                    />
                  </Typography>
                  <div className={clsx(classes.btndeco, "form-group col-md-4")}>
                    <Select
                      className="form-control"
                      id="path"
                      name="path"
                      options={pageArray}
                      value={pageArray?.filter(function (option) {
                        return option.value === item.path;
                      })}
                      onChange={(e) => onChangeHandler(e, item, index)}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
