import axios from "axios";
const axiosInstance = axios.create();

if (!!localStorage.getItem("FickleAdminAccess"))
  axiosInstance.defaults.headers.common["Authorization"] =
    "Bearer" + " " + atob(localStorage.getItem("FickleAdminAccess"));

export default axiosInstance;

export const logout = () => {
  axiosInstance.defaults.headers.common["Authorization"] = "";
  localStorage.clear();
  window.location.reload(true);
};

export const DATE_FORMAT = "YYYY-MM-DD hh:mm:ss A";
