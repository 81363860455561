import React, { useCallback, useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AuthContext from "../../Context/Context";
import { db } from "../../services/firebase";
import { doc, updateDoc } from "firebase/firestore";
import Loader from "../../loader/Loader";
import { NavLink } from "react-router-dom";
import { useDrop } from "react-dnd";
import clsx from "clsx";
import WebFont from "webfontloader";
import { toast } from "react-toastify";
import { ReactComponent as DeleteIcon } from "../../Assests/delete.svg";
import { Layout } from "../../utilitis/Layout";

import { createStyles, makeStyles } from "@mui/styles";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Switch,
  Typography,
} from "@mui/material";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import { HexColorPicker } from "react-colorful";
import { Box } from "@mui/system";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const useStyles = makeStyles(() =>
  createStyles({
    modalBox: {
      position: "fixed",
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      width: "650px",
      margin: "auto",
      height: "fit-content",
      zIndex: "9999",
      borderRadius: "10px",
    },
    backDrop: {
      position: "fixed",
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      height: "170vh",
      width: "100%",
      background: " rgba(0, 0, 0, 0.5)",
      zIndex: "9999",
    },
    btnStyle: {
      background: "#9e3a8ccc",
      color: "#fff",
      borderRadius: "20px",
      boxShadow: "0 3px 6px #00000036",
      transform: "scale(1.4)",
    },
  })
);

export const DeleteModal = (props) => {
  const {
    submitHandler = () => {},
    onClickHandler = () => {},
    id,
    deleteHandler = () => {},
    addpage,
    label,
    page,
    selectedPage,
  } = props;
  const classes = useStyles();
  const [modalvalue, setModalvalue] = React.useState("");

  // function to remove characters and numbers
  function removeSpecialCharacter(str) {
    let s = str.split("");
    let j = 0;
    for (let i = 0; i < s.length; i++) {
      // Store only valid characters
      if ((s[i] >= "A" && s[i] <= "Z") || (s[i] >= "a" && s[i] <= "z")) {
        s[j] = s[i];
        j++;
      }
    }
    return s.join("").substring(0, j);
  }

  return (
    <>
      <div className={classes.backDrop}>
        <div
          className={clsx(classes.modalBox, "px-2 text-center  bg-white py-5")}
        >
          {addpage ? (
            <div className="px-3">
              <h3
                style={{
                  textAlign: "left",
                  color: "grey",
                  fontFamily: "Raleway",
                }}
              >
                Enter page name
              </h3>

              <input
                className="form-control my-4"
                placeholder={label}
                onChange={(e) => {
                  setModalvalue(e.target.value);
                }}
                maxLength={20}
                style={{
                  fontSize: "20px",
                  height: "50px",
                }}
              />

              <button
                className={clsx("btn mx-5 px-5 py-1", classes.btnStyle)}
                onClick={() => onClickHandler(modalvalue)}
              >
                Save
              </button>
              <button
                className={clsx("btn mx-5 px-5 py-1", classes.btnStyle)}
                onClick={submitHandler}
              >
                Cancel{" "}
              </button>
            </div>
          ) : (
            <>
              <h3 className="p-3 m-4">
                Are you sure want to delete{" "}
                <span
                  style={{
                    color: "rgba(158, 58, 140, 0.8)",
                    textTransform: "uppercase",
                    fontFamily: "Raleway",
                  }}
                >
                  {page ? id : removeSpecialCharacter(id)}
                </span>{" "}
                {page ? "page ?" : "component ?"}
              </h3>
              <button
                className={clsx("btn mx-5 px-5 py-1", classes.btnStyle)}
                onClick={() => deleteHandler(id, selectedPage)}
              >
                Yes{" "}
              </button>
              <button
                className={clsx("btn mx-5 px-5 py-1", classes.btnStyle)}
                onClick={submitHandler}
              >
                No{" "}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const List = (props) => {
  const { onClickHandler = () => {}, style } = props;
  const ctx = useContext(AuthContext);

  const [defaultData, setDefaultdata] = useState(
    ctx.siteSettings ? ctx.siteSettings : {}
  );
  const siteLogoHandler = (file) => {
    ctx.setLoading(true);
    const storage = getStorage();
    const uploadPath = ctx.userId + "/sitelogo";
    const storageRef = ref(storage, uploadPath); // crea
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(storageRef).then((url) => {
        setDefaultdata((prevState) => {
          return {
            ...defaultData,
            Logo: url,
          };
        });
        ctx.setLoading(false);
      });
    });
  };

  console.log("ppppp test");
  const backgroundHanler = () => {
    ctx.setLoading(true);
    updateDoc(doc(db, "websitedata", ctx.userId), {
      siteSettings: defaultData,
    }).then((res) => {
      ctx.getWebsiteData();
      ctx.setLoading(false);
      toast.success("Successfully Updated");
    });
  };

  return (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setDefaultdata({
                siteBackground: "white",
                Logo: "",
                Title: "Fickle",
                Description: "",
                Keywords: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />

          <p variant="p" className=" my-2">
            Site background
          </p>

          <HexColorPicker
            color={defaultData?.siteBackground}
            onChange={(color) =>
              setDefaultdata((prevState) => {
                return {
                  ...defaultData,
                  siteBackground: color,
                };
              })
            }
          />
          <input
            className="form-control my-2"
            value={defaultData?.siteBackground}
            maxLength={10}
            onChange={(e) =>
              setDefaultdata((prevState) => {
                return {
                  ...defaultData,
                  siteBackground: e.target.value,
                };
              })
            }
          />

          <Divider />

          <p variant="p" className=" my-2">
            Site Settings
          </p>
          <small>Site Title</small>

          <input
            className="form-control my-2"
            value={defaultData?.Title}
            maxLength={25}
            onChange={(e) =>
              setDefaultdata((prevState) => {
                return {
                  ...defaultData,
                  Title: e.target.value,
                };
              })
            }
          />

          <small>Site Logo</small>
          <div className="d-flex m-2">
            <Avatar alt="fickle" src={defaultData?.Logo} />
          </div>
          <input
            className="form-control my-2"
            // value={ctx.siteSettings?.Title}
            type="file"
            maxLength={25}
            onChange={(e) => siteLogoHandler(e.target.files[0])}
          />
          <small>Site Description</small>

          <textarea
            className="form-control my-2"
            value={defaultData?.Description}
            type="text"
            maxLength={200}
            rows={4}
            onChange={(e) =>
              setDefaultdata((prevState) => {
                return {
                  ...defaultData,
                  Description: e.target.value,
                };
              })
            }
          />
          <small>Site Keywords</small>

          <textarea
            className="form-control my-2"
            value={defaultData?.Keywords}
            type="text"
            rows={4}
            onChange={(e) =>
              setDefaultdata((prevState) => {
                return {
                  ...defaultData,
                  Keywords: e.target.value,
                };
              })
            }
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setDefaultdata(ctx.siteSettings);
            onClickHandler(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            onClickHandler(e, false);
            backgroundHanler();
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
};
const Preview = () => {
  const classes = useStyles();
  const ctx = useContext(AuthContext);
  const [mountedData, setMountedData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(
    ctx.pageFlow?.length > 0 ? ctx.pageFlow[0] : ""
  );
  const [modalstate, setModalstate] = useState(false);
  const [addPage, setAddpage] = useState(false);
  const [deletePage, setDeletepage] = useState(false);
  const [loading, setloading] = useState(false);
  const [id, setId] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [pageState, setPageState] = useState("single");
  const [websiteData, setWebsiteData] = useState({});

  const [style, setStyle] = React.useState("full");

  useEffect(() => {
    setSelectedPage(ctx.pageFlow?.length > 0 ? ctx.pageFlow[0] : "");
  }, [ctx.pageFlow]);

  useEffect(() => {
    setWebsiteData(ctx.websiteData);
  }, [ctx.websiteData]);

  useEffect(() => {
    setPageState(ctx.pageState ? ctx.pageState : "single");
  }, [ctx.pageState]);

  useEffect(() => {
    let pageData = [];
    if (ctx.selectedPage !== "") {
      if (ctx.layoutData[selectedPage] !== undefined) {
        pageData = ctx.layoutData[selectedPage];
      }
    }

    let newArr = [];
    for (var i = 0; i < pageData.length; i++) {
      let tempArr = Layout.filter((x) => pageData[i].id === x.id);
      newArr = newArr.concat(tempArr);
    }

    let finalArray = newArr?.map((item, i) =>
      Object.assign({}, item, pageData[i])
    );
    setMountedData(finalArray);
  }, [selectedPage, ctx.selectedPage, ctx.layoutData]);

  const [{ isOver }, drop] = useDrop({
    accept: "Object",

    drop: (item, monitor) => {
      if (mountedData.map((x) => x.uniqId !== item.uniqId)) {
        setMountedData((prevState) => {
          return [...prevState, item];
        });
        ctx.addLayout(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleOnDragEnd = (movedItem) => {
    const newArr = Array.from(mountedData);
    const [reorderedItem] = newArr.splice(movedItem.source.index, 1);
    newArr.splice(movedItem.destination.index, 0, reorderedItem);
    setMountedData(newArr);
    // ctx.updateLayout(newArr);
  };

  const CreateComponent = ({ component, id }) => {
    const Component = component;
    return <Component id={id} />;
  };

  function dataHandler(data) {
    setModalstate(true);
    setId(data);
  }

  const deleteHandler = (id, page) => {
    setMountedData((prevState) => {
      prevState = prevState.filter((item) => item.uniqId !== id);
      // ctx.updateLayout(prevState);
      return [...prevState];
    });

    if (websiteData[page] !== undefined) {
      let tempArr = websiteData[page];
      if (tempArr[id] !== undefined) {
        delete tempArr[id];
        setWebsiteData((prevState) => {
          prevState[page] = tempArr;
          return {
            ...prevState,
          };
        });
      }
    }

    //   if (ctx.layoutData[page] !== undefined) {
    //     let tempArr = ctx.layoutData[page];
    //     let updateData = tempArr.filter((item) => item.uniqId !== id);
    //     ctx.setLayoutData((prevState) => {
    //       prevState[page] = updateData;
    //       return {
    //         ...prevState,
    //       };
    //     });
    //   }
    setModalstate(false);
  };

  // create page
  const createPage = (value) => {
    if (ctx.pageFlow.length === 5) {
      return;
    }
    if (ctx.pageFlow.length === 0) {
      updateDoc(doc(db, "layout", ctx.userId), { pages: ["home"] });
      updateDoc(doc(db, "layout", ctx.userId), { layout: { home: [] } });
      setloading(true);
      ctx.getLayoutData();
      setTimeout(() => {
        toast.success("Page created successfully");
        setloading(false);
      }, 1500);
    } else {
      if (value === "") {
        toast.warning("Field are empty !");
      } else if (ctx.pageFlow.includes(value.toLocaleLowerCase())) {
        toast.warning("Name already exists !");
        return;
      } else {
        var newPageArr = ctx.pageFlow.concat(value.toLocaleLowerCase());
        const obj = newPageArr.reduce((accumulator, value) => {
          return { ...accumulator, [value]: [] };
        }, {});
        var newObj = Object.assign(obj, ctx.layoutData);
        updateDoc(doc(db, "layout", ctx.userId), { pages: newPageArr });
        updateDoc(doc(db, "layout", ctx.userId), { layout: newObj });
        ctx.getLayoutData();
        setAddpage(false);
        setloading(true);
        setTimeout(() => {
          toast.success("Page created successfully");
          setloading(false);
        }, 1500);
      }
    }
  };

  useEffect(() => {
    if (ctx.pageFlow?.length <= 1) {
      if (ctx.pageState === "multiple") {
        setPageState("single");
        updateDoc(doc(db, "layout", ctx.userId), {
          pageState: ctx.pageFlow?.length <= 1 ? "single" : "multiple",
        });

        setTimeout(() => {
          ctx.getLayoutData();
        }, 2000);
      }
    } else {
      if (ctx.pageState === "single") {
        setPageState("multiple");
        updateDoc(doc(db, "layout", ctx.userId), {
          pageState: ctx.pageFlow?.length <= 1 ? "single" : "multiple",
        });

        setTimeout(() => {
          ctx.getLayoutData();
        }, 2000);
      }
    }
  }, [ctx.pageFlow]);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const onSaveHandler = () => {
    setloading(true);
    // ctx.updateLayout(mountedData);

    var tempArr = [];
    for (var i = 0; i < mountedData.length; i++) {
      let newData = {
        id: mountedData[i].id,
        uniqId: mountedData[i].uniqId,
      };

      tempArr = tempArr.concat(newData);
    }
    let obj = Object.assign(ctx.layoutData, { [selectedPage]: tempArr });
    // upadte in db
    updateDoc(doc(db, "layout", ctx.userId), {
      layout: obj,
      pageState: ctx.pageFlow.length === 1 ? "single" : pageState,
    });
    updateDoc(doc(db, "websitedata", ctx.userId), {
      websiteData: websiteData,
    });
    setTimeout(() => {
      setloading(false);
      ctx.getLayoutData();
      ctx.getWebsiteData();
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Raleway"],
      },
    });
  }, []);
  // delete page
  const deletePageHandler = (pageId) => {
    setDeletepage(false);
    ctx.deletePage(pageId);
    setloading(true);
    setTimeout(() => {
      ctx.getLayoutData();
    }, 1000);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Deleted");
    }, 2000);
  };

  const NoLayout = () => {
    return (
      <div
        className="p-4"
        style={{
          boxShadow: "0px 3px 6px #00000036",
          borderRadius: "5rem",
          overflow: "hidden",
        }}
      >
        <h2 className="text-center">Drag and Drop Here</h2>
        <div className="d-flex justify-content-center">
          <lottie-player
            src="https://assets6.lottiefiles.com/packages/lf20_pgeevipp.json"
            background="transparent"
            speed="1"
            style={{
              width: "500px",
              height: "500px",
              transform: "scale(1.8)",
              fontfamily: "raleway",
            }}
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Loader />
        </>
      )}
      <div
        className="col-10 m-0 pt-4 p-2 special-scroll"
        style={{ height: "91vh", overflowX: "hidden", overflowY: "auto" }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="row px-4  pt-2 pb-4 justify-content-between">
            <NavLink to="/" target="_blank">
              <button
                className="btn  px-5 py-1 m-1"
                style={{
                  zoom: "0.8",
                  background: "#9e3a8ccc",
                  color: "#fff",
                  borderRadius: "20px",
                  boxShadow: "0 3px 6px #00000036",
                }}
              >
                Preview<i className="fa fa-eye mx-2"></i>
              </button>
            </NavLink>

            <button
              className="btn px-5 py-1 m-1"
              onClick={onSaveHandler}
              disabled={ctx.pageFlow?.length === 0}
              style={{
                zoom: "0.8",
                background: "#9e3a8ccc",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
          <div>
            <React.Fragment>
              <SwipeableDrawer
                anchor="right"
                open={state.right}
                onClose={(e) => {
                  toggleDrawer(e, false);
                }}
                onOpen={(e) => {
                  toggleDrawer(e, true);
                }}
              >
                {state.right && (
                  <List
                    onClickHandler={(e) => {
                      toggleDrawer(e, false);
                    }}
                    style={style}
                  />
                )}
              </SwipeableDrawer>
            </React.Fragment>
          </div>
          {ctx.pageFlow?.length === 0 ? (
            <div className="px-4 d-flex">
              <Chip
                // style={{ margin: "5px" }}
                label="Add page"
                style={{
                  // border: "none",
                  // borderRadius: "5px",
                  // borderBottom: " 2px grey solid",
                  color: "grey",
                  cursor: "pointer",
                }}
                className="px-3 m-2"
                deleteIcon={<AddIcon />}
                onDelete={createPage}
                variant="outlined"
                onClick={createPage}
              />
            </div>
          ) : (
            <>
              <div className="px-4 d-flex align-items-center chips">
                <Chip
                  // style={{ margin: "5px" }}
                  label="Add page"
                  style={{
                    // border: "none",
                    // borderRadius: "5px",
                    // borderBottom: " 2px grey solid",
                    color: "grey",
                    cursor: "pointer",
                  }}
                  disabled={ctx.pageFlow?.length === 5}
                  className="px-3 my-2"
                  deleteIcon={<AddIcon />}
                  onDelete={() => setAddpage(true)}
                  variant="outlined"
                  onClick={() => setAddpage(true)}
                />

                {ctx.pageFlow?.map((page, index) => (
                  <button
                    className="page px-3 m-2 d-flex align-items-center position-relative justify-content-start"
                    style={
                      selectedPage.toLocaleLowerCase() ===
                      page.toLocaleLowerCase()
                        ? {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                            borderBottom: "2px rgba(158, 58, 140, 0.8) solid",
                            color: "rgba(158, 58, 140, 0.8)",
                          }
                        : {}
                    }
                    onClick={() => {
                      setSelectedPage(page);
                      ctx.updateSelectedPage(page);
                    }}
                    key={index}
                  >
                    <span
                      style={{
                        fontSize: "0.8125rem",
                        fontFamily:
                          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                      }}
                    >
                      {page}
                    </span>
                    {/* {page === selectedPage ? ( */}
                    <DeleteIcon
                      onClick={() => {
                        setDeleteId(page);
                        setDeletepage(true);
                      }}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "4.5px",
                        bottom: 0,
                        width: "1.5rem",
                        height: "1.5rem",
                        zIndex: 10,
                        fill:
                          selectedPage?.toLocaleLowerCase() ===
                          page.toLocaleLowerCase()
                            ? "rgba(158, 58, 140, 0.8)"
                            : "",
                        padding: "5px",
                      }}
                    />
                  </button>
                ))}
              </div>
              <div className="d-flex px-4">
                <FormControl>
                  {/* <FormLabel id="demo-controlled-radio-buttons-group">
                    Menu Type
                  </FormLabel> */}
                  <RadioGroup
                    row
                    color="secondary"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={pageState}
                    onChange={(e) => {
                      setPageState(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      disabled
                      value="single"
                      control={<Radio color="secondary" size="small" />}
                      label="Single Page"
                    />
                    <FormControlLabel
                      disabled
                      value="multiple"
                      control={<Radio color="secondary" size="small" />}
                      label="Multiple Page"
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  onClick={(e) => {
                    setStyle("full");

                    toggleDrawer(e, true);
                  }}
                >
                  Site Settings
                </Button>
              </div>

              <div
                style={{
                  zoom: "0.6",
                }}
              >
                {addPage && (
                  <DeleteModal
                    submitHandler={() => setAddpage(false)}
                    addpage
                    label={"Add Page"}
                    onClickHandler={(value) => {
                      createPage(value);
                    }}
                  />
                )}
                {deletePage && (
                  <DeleteModal
                    submitHandler={() => setDeletepage(false)}
                    page
                    id={deleteId}
                    deleteHandler={() => deletePageHandler(deleteId)}
                  />
                )}
              </div>
              <Droppable droppableId="mounted">
                {(provided, snapshot) => (
                  <div
                    className="mounted px-4"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div
                      // className="p-2"
                      ref={drop}
                      style={{
                        zoom: "0.6",
                        border:
                          ctx.layoutFlow?.length > 0
                            ? "1px solid #9e3a8ccc"
                            : "",
                        background: ctx?.siteBackground,
                      }}
                    >
                      {mountedData?.length === 0 ? (
                        <NoLayout />
                      ) : (
                        <>
                          {mountedData?.map((item, index) => (
                            <Draggable
                              key={item.uniqId}
                              draggableId={item.uniqId}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="position-relative"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {modalstate && (
                                    <DeleteModal
                                      submitHandler={() => setModalstate(false)}
                                      id={id}
                                      selectedPage={selectedPage}
                                      deleteHandler={() =>
                                        deleteHandler(id, selectedPage)
                                      }
                                    />
                                  )}
                                  <div
                                    onClick={() => dataHandler(item.uniqId)}
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      zIndex: 20,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                        fill: "#dc3545",
                                        padding: "5px",
                                      }}
                                    />
                                  </div>

                                  <CreateComponent
                                    component={item.c}
                                    id={item.uniqId}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </>
                      )}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          )}
        </DragDropContext>
      </div>
    </>
  );
};

export default Preview;
