import React, { useContext, useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import Loader from "../../../loader/Loader";
import HeaderLogo from "../../../Assests/images/headerlogo.png";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import Select from "react-select";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import AuthContext from "../../../Context/Context";
import { toast } from "react-toastify";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const useStyles = makeStyles(() =>
  createStyles({
    rootNav: {
      backgroundColor: "#fff",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2rem",
      // boxShadow: "0px 3px 17px #fff5ff",
    },
    logoContainer: {
      width: "20%",
      marginRight: "30px",
      position: "relative",

      "& img": {
        width: "100%",
        height: "4rem",
        objectFit: "contain",
      },
    },
    // menuList: {
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "end",
    //   width: "65%",
    // },
    menuItems: {
      fontItems: "0.9rem",
      color: "#000",

      textTransform: "uppercase",
      padding: "0.5rem 0.75rem",
      marginRight: "0.5rem",
      marginLeft: "40px",
    },
    inputHeader: {
      width: "100%",
      background: "none",
      border: 0,
      outline: 0,
      fontItems: "0.9rem",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    pathSelector: {
      padding: "1rem 0",
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      padding: "1rem",
      "& p": {
        fontSize: "1.2rem",
        width: "15%",
        textTransform: "uppercase",
        color: "#000",
      },
    },

    "@media (max-width: 600px)": {
      rootNav: {
        padding: "0",
        paddingLeft: "2px",
      },
      menuItem: {
        fontSize: "8px",
      },
      logoContainer: {
        width: "25%",
      },
    },
  })
);

export const Navbar1 = (props) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const location = useLocation();

  const [defaultData, setDefaultdata] = useState();

  const [pageState, setPageState] = useState(
    ctx.pageState ? ctx.pageState : "single"
  );
  const [style, setStyle] = React.useState("full");

  const headerData = {
    logo: HeaderLogo,
    navbarAlignment: "justify-content-center",
    pageState: pageState,
    backgroundColor: "#fff",
    menucolor: "black",
    hoverColor: "violet",
    fontFamily: "",
    menuItem: [
      {
        label: "Home",
        path: "",
      },
      {
        label: "About",
        path: "",
      },
      {
        label: "Women",
        path: "",
      },
      {
        label: "Men",
        path: "",
      },
      {
        label: "Contact",
        path: "",
      },
    ],
  };
  const [localData, setLocalData] = useState(headerData);
  const [menuItem, setMenuItem] = useState(localData?.menuItem);
  const [pathArray, setPathArray] = useState([]);

  useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      setloading(true);
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? headerData : pageData[props.id]
      );
      setloading(false);
    }
  }, [ctx.selectedPage]);

  useEffect(() => {
    setPageState(ctx.pageState ? ctx.pageState : pageState);
  }, [ctx.pageState, pageState]);

  useEffect(() => {
    setMenuItem(localData?.menuItem);
  }, [localData?.menuItem]);
  // console.log(localData, "lolo");

  useEffect(() => {
    setPageState(localData?.pageState);
  }, [localData]);

  // useEffect(() => {
  //   if (ctx.pageFlow?.length === 1) {
  //     setPageState("single");
  //   }
  // }, [ctx.pageFlow]);

  useEffect(() => {
    if (ctx.selectedPage !== "") {
      if (ctx.layoutData[ctx.selectedPage] !== undefined) {
        if (pageState === "single") {
          var newObj = ctx.layoutData[ctx.selectedPage];
          var tempArray = newObj.map(function (mopt) {
            var info = {
              value: mopt.uniqId,
              label: rename(mopt.uniqId),
            };
            return info;
          });
          setPathArray(tempArray);
        } else if (pageState === "multiple") {
          var tempArray = ctx.pageFlow?.map(function (mopt) {
            var info = {
              value: mopt,
              label: mopt,
            };
            return info;
          });
          setPathArray(tempArray);
        }
      }
    }
  }, [pageState]);

  const rename = (name, i) => {
    if (typeof name === "string") {
      let firstName = name.substring(0, name.length - 2);
      let lastName = name.charAt(name.length - 1);
      let newName = firstName + " " + lastName;
      return newName;
    } else {
      return name;
    }
  };

  const onImageChange = (e, i) => {
    // setError(null);
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    let d = new Date().getTime();
    if (!selected) {
      // setError("Please select file");
      return;
    }

    if (!selected.type.includes("image")) {
      // setError("Please select image file");
      return;
    }
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else {
      setloading(true);
      const storage = getStorage();
      const uploadPath = ctx.userId + "/nav1f54656"; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data

      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            return {
              ...prevState,
              logo: url,
            };
          });
          setTimeout(() => {
            setloading(false);
            toast.success("Image Uploaded");
          }, 2000);
        });
      });
    }

    // setError(null);
  };

  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.name] = e.target.value;
    } else {
      tempEventInputs["path"] = e.value;
    }
    setMenuItem((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                backgroundColor: "#fff",
                menucolor: "black",
                hoverColor: "violet",
                fontFamily: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Navbar background
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Menu Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData?.menucolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                menucolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.menucolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                menucolor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <small>Active Color</small>
          <HexColorPicker
            color={localData.hoverColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                hoverColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.hoverColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                hoverColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      menuItem: menuItem,
      pageState: pageState,
    };
    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "white",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && <Loader />}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      {ctx.isEditable ? (
        <>
          <nav
            className={clsx(classes.rootNav, "navbar navbar-expand-lg   px-2")}
            style={{ background: localData?.backgroundColor }}
          >
            <div className={classes.logoContainer}>
              <input
                type="file"
                onChange={(e) => onImageChange(e)}
                className={classes.inputFile}
                id="logo"
                name="logo"
              />
              <label className={classes.inputLabel} htmlFor="logo">
                <i className="fa fa-upload"></i>
              </label>
              <img src={localData.logo} alt="headerLogo" />
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={clsx(
                " collapse navbar-collapse ",
                `${localData.navbarAlignment}`
              )}
              id="navbarTogglerDemo01"
            >
              {menuItem?.map((item, index) => (
                <div key={index}>
                  <input
                    id="label"
                    name="label"
                    className={clsx(classes.inputHeader, localData?.fontFamily)}
                    style={{ color: localData?.menucolor }}
                    onChange={(e) => onChangeHandler(e, item, index)}
                    value={item.label}
                    maxLength={25}
                  />
                </div>
              ))}
            </div>
          </nav>
          <div></div>
          <div className={classes.pathSelector}>
            {menuItem.map((item, index) => (
              <div className={classes.row} key={index}>
                <Typography>{item.label}</Typography>
                <Select
                  id="path"
                  name="path"
                  options={pathArray}
                  value={pathArray.filter(function (option) {
                    return option.value === item.path;
                  })}
                  onChange={(e) => onChangeHandler(e, item, index)}
                  styles={{ width: "20%" }}
                />
              </div>
            ))}
            <div>
              <h4 className="pb-2">Navbar Position</h4>
              <select
                className="form-select  form-control w-25"
                id="navbarAlignment"
                onChange={onChange}
              >
                <option value="justify-content-start">left</option>
                <option value="justify-content-center" selected>
                  center
                </option>
                <option value="justify-content-end">right</option>
              </select>
            </div>
          </div>
        </>
      ) : (
        <nav
          style={{ background: localData?.backgroundColor }}
          className={clsx(classes.rootNav, "navbar navbar-expand-lg px-2")}
        >
          <div className={classes.logoContainer}>
            <img src={localData.logo} alt="headerLogo" />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={clsx(
              "m-2 collapse navbar-collapse ",
              `${localData.navbarAlignment} `
            )}
            id="navbarTogglerDemo01"
          >
            {pageState === "single"
              ? menuItem.map((item, index) => (
                  <HashLink
                    to={
                      location.pathname.includes("layout")
                        ? "#"
                        : `#${item.path}`
                    }
                    key={index}
                  >
                    <p
                      className={clsx(classes.menuItems, localData?.fontFamily)}
                      style={{ color: localData?.menucolor }}
                    >
                      {item.label}
                    </p>
                  </HashLink>
                ))
              : menuItem.map((item, index) => (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!location.pathname.includes("layout")) {
                        if (ctx.pageFlow.includes(item.path))
                          ctx.setLoading(true);
                        ctx.updateSelectedPage(item.path);
                      }
                    }}
                    key={index}
                  >
                    <p
                      style={
                        ctx.selectedPage === item.path
                          ? { color: localData.hoverColor }
                          : { color: localData?.menucolor }
                      }
                      className={clsx(classes.menuItems, localData?.fontFamily)}
                    >
                      {item.label}
                    </p>
                  </span>
                ))}
          </div>
        </nav>
      )}
    </>
  );
};
