import { useState, useContext } from "react";
import AuthContext from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import React from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  isEmailVerified,
} from "firebase/auth";
import { auth } from "../../services/firebase";
import Loader from "../../loader/Loader";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Bgicon1 } from "../../Assests/xd1.svg";
import { ReactComponent as Bgicon2 } from "../../Assests/xd2.svg";
import { ReactComponent as Bgicon3 } from "../../Assests/xd4.svg";
import { ReactComponent as backsrc } from "../../Assests/images/backscrn.jpg";
import Logo from "../../Assests/fickle1.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { TextField } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import BrushIcon from "@mui/icons-material/Brush";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { width } from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FaceIcon from "@mui/icons-material/Face";
import { pink } from "@mui/material/colors";
import logo from "../../Assests/images/logo.svg";
import { useForm } from "react-hook-form";
import { AdminServices } from "../../services/Services";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  Bgicon1: {
    position: "fixed",
    top: "33%",
    left: "9%",
  },
  Bgicon2: {
    top: "-10%",
    left: "67%",
    position: "fixed",
  },
  Bgicon3: {
    position: "fixed",
    bottom: "-8%",
    left: "-8%",
  },

  Bgicon4: {
    position: "fixed",
    right: "33%",
    bottom: "6%",
    zoom: "0.6",
    transform: "rotate(204deg)",
  },
  Bgicon5: {
    position: "fixed",
    right: "5%",
    bottom: "27%",
  },

  boxbac: {
    background: "#fff",
    boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 19px )",
    WebkitBackdropFilter: "blur( 19px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
  },
  root: {
    "& .css-10i04qz-MuiInputBase-root-MuiFilledInput-root:after": {
      color: "#fff",
    },
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4b3573",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4b3573",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4b3573",
    },
    "& .MuiOutlinedInput-input": {
      color: "#4b3573",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#4b3573",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#4b3573",
    },
    "& .MuiInputLabel-outlined": {
      color: "#4b3573",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#4b3573",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#4b3573",
    },
  },

  "@media (max-width: 1200px)": {},
});
const Login = (props) => {
  const classes = useStyles();
  const ctx = useContext(AuthContext);
  // const [modalstate, setModalstate] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({});
  const [userCred, setUserCred] = useState({
    email: "",
    password: "",
    otp: "",
    newpassword: "",
  });
  const [showpassword, setShowpassword] = useState(false);
  const [user, setUser] = useState("");

  const [resetstep, setResetstep] = useState("login");

  const navigate = useNavigate();
  const titleCase = (str) => {
    return str
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: userCred });

  onAuthStateChanged(auth, (currentuser) => {
    setUser(currentuser);
  });

  const handleLogin = async (e) => {
    setloading(true);

    if (resetstep === "login") {
      signInWithEmailAndPassword(auth, userCred.email, userCred.password)
        .then((res) => {
          // if (!res.user.emailVerified) {
          setloading(false);

          ctx.setUserId(res.user.uid);

          setTimeout(() => {
            user && navigate(`/${res.user.uid}/home`);
          }, 1000);
        })
        .catch((error) => {
          // console.log("error", error);
          setloading(false);
          const errorCode = error.code;
          const message = errorCode.substring(5);
          setError({ error: titleCase(message) });
        });
    } else if (resetstep === "reset1") {
      AdminServices("post", "reset_password/", {
        Mail_id: userCred.email,
      })
        .then((res) => {
          setResetstep("step2");
          toast.success("Otp has been sended to Mail");
          setError({});
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      AdminServices("post", "reset_password/", {
        Mail_id: userCred.email,
        Password: userCred.password,
        Otp: userCred.otp,
      })
        .then((res) => {
          setResetstep("step1");

          toast.success("Password has been changed");
          setError({});
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const onChangeHandler = (event) => {
    event.preventDefault();
    let val = event.target.value;
    setUserCred((prevState) => {
      return {
        ...prevState,
        [event.target.name]: val,
      };
    });
  };
  return (
    <>
      {loading && (
        <>
          <Loader />
        </>
      )}
      <div
        style={{
          backgroundImage:
            "url(" +
            "https://images.unsplash.com/photo-1659284653841-a7c0ad936009?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
        className="Login-Edit position-relative overlay"
        // style={{ height: "100vh" }}
      >
        <AppBar position="static" style={{ background: "#4b3573" }}>
          <Toolbar>
            <Chip
              style={{ background: "#fff" }}
              icon={<BrushIcon sx={{ color: pink[500] }} />}
              label="BETA"
            />
            <div className="text-center">
              <img style={{ width: "100px" }} src={logo} />
            </div>
          </Toolbar>
        </AppBar>

        <div className="row">
          <div className="col-md-6  d-flex align-items-center">
            <div className="col-md-7 m-auto">
              <h2 className="content text-center">
                Your
                <br /> Business At
                <br /> Your
                <br /> Fingertips
              </h2>
            </div>
          </div>
          <div className="col-md-6 p-4 ">
            <Box
              // style={{ width: "80%" }}

              sx={{
                marginTop: 8,
              }}
              className={clsx(classes.boxbac, "col-lg-7 col-md-10")}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{ background: "#4b3573" }}
                  sx={{ m: 1, bgcolor: "primary.main" }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  style={{ color: "#4b3573" }}
                  component="h1"
                  variant="h5"
                >
                  Sign in
                </Typography>
              </Box>
              <form
                className="text-center"
                component="form"
                onSubmit={handleSubmit(handleLogin)}
              >
                {resetstep !== "reset2" && (
                  <TextField
                    className={clsx(classes.root, "mt-4")}
                    style={{ width: "90%" }}
                    id="outlined-basic"
                    label="Email Address"
                    error={error.error || errors.email}
                    variant="outlined"
                    autoComplete="email-address"
                    {...register(
                      "email",

                      {
                        onChange: (e) => {
                          onChangeHandler(e);
                        },
                        required: "Email is Required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email address",
                        },
                      }
                    )}
                    value={userCred.email}
                    // onChange={onChangeHandler}
                  />
                )}
                {resetstep === "reset2" ? (
                  <>
                    <TextField
                      className={clsx(classes.root, "mt-4")}
                      style={{ width: "90%" }}
                      id="outlined-basic"
                      label="OTP"
                      variant="outlined"
                      error={error.error || errors.otp}
                      {...register(
                        "otp",

                        {
                          onChange: (e) => {
                            onChangeHandler(e);
                          },
                          required: "Otp is Required",
                        }
                      )}
                      value={userCred.password}
                      // onChange={onChangeHandler}
                      type={"number"}
                    />
                    <TextField
                      className={clsx(classes.root, "mt-4")}
                      style={{ width: "90%" }}
                      id="outlined-basic"
                      label=" New Password"
                      variant="outlined"
                      error={error.error || errors.newpassword}
                      {...register(
                        "newpassword",

                        {
                          onChange: (e) => {
                            onChangeHandler(e);
                          },
                          required: "New Password is Required",
                        }
                      )}
                      value={userCred.password}
                      // onChange={onChangeHandler}
                      type={showpassword ? "text" : "password"}
                    />
                  </>
                ) : resetstep === "login" || resetstep === "reset2" ? (
                  <>
                    <TextField
                      className={clsx(classes.root, "mt-4")}
                      style={{ width: "90%" }}
                      id="outlined-basic"
                      label={
                        resetstep === "step1" ? "Password" : "Confirm Password"
                      }
                      variant="outlined"
                      autoComplete="password"
                      error={error.error || errors.password}
                      {...register(
                        "password",

                        {
                          onChange: (e) => {
                            onChangeHandler(e);
                          },
                          required: "Password is Required",
                        }
                      )}
                      value={userCred.password}
                      // onChange={onChangeHandler}
                      type={showpassword ? "text" : "password"}
                    />
                  </>
                ) : (
                  <></>
                )}

                <small
                  style={{
                    color: "red",
                    fontSize: "14px",
                    padding: "0px 20px",
                  }}
                  className=" text-center mt-1 d-block "
                >
                  {error?.error
                    ? error?.error
                    : errors?.email
                    ? errors?.email.message
                    : errors?.password?.message}
                </small>

                {resetstep === "login" && (
                  <FormControlLabel
                    style={{ color: "#130818" }}
                    control={
                      <Checkbox
                        defaultChecked={showpassword}
                        labelStyle={{ color: "#000" }}
                        iconStyle={{ fill: "red" }}
                      />
                    }
                    label="Show Password"
                    onChange={() => setShowpassword(!showpassword)}
                  />
                )}
                <Button
                  // onClick={forgotPassword ? resetHanler : handleLogin}
                  type="submit"
                  style={{ width: "90%", background: "#4b3573" }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {resetstep === "login" ? "login" : "send"}
                </Button>
                <Grid className="mb-4">
                  <Grid item xs>
                    <Link
                      onClick={() => {
                        resetstep === "reset1" || resetstep === "reset2"
                          ? setResetstep("login")
                          : setResetstep("reset1");
                      }}
                      style={{ color: "#130818" }}
                      href="#"
                      variant="body2"
                    >
                      {resetstep === "login"
                        ? "Forgot Password"
                        : "Back to login"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
