import React, { useContext, useState } from "react";
import AuthContext from "../../../Context/Context";
import styles from "./ContactForm3.module.css";
import Loader from "../../../loader/Loader";
import clsx from "clsx";
import validator from "validator";

import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const ContactForm3 = (props) => {
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const defaultValues = { name: "", email: "", message: "", phone: "" };
  const [formData, setFormData] = useState(defaultValues);
  const [defaultData, setDefaultdata] = useState();

  const [style, setStyle] = React.useState("full");
  const onChange = (event) => {
    let val = event.target.value;
    setFormData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: val,
      };
    });
  };

  function formHandler(e) {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.message === ""
    ) {
      toast.warn("Fill the required fields");
    } else if (validator.isEmail(formData.email) === false) {
      toast.warn("please enter valid mail");
    } else if (validator.isMobilePhone(formData.phone) === false) {
      toast.warn("Please enter valid mobile number");
    } else {
      let title = "Received A New Enquiry";
      ctx.formHandler(formData);
      ctx.mailSend(title, formData);
      toast.success("Submited Successfully");
      setFormData(defaultValues);
    }
  }

  const data = {
    backgroundColor: "",
    header: "Contact Us",
    headerColor: "",
    fontFamily: "",
    para: " Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmodtempor incidunt ut labore et dolore magna aliqua. Ut enim ad minimeniam, quis nostrum",
    paracolor: "",
    parafont: "",
    address: "xxx, yyy, zzz - 123123",
    phone: "99887 766554",
    email: "sample@gmail.com",
    infoheadcolor: "",
    infoheadfont: "",
    infoparacolor: "",
    infoparafont: "",
    iconColor: "",
    formbackround: "",
    formheadingcolor: "",
    formheadingfont: "",
    formlabelcolor: "",
    buttonbackground: "",
    buttontextcolor: "",
    location: "Anicha+Digital+Infrastructure+Pvt+Ltd",
  };

  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined ? data : ctx.websiteData[props.id]
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const onChangeHandler = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                backgroundColor: "",
                headerColor: "",
                fontFamily: "",
                paracolor: "",
                parafont: "",
                infoheadcolor: "",
                infoheadfont: "",
                infoparacolor: "",
                infoparafont: "",
                iconColor: "",
                formbackround: "",
                formheadingfont: "",
                formheadingcolor: "",
                formlabelcolor: "",
                buttonbackground: "",
                buttontextcolor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Contact background
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData?.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <p variant="p" className="my-2">
            Para Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.parafont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                parafont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.paracolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                paracolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.paracolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                paracolor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <p variant="p" className="my-2">
            Contact info heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.infoheadfont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                infoheadfont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.infoheadcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                infoheadcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.infoheadcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                infoheadcolor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <small>Icon Color</small>
          <HexColorPicker
            color={localData.iconColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                iconColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.iconColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                iconColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Contact info para Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.infoparafont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                infoparafont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.infoparacolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                infoparacolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.infoparacolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                infoparacolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />

          <p variant="p" className=" my-2">
            Form style
          </p>
          <small>Form Background</small>
          <HexColorPicker
            color={localData.formbackround}
            onChange={(color) =>
              setLocalData({
                ...localData,
                formbackround: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.formbackround}
            onChange={(e) => {
              setLocalData({
                ...localData,
                formbackround: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />

          <small> Form Heading Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.formheadingfont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                formheadingfont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Form Heading Color</small>
          <HexColorPicker
            color={localData.formheadingcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                formheadingcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.formheadingcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                formheadingcolor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <small>Label color</small>
          <HexColorPicker
            color={localData.formlabelcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                formlabelcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.formlabelcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                formlabelcolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <small>Button background</small>
          <HexColorPicker
            color={localData.buttonbackground}
            onChange={(color) =>
              setLocalData({
                ...localData,
                buttonbackground: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.buttonbackground}
            onChange={(e) => {
              setLocalData({
                ...localData,
                buttonbackground: e.target.value,
              });
            }}
            maxLength={10}
          />

          <small>Button Text color</small>
          <HexColorPicker
            color={localData.buttontextcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                buttontextcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.buttontextcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                buttontextcolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <>
          <div className="row py-3 justify-content-end">
            <div className="row py-3 justify-content-end">
              <button
                className="btn px-5"
                onClick={onSaveHandler}
                style={{
                  background: "#9e3a8ccc",
                  fontSize: "20px",
                  color: "#fff",
                  borderRadius: "20px",
                  boxShadow: "0 3px 6px #00000036",
                }}
              >
                Save<i className="fa fa-save mx-2"></i>{" "}
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div
        id="#contact"
        className="col-lg-8 mx-auto p-1 p-md-3"
        style={{ background: localData?.backgroundColor }}
      >
        <section className={styles.contact}>
          <div className={styles.content}>
            <h2
              style={{ color: localData?.headerColor }}
              className={clsx(styles.content_h2, localData?.fontFamily)}
            >
              {localData.header}
            </h2>
            {ctx.isEditable ? (
              <>
                <textarea
                  id="para"
                  style={{ color: localData?.paracolor }}
                  className={clsx(styles.inputPara, localData?.parafont)}
                  onChange={onChangeHandler}
                  value={localData.para}
                />
              </>
            ) : (
              <>
                <p
                  style={{ color: localData?.paracolor }}
                  className={clsx(styles.content_p, localData?.parafont)}
                >
                  {localData.para}
                </p>
              </>
            )}
          </div>
          <div className={clsx("container p-md-2 p-sm-0", styles.con)}>
            <div className={styles.contactInfo}>
              <div className={styles.box}>
                <div className={styles.icon_address}>
                  <i
                    className="fa fa-location-arrow ico"
                    aria-hidden="true"
                    style={{ color: localData?.iconColor }}
                  ></i>
                </div>
                <div className={styles.text_address}>
                  <h3
                    style={{ color: localData?.infoheadcolor }}
                    className={clsx(
                      styles.infoHeading,
                      localData?.infoheadfont
                    )}
                  >
                    Address
                  </h3>

                  {ctx.isEditable ? (
                    <>
                      <textarea
                        id="address"
                        style={{ color: localData?.infoparacolor }}
                        className={clsx(
                          styles.inputtext,
                          localData?.infoparafont
                        )}
                        onChange={onChangeHandler}
                        value={localData.address}
                        maxLength={150}
                      />
                    </>
                  ) : (
                    <>
                      <p
                        style={{ color: localData?.infoparacolor }}
                        className={clsx(
                          styles.text_address_p,
                          localData?.infoparacolor
                        )}
                      >
                        {localData.address}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className={styles.box}>
                <div className={styles.icon_address}>
                  <i
                    className="fa fa-volume-control-phone ico"
                    aria-hidden="true"
                    style={{ color: localData?.iconColor }}
                  ></i>
                </div>
                <div className={styles.text_address}>
                  <h3
                    style={{ color: localData?.infoheadcolor }}
                    className={clsx(
                      styles.infoHeading,
                      localData?.infoheadfont
                    )}
                  >
                    Phone
                  </h3>
                  {ctx.isEditable ? (
                    <>
                      <input
                        type="text"
                        id="phone"
                        style={{ color: localData?.infoparacolor }}
                        className={clsx(
                          styles.inputtext,
                          localData?.infoparafont
                        )}
                        onChange={onChangeHandler}
                        maxLength={150}
                        value={localData.phone}
                      />
                    </>
                  ) : (
                    <>
                      <p
                        style={{ color: localData?.infoparacolor }}
                        className={clsx(
                          styles.text_address_p,
                          localData?.infoparacolor
                        )}
                      >
                        {localData.phone}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className={styles.box}>
                <div className={styles.icon_address}>
                  <i
                    style={{ color: localData?.iconColor }}
                    className="fa fa-envelope ico"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className={styles.text_address}>
                  <h3
                    style={{ color: localData?.infoheadcolor }}
                    className={clsx(
                      styles.infoHeading,
                      localData?.infoheadfont
                    )}
                  >
                    Email
                  </h3>
                  {ctx.isEditable ? (
                    <>
                      <input
                        id="email"
                        style={{ color: localData?.infoparacolor }}
                        className={clsx(
                          styles.inputtext,
                          localData?.infoparafont
                        )}
                        onChange={onChangeHandler}
                        maxLength={150}
                        value={localData.email}
                      />
                    </>
                  ) : (
                    <>
                      <p
                        style={{ color: localData?.infoparacolor }}
                        className={clsx(
                          styles.text_address_p,
                          localData?.infoparacolor
                        )}
                      >
                        {localData.email}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className={styles.ContactForm}
              style={{ background: localData?.formbackround }}
            >
              <form>
                <h2
                  style={{ color: localData?.formheadingcolor }}
                  className={clsx(
                    styles.formHeading,
                    localData?.formheadingfont
                  )}
                >
                  Send Us A Message
                </h2>
                <div className={styles.inputbox}>
                  <label style={{ color: localData?.formlabelcolor }}>
                    Name
                  </label>
                  <input
                    type="text"
                    required="required"
                    name="name"
                    onChange={(e) => onChange(e)}
                    value={formData.name}
                  />
                </div>
                <div className={styles.inputbox}>
                  <label style={{ color: localData?.formlabelcolor }}>
                    Email
                  </label>
                  <input
                    type="text"
                    required="required"
                    name="email"
                    onChange={(e) => onChange(e)}
                    value={formData.email}
                  />
                </div>
                <div className={styles.inputbox}>
                  <label style={{ color: localData?.formlabelcolor }}>
                    Phone
                  </label>
                  <input
                    type="text"
                    required="required"
                    name="phone"
                    maxLength="10"
                    onChange={(e) => onChange(e)}
                    value={formData.phone}
                  />
                </div>
                <div className={styles.inputbox}>
                  <label style={{ color: localData?.formlabelcolor }}>
                    Your Message
                  </label>
                  <textarea
                    required="required"
                    name="message"
                    value={formData.message}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className={styles.inputbox}>
                  <input
                    style={{
                      color: localData?.buttontextcolor,
                      background: localData?.buttonbackground,
                    }}
                    type="submit"
                    value="Send"
                    onClick={(e) => formHandler(e)}
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
        {ctx.isEditable ? (
          <div className="w-100">
            <h3>Location</h3>
            <input
              id="location"
              className="form-control my-2"
              onChange={onChangeHandler}
              value={localData.location}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              className={styles.map}
              id="gmap_canvas"
              src={`https://maps.google.com/maps?q=${localData?.location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm3;
