import React from "react";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../Context/Context";
import { createStyles, makeStyles } from "@mui/styles";
import { Layout } from "../../utilitis/Layout";
import Loader from "../../loader/Loader";
import useDocumentTitle from "../../Components/Title/useDocumentTitle";

const useStyles = makeStyles(() =>
  createStyles({
    scrollbar: {
      height: "100vh",
      overflowX: "hidden",
      overflowY: "auto",
      scrollBehavior: "smooth",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        width: "5px",
        background: "#000",
      },
      "&::-webkit-scrollbar-track": {
        width: "5px",
        background: "#efefef",
        boxShadow: "inset 0px 3px 6px #00000036",
      },
    },
    NavbarRoot: {
      position: "sticky",
      top: "0",
      zIndex: "1396",
    },
  })
);

const Home = () => {
  const classes = useStyles();
  const ctx = useContext(AuthContext);
  const [nav, setNav] = useState([]);

  const [home, setHome] = useState(null);
  const [layoutFlow, setLayoutFlow] = useState([]);

  useEffect(() => {
    ctx.getWebsiteData();
    ctx.getLayoutData();
  }, [ctx.userId]);

  useEffect(() => {
    if (!home) {
      setHome(ctx.selectedPage);
    }
  }, [ctx.selectedPage]);

  useEffect(() => {
    let pageData = [];
    if (ctx.layoutData && ctx.layoutData[home] !== undefined) {
      pageData = ctx.layoutData[home];
    }
    let newArr = [];
    for (const data of pageData) {
      let tempArr = Layout.filter((x) => data.id === x.id);
      newArr = newArr.concat(tempArr);
    }
    let finalArray = newArr?.map((item, i) =>
      Object.assign({}, item, pageData[i])
    );
    setNav(finalArray);
  }, [home]);

  useEffect(() => {
    ctx.setLoading(true);
    let pageData = [];
    if (ctx.layoutData && ctx.layoutData[ctx.selectedPage] !== undefined) {
      pageData = ctx.layoutData[ctx.selectedPage];
    }
    let newArr = [];
    for (const data of pageData) {
      let tempArr = Layout.filter((x) => data.id === x.id);
      newArr = newArr.concat(tempArr);
    }
    let finalArray = newArr?.map((item, i) =>
      Object.assign({}, item, pageData[i])
    );
    setLayoutFlow(finalArray);
    ctx.setLoading(false);
  }, [ctx.selectedPage, ctx.layoutData]);

  useDocumentTitle(ctx.siteSettings?.Title);
  var Description = document.getElementById("description");
  var fevicon = document.getElementById("feviIcon");
  var Keywords = document.getElementById("keywords");

  React.useEffect(() => {
    if (ctx.siteSettings) {
      fevicon.href = ctx.siteSettings?.Logo;
      Description.content = ctx.siteSettings?.Description;
      Keywords.content = ctx.siteSettings?.Keywords;
    }
  }, [ctx.siteSettings]);

  const CreateComponent = ({ component, id }) => {
    const Component = component;
    return <Component id={id} />;
  };

  React.useEffect(() => {
    ctx.setLoading(true);
    setTimeout(() => {
      ctx.setLoading(false);
    }, 1500);
  }, [ctx.selectedPage]);

  return (
    <div
      style={{
        background: ctx?.siteSettings?.siteBackground,
      }}
      className={classes.scrollbar}
    >
      {ctx.loading ? (
        <Loader />
      ) : (
        <>
          {nav.length !== 0 &&
            home !== ctx.selectedPage &&
            nav[0].uniqId.includes("Nav") && (
              <div
                key={nav[0].uniqId}
                id={nav[0].uniqId}
                className={classes.NavbarRoot}
              >
                <CreateComponent component={nav[0].c} id={nav[0].uniqId} />
              </div>
            )}

          {layoutFlow &&
            layoutFlow.map((item) => (
              <div
                key={item.uniqId}
                id={item.uniqId}
                className={
                  item.uniqId.includes("Nav") ? classes.NavbarRoot : ""
                }
              >
                <>
                  <CreateComponent component={item.c} id={item.uniqId} />
                </>
              </div>
            ))}

          {nav.length !== 0 &&
            home !== ctx.selectedPage &&
            nav[nav.length - 1].uniqId.includes("Foot") && (
              <div
                key={nav[nav.length - 1].uniqId}
                id={nav[nav.length - 1].uniqId}
                className={classes.NavbarRoot}
              >
                <CreateComponent
                  component={nav[nav.length - 1].c}
                  id={nav[nav.length - 1].uniqId}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default Home;
