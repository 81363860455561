import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useContext } from "react";
import AuthContext from "../../Context/Context";
import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles(() =>
  createStyles({
    backDrop: {
      position: "fixed",
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      height: "100vh",
      width: "100%",
      background: " rgba(0, 0, 0, 0.5)",
      zIndex: "1218",
    },
    // Modal
    modalBox: {
      position: "fixed",
      height: "fit-content",
      background: "white",
      left: "0",
      top: "0",
      bottom: "0",
      right: "0",
      margin: "auto",
    },
  })
);
export const Appform = (props) => {
  const { id, submitHandler = () => {} } = props;
  const ctx = useContext(AuthContext);
  const classes = useStyles();
  const data = ctx.appData.find((x) => x.id === id);

  return (
    <>
      <div className={classes.backDrop}>
        <div
          className={clsx(classes.modalBox, "  rounded container profile-page")}
          style={{ width: "35%" }}
        >
          <div className="cardData p-3 profile-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={submitHandler}
            >
              &times;
            </button>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="profile-image float-md-right">
                  {" "}
                  <Avatar
                    src={data.img}
                    alt=""
                    style={{
                      width: "100px",
                      height: "100px",
                      overflow: "hidden",
                    }}
                  />{" "}
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-12">
                <h4 className="m-t-0 m-b-0">
                  {/* <strong>Michael</strong> Deo */}
                  {data.student_name}
                </h4>
                {/* <span className="job_post">11TH contained</span> */}
                <h6 className="p-0 mt-2 ">
                  {" "}
                  <i
                    className="fa fa-phone-square mx-2"
                    aria-hidden="true"
                  ></i>{" "}
                  {data.phone}
                </h6>

                <h6 className="p-0 mt-2">
                  <i className="fa fa-envelope mx-2" aria-hidden="true"></i>
                  {data.email}
                </h6>
              </div>
              <div className="col-6 mt-2">
                <h5>Previous School</h5>
                <p>{data.previous_school}</p>
              </div>
              <div className="col-6 mt-2">
                <h5>Current class</h5>
                <p>{data.current_class}</p>
              </div>
              <div className="col-12 mt-2">
                <h5>Father name</h5>
                <p>{data.father_name}</p>
              </div>
              <div className="col-12 mt-2">
                <h5>Message</h5>
                <p style={{ overflowWrap: "anywhere" }}>{data.message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Contactform = (props) => {
  const { id, submitHandler = () => {} } = props;
  const ctx = useContext(AuthContext);
  const classes = useStyles();
  const data = ctx.messageData.find((x) => x.id === id);

  return (
    <>
      <div className={classes.backDrop}>
        <div
          className={clsx(classes.modalBox, "  rounded container profile-page")}
          style={{ width: "35%" }}
        >
          <div className="cardData p-3 profile-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={submitHandler}
            >
              &times;
            </button>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="profile-image float-md-right">
                  {" "}
                  <img
                    src="https://bootdey.com/img/Content/avatar/avatar7.png"
                    alt=""
                    style={{
                      width: "100px",
                      height: "100px",
                      overflow: "hidden",
                    }}
                  />{" "}
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-12">
                <h4 className="m-t-0 m-b-0">
                  {/* <strong>Michael</strong> Deo */}
                  {data.name}
                </h4>
                <span className="job_post">{data.company}</span>
                <h6 className="p-0 mt-2 ">
                  {" "}
                  <i
                    className="fa fa-phone-square mx-2"
                    aria-hidden="true"
                  ></i>{" "}
                  {data.phone}
                </h6>

                <h6 className="p-0 mt-2">
                  <i className="fa fa-envelope mx-2" aria-hidden="true"></i>
                  {data.email}
                </h6>
              </div>
              {/* <div className="col-6 mt-2">
                <h5>Previous class</h5>
                <p>{data.pastclass}</p>
              </div>
              <div className="col-6 mt-2">
                <h5>Current class</h5>
                <p>{data.currentclass}</p>
              </div> */}
              {/* <div className="col-12 mt-2">
                <h5>Father name</h5>
                <p>{data.fathername}</p>
              </div> */}
              <div className="col-12 mt-2">
                <h5>Message</h5>
                <p style={{ overflowWrap: "anywhere" }}>{data.message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
