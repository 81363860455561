import { createStyles, makeStyles } from "@mui/styles";
import React, { useContext, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import Loader from "../../../loader/Loader";
import { toast } from "react-toastify";
import clsx from "clsx";
import ShareIcon from "@mui/icons-material/Share";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import AuthContext from "../../../Context/Context";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const useStyles = makeStyles(() => ({
  img: {
    paddingRight: "80px",
    marginTop: "100px",
  },
  img1: {
    height: "291px",
    width: "440px",
    marginTop: "20px",
  },

  falinkedin: {
    background: "#0979c4",
    color: "white",
    padding: "10px",
    fontSize: "22px",
    width: "50px",
    textAlign: "center",
    textDecoration: "none",
    margin: "5px 2px",
    "&hover": {
      backgroundColor: "#0d639c",
      transition: "0.6s",
      color: "rgb(255, 255, 255)",
    },
  },
  fawhatsapp: {
    background: "#07ad15",
    color: "white",
    padding: "10px",
    fontSize: "22px",
    width: "50px",
    textAlign: "center",
    textDecoration: "none",
    margin: "5px 2px",
    "& hover": {
      backgroundColor: "#12811b",
      transition: "0.6s",
      color: "rgb(255, 255, 255)",
    },
  },
  fafacebook: {
    background: "#4bbce9",
    color: "white",
    padding: "10px",
    fontSize: "22px",
    width: "50px",
    texAlign: "center",
    texDecoration: "none",
    margin: "5px 2px",
    "& hover": {
      backgroundColor: "#2c8eb4",
      transition: "0.6s",
      color: "rgb(255, 255, 255)",
    },
  },
  introHeader: {
    fontSize: "1.75rem !important",
    color: "#000",
    textTransform: "capitalize",
    textAlign: "center",
    paddingBottom: "1.5rem",
    background: "transparent",
    outline: 0,
    border: "none",
    width: "100%",
  },
  footericons: {
    padding: "10px",
    textAlign: "center",
  },
  image: {
    border: "none",
    backgroundColor: " transparent",
    marginTop: "10px",
  },
  a: {
    "&hover": {
      textDecoration: "none",
    },
  },
  fafacebookf: {
    backgroundColor: "#4bbce9",
    padding: "12px 18px",
    fontSize: "20px",
    color: "white",
  },
  inputFile: {
    width: 0,
    height: 0,
    opacity: 0,
    zIndex: "0",
  },
  inputLabel: {
    position: "absolute",
    background: "#fff",
    width: "2.5rem",
    height: "2.5rem",
    padding: "0.3rem",
    bottom: "0",
    right: "0",
    zIndex: 20,
    textAlign: "center",
    cursor: "pointer",
    "& i": {
      fontSize: "1.75rem",
    },
  },
  addCard: {
    borderRadius: "1rem",
    heigh: "fit-content",
    background: "#fff",
    padding: "1rem 2rem",
    cursor: "pointer",
    top: "1rem",
    color: "#9e3a8ccc",
    right: "1rem",
    boxShadow: "2px 2px 3px 0 #ccc",
  },
  ShareIcon: {
    fontSize: "22px",
    "& svg": {
      // width: "1rem",
    },
  },
  "@media (max-width: 600px)": {
    img1: {
      height: "200px",
      width: "100%",
      marginTop: "20px",
    },
    ShareIcon: {
      fontSize: "15px",
      "& svg": {
        width: "1rem",
      },
    },
  },
}));

export const Card5 = (props) => {
  const ctx = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [activeState, setActiveState] = React.useState("");
  const [defaultData, setDefaultdata] = useState();
  const [imageindex, setImageindex] = useState();
  const [style, setStyle] = React.useState("full");

  // const assert = require("assert");
  // const urlmask = require("url-mask");
  // let masked = urlmask();
  // assert.equal("http://*****:*****@github.com/", masked);
  const cardData = {
    header: "New Arrivals",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    data: [
      {
        img: "https://www.iamgreenwise.com/wp-content/uploads/child-happy-nature-autumn-leaves-900x563.jpg",
        id: "0",
      },
      {
        img: "https://www.iamgreenwise.com/wp-content/uploads/child-happy-nature-autumn-leaves-900x563.jpg",
        id: "1",
      },
      {
        img: "https://media.istockphoto.com/photos/multiethnic-group-of-preschool-students-in-class-picture-id1166892018?k=20&m=1166892018&s=612x612&w=0&h=IPblvimEzdw8B4r30D4elnW2Dx5ewuyueyxXWaLnhao=",
        id: "2",
      },
    ],
  };
  let pageData = ctx.websiteData[ctx.selectedPage];

  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : cardData
  );
  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [card, setCard] = useState(localData.data);

  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };
  const onImageChange = (e, i) => {
    // setError(null);
    let d = new Date().getTime();
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;

    if (!selected) {
      // setError("Please select file");
      return;
    }

    if (!selected.type.includes("image")) {
      // setError("Please select image file");
      return;
    }
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;

    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else {
      setloading(true);
      const storage = getStorage();
      const uploadPath =
        ctx.userId + "/" + card[i].id + ctx.selectedPage + props.id; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setCard((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[i],
              img: url,
            };
            prevState[i] = updatedData;
            return [...prevState];
          });
          setTimeout(() => {
            setloading(false);
            toast.success("Image Uploaded");
          }, 2000);
        });
      });
    }

    // setError(null);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <p variant="p" className=" my-2">
            Gallery background
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      img: "https://www.iamgreenwise.com/wp-content/uploads/child-happy-nature-autumn-leaves-900x563.jpg",
      id: card.length + 1,
    };
    setCard((prevState) => {
      return [...prevState, updatedData];
    });
  };

  const removeCard = (value) => {
    setCard((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  const classes = useStyles();
  let editable = (
    <>
      <div className="text-center">
        <input
          className={clsx(classes.introHeader, localData?.fontFamily)}
          style={{ color: localData?.headerColor }}
          placeholder="Header"
          id="header"
          onChange={onChange}
          value={localData.header}
        />
      </div>

      <div className="row">
        {card.map((details, index) => (
          <div
            className="col-sm-12 col-md-4 col-lg-4 position-relative"
            key={index}
          >
            <div
              onClick={() => removeCard(details.id)}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 20,
                cursor: "pointer",
              }}
            >
              <DeleteIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  fill: "#dc3545",
                  padding: "5px",
                }}
              />
            </div>
            <input
              type="file"
              onChange={(e) => onImageChange(e, index)}
              className={classes.inputFile}
              id={details.id}
              name={details.title}
            />
            <label className={classes.inputLabel} htmlFor={details.id}>
              <i className="fa fa-upload"></i>
            </label>
            <img
              className={classes.img1}
              src={details.img}
              height="291"
              width="440px"
              alt="fikle"
            />
          </div>
        ))}
        <div
          className={clsx(
            " col-sm-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center"
          )}
          style={{ height: "291px", width: "440px" }}
        >
          {card.length < ctx.user?.Card_limit && (
            <div className={classes.addCard} onClick={addCard}>
              <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add
              Card
            </div>
          )}
        </div>
      </div>
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      data: card,
    };

    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };
  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "#fff",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div
        className="container"
        style={{ background: localData?.backgroundColor }}
      >
        {ctx.isEditable ? (
          editable
        ) : (
          <>
            <h4
              className={clsx(
                classes.introHeader,
                localData?.fontFamily,
                "text-center"
              )}
              style={{ color: localData?.headerColor }}
            >
              {localData.header}
            </h4>
            <div className="row">
              {card?.map((data, index) => (
                <div className="col-sm-12 col-lg-4" key={index}>
                  <img className={classes.img1} src={data.img} alt="fikle" />
                  <div
                    className={clsx(
                      " mt-0 p-md-3 p-2 text-center bg-light",
                      classes.ShareIcon
                    )}
                  >
                    {activeState === "create" + index ? (
                      <>
                        <CopyToClipboard text={data.img}>
                          <button
                            onClick={() => setActiveState("share" + index)}
                            className="btn mt-2  "
                            // style={{ fontSize: "22px" }}
                          >
                            Create Link
                          </button>
                        </CopyToClipboard>
                      </>
                    ) : activeState === "share" + index ? (
                      <>
                        <div>
                          <FacebookShareButton
                            url={data.img}
                            className="mx-2 socialshare"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <TwitterShareButton
                            url={data.img}
                            className="mx-2 socialshare"
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={data.img}
                            source={"campuzone-sundar"}
                            className="mx-2 socialshare"
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                          <WhatsappShareButton
                            url={data.img}
                            className="mx-2 socialshare"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setActiveState("create" + index)}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <ShareIcon className="mx-1 d-inline" />
                          <h6
                            className="font-weight-bold d-inline"
                            // style={{ fontSize: "22px" }}
                          >
                            Share
                          </h6>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};
