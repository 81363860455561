import { Card1 } from "../Components/Sections/Card/Card1";
import Card2 from "../Components/Sections/Card/Card2";
import Card3 from "../Components/Sections/Card/Card3";
import { Card4 } from "../Components/Sections/Card/Card4";
import { Card5 } from "../Components/Sections/Card/Card5";
import { Hero1 } from "../Components/Sections/Hero/Hero1";
import { Hero2 } from "../Components/Sections/Hero/Hero2";
import Hero3 from "../Components/Sections/Hero/Hero3";
import Hero4 from "../Components/Sections/Hero/Hero4";
import Hero5 from "../Components/Sections/Hero/Hero5";
import Hero6 from "../Components/Sections/Hero/Hero6";

import { Navbar1 } from "../Components/Sections/Navbar/Navbar1";
import { Navbar2 } from "../Components/Sections/Navbar/Navbar2";
import { Slider1 } from "../Components/Sections/Testimonals/Slider1";
import Slider2 from "../Components/Sections/Testimonals/Slider2";
import { Gallery1 } from "../Components/Sections/Gallery/Gallery1";
import { Gallery2 } from "../Components/Sections/Gallery/Gallery2";
import Gallery3 from "../Components/Sections/Gallery/Gallery3";
import { Footer1 } from "../Components/Sections/Footer/Footer1";
import Footer2 from "../Components/Sections/Footer/Footer2";
import Footer3 from "../Components/Sections/Footer/Footer3";
import { ContactForm1 } from "../Components/Sections/Contact/ContactForm1";
import { ContactForm2 } from "../Components/Sections/Contact/ContactForm2";
import ContactForm3 from "../Components/Sections/Contact/ContactForm3";
import { ApplicationForm1 } from "../Components/Sections/ApplicationForm/ApplicationForm1";
import { Ticker1 } from "../Components/Sections/Tickers/Ticker1";
import Divider1 from "../Components/Sections/Divider/Divider1";
import Divider2 from "../Components/Sections/Divider/Divider2";
import Topbar1 from "../Components/Sections/Topbar/Topbar1";

export const LocalSections = [
  {
    group: "Navbar",
    variants: [
      { c: Navbar1, id: "Navbar1" },
      { c: Navbar2, id: "Navbar2" },
    ],
  },
  {
    group: "Ticker",
    variants: [{ c: Ticker1, id: "Ticker1" }],
  },
  {
    group: "Hero",
    variants: [
      { c: Hero1, id: "Hero1" },
      { c: Hero2, id: "Hero2" },
      { c: Hero3, id: "Hero3" },
      { c: Hero4, id: "Hero4" },
      { c: Hero5, id: "Hero5" },
      { c: Hero6, id: "Hero6" },
    ],
  },
  {
    group: "Card",
    variants: [
      { c: Card1, id: "Card1" },
      { c: Card2, id: "Card2" },
      { c: Card3, id: "Card3" },
      { c: Card4, id: "Card4" },
      { c: Card5, id: "Card5" },
    ],
  },
  {
    group: "Gallery",
    variants: [
      { c: Gallery1, id: "Gallery1" },
      { c: Gallery2, id: "Gallery2" },
      { c: Gallery3, id: "Gallery3" },
    ],
  },
  {
    group: "Testimonal",
    variants: [
      { c: Slider1, id: "Slider1" },
      { c: Slider2, id: "Slider2" },
    ],
  },
  {
    group: "Application Form",
    variants: [{ c: ApplicationForm1, id: "ApplicationForm1" }],
  },
  {
    group: "Contact",
    variants: [
      { c: ContactForm1, id: "ContactForm1" },
      { c: ContactForm2, id: "ContactForm2" },
      { c: ContactForm3, id: "ContactForm3" },
    ],
  },
  {
    group: "Footer",
    variants: [
      { c: Footer1, id: "Footer1" },
      { c: Footer2, id: "Footer2" },
      { c: Footer3, id: "Footer3" },
    ],
  },
  {
    group: "Divider",
    variants: [
      { c: Divider1, id: "Divider1" },
      { c: Divider2, id: "Divider2" },
    ],
  },

  {
    group: "TopNavbar",
    variants: [{ c: Topbar1, id: "Topbar1" }],
  },
];
