import React, { useContext, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import validator from "validator";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import clsx from "clsx";
import { toast } from "react-toastify";
import { db } from "../../../services/firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";

const useStyles = makeStyles(() =>
  createStyles({
    btnSubmit: {
      borderRadius: "20px",
      border: "1px solid #e6983f",
      backgroundColor: "#e6983f",
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "bold",
      padding: "12px 45px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      transition: "transform 80ms ease-in",
      cursor: "pointer",
      "&:focus": {
        opacity: "0.7",
      },
    },
    inputForm: {
      backgroundColor: "#eee",
      border: "none",
      padding: "12px 15px",
      margin: " 8px 0",
      width: "100%",
      fontSize: "0.8rem",
      "&:focus": { outline: "1px solid #e6983f" },
    },
    editableHeader: {
      width: "100%",
      fontSize: "25px",
      textAlign: "center",
      color: "white",
      background: "transparent",
      outline: "0",
      border: "none",
      marginBottom: "10px",
    },
    editable: {
      width: "100%",
      height: "fitContent",
      textAlign: "center",
      color: "white",
      background: "transparent",
      outline: "0",
      border: "none",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    "@media (max-width: 600px)": {
      inputForm: {
        padding: "10px 10px",
        margin: " 8px 0",
      },
      headingCT: {
        fontSize: "20px",
      },
      paraCT: {
        fontSize: "12px",
      },

      btnSubmit: {
        padding: "5px 20px",
      },
    },
  })
);

export const ContactForm2 = (props) => {
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
    company: "",
  };

  const [formData, setFormData] = useState(defaultValues);
  const onChange = (event) => {
    let val = event.target.value;
    setFormData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: val,
      };
    });
  };

  function formHandler(e) {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.message === ""
    ) {
      toast.warn("Fill the required fields");
    } else if (
      validator.isMobilePhone(formData.phone) === false &&
      validator.isEmail(formData.email) === false
    ) {
      toast.warn("Please enter valid mail & phone");
    } else if (validator.isMobilePhone(formData.phone) === false) {
      toast.warn("Please enter valid mobile number");
    } else if (validator.isEmail(formData.email) === false) {
      toast.warn("please enter valid mail");
    } else {
      let title = "Received A New Enquiry";
      ctx.formHandler(formData);
      ctx.mailSend(title, formData);
      toast.success("Submited Successfully");
      setFormData(defaultValues);
    }
  }

  const data = {
    header: "Let's Chat",
    para1:
      "Whether you have a question, want to start a project or simply want to connect.",
    para2: "Feel free to send me a message in the contact form",
    formheading: "Enquiry Form",
  };
  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined ? data : ctx.websiteData[props.id]
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const onChangeHandler = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };
  const classes = useStyles();
  let editable = (
    <>
      <div
        className="rounded mx-auto col-lg-7 col-md-10 row my-3 p-0"
        style={{
          boxShadow:
            "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)",
        }}
      >
        <div
          className="left-container  col-md-6 p-0 d-flex justify-content-center align-items-center  text-white"
          style={{ backgroundColor: "#e6983f" }}
        >
          <div className="text-center p-2" style={{ lineHeight: "22px" }}>
            {/* <h2>Let's Chat</h2> */}
            <input
              onChange={onChangeHandler}
              className={classes.editableHeader}
              id="header"
              placeholder="header"
              value={localData.header}
            />
            <p style={{ fontSize: "0.9rem" }}>
              <textarea
                onChange={onChangeHandler}
                className={classes.editable}
                id="para1"
                placeholder="para1"
                value={localData.para1}
              />
            </p>
            <br />
            <p style={{ fontSize: "0.9rem" }}>
              <textarea
                onChange={onChangeHandler}
                className={classes.editable}
                id="para2"
                placeholder="para2"
                value={localData.para2}
              />
            </p>
          </div>
        </div>
        <div className="right-container col-md-6 p-0  d-flex justify-content-center align-items-center bg-white">
          <div className="text-center p-3">
            <form action="#">
              <input
                onChange={onChangeHandler}
                className={classes.editableHeader}
                id="formheading"
                placeholder="formheading"
                style={{ color: "black" }}
                value={localData.formheading}
              />
              {/* <h2 className="lg-view">Enquiry Form</h2> */}

              <input
                type="text"
                placeholder="Name*"
                maxLength={40}
                onChange={(e) => onChange(e)}
                name="name"
                className={classes.inputForm}
              />
              <input
                type="email"
                name="email"
                maxLength={70}
                placeholder="Email *"
                onChange={(e) => onChange(e)}
                className={classes.inputForm}
              />
              <input
                type="text"
                name="company"
                maxLength={100}
                placeholder="Company"
                onChange={(e) => onChange(e)}
                className={classes.inputForm}
              />
              <input
                type="phone"
                name="phone"
                maxLength={10}
                placeholder="Phone"
                onChange={(e) => onChange(e)}
                className={classes.inputForm}
              />
              <textarea
                rows="4"
                name="message"
                placeholder="Message"
                onChange={(e) => onChange(e)}
                className={classes.inputForm}
              ></textarea>
              <button
                className={classes.btnSubmit}
                onClick={(e) => formHandler(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {ctx.isEditable ? (
        editable
      ) : (
        <>
          <div
            className="rounded mx-auto col-lg-7 col-md-10 row my-3 p-0"
            style={{
              boxShadow:
                "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)",
            }}
          >
            <div
              className="left-container  col-md-6 p-0 d-flex justify-content-center align-items-center  text-white"
              style={{ backgroundColor: "#e6983f" }}
            >
              <div className="text-center p-2" style={{ lineHeight: "22px" }}>
                <h2
                  className={classes.headingCT}
                  style={{ overflowWrap: "anywhere" }}
                >
                  {localData.header}
                </h2>
                <p
                  className={classes.paraCT}
                  style={{ overflowWrap: "anywhere" }}
                >
                  {localData.para1}
                </p>
                <br />
                <p className={classes.paraCT}>{localData.para2}</p>
              </div>
            </div>
            <div className="right-container col-md-6 p-0  d-flex justify-content-center align-items-center bg-white">
              <div className="text-center p-3">
                <form action="#">
                  <h2 className={classes.headingCT} className="lg-view">
                    {localData.formheading}
                  </h2>

                  <input
                    type="text"
                    placeholder="Name *"
                    onChange={(e) => onChange(e)}
                    name="name"
                    value={formData.name}
                    className={classes.inputForm}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email *"
                    value={formData.email}
                    onChange={(e) => onChange(e)}
                    className={classes.inputForm}
                  />
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    placeholder="Company"
                    onChange={(e) => onChange(e)}
                    className={classes.inputForm}
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    maxLength="10"
                    value={formData.phone}
                    onChange={(e) => onChange(e)}
                    className={classes.inputForm}
                  />
                  <textarea
                    rows="4"
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={(e) => onChange(e)}
                    className={classes.inputForm}
                  ></textarea>
                  <button
                    className={classes.btnSubmit}
                    onClick={(e) => formHandler(e)}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ContactForm2;
