import React from "react";
import styles from "./Divider.module.css";

function Divider2() {
  return (
    <>
      <div>
        <div className={styles.astrodivider}>
          <div className={styles.astrodividermask}></div>
          <span>
            <i>&#10038;</i>
          </span>
        </div>
      </div>
    </>
  );
}

export default Divider2;
