import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import validator from "validator";
import learn from "../../../Assests/images/learn.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useContext, useState, useCallback } from "react";
import AuthContext from "../../../Context/Context";
import Avatar from "@mui/material/Avatar";
import Loader from "../../../loader/Loader";
import getCroppedImg from "../../Crop/CropImage";
import Cropper from "react-easy-crop";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Button from "@material-ui/core/Button";
import { Box, Divider, SwipeableDrawer, Typography } from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const useStyles = makeStyles(() => ({
  totalform: {
    width: "100%",
    margin: "auto",
  },
  modalBox: {
    position: "fixed",
    left: "0",
    top: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
    zIndex: "9999",
  },
  btnStyle: {
    background: "#9e3a8ccc",
    fontSize: "20px",
    color: "white",
    borderRadius: "20px",
    boxShadow: "0 3px 6px #00000036",
  },
  formback: {
    background: "#FEF6FF",
    borderRadius: "8px",
    padding: "10px 0px",
    width: "100%",
    borderTop: "2px",
    borderTopColor: "#000",
  },
  // Avatar: {
  //   width: "100px",
  //   overflow: "hidden",
  //   height: "100px",
  // },
  textdec: {
    color: "#4B4453",
    fontSize: "15px",
  },

  btnclr: {
    color: "#fff",
    background: "#8E41A6",
  },
  image: {
    width: "100px",
    height: "100px",
  },
  firstbg: {
    background: "#9467DE",
    borderRadius: "8px",
  },
  inputFile: {
    width: 0,
    height: 0,
    opacity: 0,
    zIndex: "0",
  },
  inputLabel: {
    position: "absolute",
    background: "#fff",
    width: "2.0rem",
    height: "2.0rem",

    padding: "0.3rem",
    bottom: "0",
    zIndex: 20,
    textAlign: "center",
    cursor: "pointer",
    "& i": {
      fontSize: "1.2rem",
    },
  },
  "@media (max-width: 600px)": {
    formHead: {
      display: "none",
    },
    totalform: {
      padding: "0px",
    },
    // Avatar: {
    //   width: "100%",

    //   height: "100%",
    // },

    textdec2: {
      fontSize: "20px",
    },
    textdec: {
      fontSize: "10px",
    },
    formback: {
      padding: "10px",
    },
    appForm: {
      padding: "0px",
    },

    btnclr: {
      fontSize: "10px",
    },
  },
}));
export const ApplicationForm1 = (props) => {
  const ctx = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imagecrop, setImagecrop] = useState();
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [defaultData, setDefaultdata] = useState();

  const [style, setStyle] = React.useState("full");
  const [imageindex, setImageindex] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const defaultValues = {
    student_name: "",
    previous_school: "",
    current_class: "LKG",
    father_name: "",
    email: "",
    phone: "",
    message: "",
    img: "",
  };

  const [formData, setFormData] = useState(defaultValues);

  const data = {
    img: learn,
    id: "appimage",
    backgroundColor: "",
    headerColor: "white",
    fontFamily: "",
    imgBackground: "",
    formbackround: "",
    formheadingcolor: "",
    formheadingfont: "",
    formlabelcolor: "",
    buttonbackground: "",
    buttontextcolor: "",
  };
  let pageData = ctx.websiteData[ctx.selectedPage];

  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : data
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  // setError(null);
  var allowedExtensions = /(\.jpeg|\.png|\.jpg)$/i;
  const onSelectFile = (e, i) => {
    let selected = e.target.files[0];

    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    if (imagesize > ctx.user?.Image_size) {
      toast.error("Image size cannot be exceeded" + ctx.user.Image_size);
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImagecrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageindex(i);
    }
  };

  function cancelCrop() {
    setImagecrop();
    setImageindex();
  }
  const showCroppedImage = useCallback(async () => {
    try {
      setloading(true);
      const croppedImage = await getCroppedImg(
        imagecrop,
        croppedAreaPixels
        // rotation
      );

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }

      //Usage example:
      var file = dataURLtoFile(croppedImage, "txt");

      const storage = getStorage();
      const uploadPath =
        ctx.userId + "/" + localData.id + ctx.selectedPage + props.id; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            return {
              ...prevState,
              img: url,
            };
          });

          setImagecrop();
          setImageindex();
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // setError(null);
  function formHandler(e) {
    e.preventDefault();
    if (
      formData.student_name === "" ||
      formData.father_name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.current_class === ""
    ) {
      toast.warn("Fill the required fields");
    } else if (
      validator.isMobilePhone(formData.phone) === false &&
      validator.isEmail(formData.email) === false
    ) {
      toast.warn("Please enter valid mail & phone");
    } else if (validator.isMobilePhone(formData.phone) === false) {
      toast.warn("Please enter valid mobile number");
    } else if (validator.isEmail(formData.email)) {
      console.log(formData);
      ctx.appformHandler(formData);
      let title = "Application Form";
      ctx.mailSend(title, formData);
      toast.success("Submited Successfully");
      setFormData(defaultValues);
    } else {
      toast.warn("please enter valid mailid");
    }
  }

  const options = [
    "LKG",
    "UKG",
    "1st Standard",
    "2nd Standard",
    "3rd Standard",
    "4th Standard",
    "5th Standard",
    "6th Standard",
    "7th Standard",
    "8th Standard",
    "9th Standard",
    "10th Standard",
    "11th Standard",
    "12th Standard",
  ];

  const onProImageChange = (e) => {
    // setError(null);
    let d = new Date().getTime();
    let selected = e.target.files[0];
    const storage = getStorage();
    const uploadPath = ctx.userId + "/" + e.target.name;
    const storageRef = ref(storage, uploadPath); // upload path
    if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else {
      setloading(true);
      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setFormData((prevState) => {
            return {
              ...prevState,
              img: url,
            };
          });
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    }
    // create refernce to store data
  };

  const onChange = (event) => {
    let val = event.target.value;
    setFormData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: val,
      };
    });
  };

  const classes = useStyles();

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                backgroundColor: "",
                headerColor: "white",
                fontFamily: "",
                imgBackground: "",
                formbackround: "",
                formheadingfont: "",
                formheadingcolor: "",
                formlabelcolor: "",
                buttonbackground: "",
                buttontextcolor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>

          <Divider />

          <p variant="p" className="my-2">
            Background Styles
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <p variant="p" className="my-2">
            Image Styles
          </p>
          <small>Image Background</small>
          <HexColorPicker
            color={localData.imgBackground}
            onChange={(color) =>
              setLocalData({
                ...localData,
                imgBackground: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.imgBackground}
            onChange={(e) => {
              setLocalData({
                ...localData,
                imgBackground: e.target.value,
              });
            }}
            maxLength={10}
          />

          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData?.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />

          <p variant="p" className=" my-2">
            Form style
          </p>
          <small>Form Background</small>
          <HexColorPicker
            color={localData.formbackround}
            onChange={(color) =>
              setLocalData({
                ...localData,
                formbackround: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.formbackround}
            onChange={(e) => {
              setLocalData({
                ...localData,
                formbackround: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />

          <small> Form Heading Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.formheadingfont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                formheadingfont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Form Heading Color</small>
          <HexColorPicker
            color={localData.formheadingcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                formheadingcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.formheadingcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                formheadingcolor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <small>Label color</small>
          <HexColorPicker
            color={localData.formlabelcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                formlabelcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.formlabelcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                formlabelcolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <small>Button background</small>
          <HexColorPicker
            color={localData.buttonbackground}
            onChange={(color) =>
              setLocalData({
                ...localData,
                buttonbackground: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.buttonbackground}
            onChange={(e) => {
              setLocalData({
                ...localData,
                buttonbackground: e.target.value,
              });
            }}
            maxLength={10}
          />

          <small>Button Text color</small>
          <HexColorPicker
            color={localData.buttontextcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                buttontextcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.buttontextcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                buttontextcolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {imagecrop && ctx.isEditable && (
        <div className={classes.modalBox}>
          <Cropper
            image={imagecrop}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            onCropChange={setCrop}
            aspect={6 / 4}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

          <div
            className="text-center my-2"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              onClick={showCroppedImage}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-4")}
              style={{ fontSize: "30px" }}
            >
              Crop
            </Button>
            <Button
              onClick={cancelCrop}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-3")}
              style={{ fontSize: "30px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {ctx.isEditable ? (
        <>
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div
        className={clsx(classes.totalform, " container row ")}
        style={{ background: localData?.backgroundColor }}
      >
        <div
          className={clsx(
            classes.firstbg,
            " d-flex justify-content-center align-items-center "
          )}
          style={{ background: localData?.imgBackground }}
        >
          <div className={clsx(classes.formHead, "col-md-6  p-0")}>
            <div className="row d-flex justify-content-center align-items-center">
              <h3
                style={{ color: localData?.headerColor }}
                className={clsx("mt-2", localData?.fontFamily)}
              >
                OPEN FOR REGISTRATION
              </h3>
            </div>
            <div className="row postion-relative">
              <img className={classes.image2} src={localData.img} />
              {ctx.isEditable && (
                <>
                  {" "}
                  <input
                    type="file"
                    onChange={(e) => onSelectFile(e)}
                    className={classes.inputFile}
                    id="Application"
                    name={"Application"}
                  />
                  <label className={classes.inputLabel} htmlFor={"Application"}>
                    <i className="fa fa-upload"></i>
                  </label>
                </>
              )}
            </div>
          </div>

          <div className={clsx(classes.appForm, "col")}>
            <div
              style={{ background: localData?.formbackround }}
              className={clsx(classes.formback, "container")}
            >
              <div className="text-center">
                <h3
                  style={{ color: localData?.formheadingcolor }}
                  className={clsx(
                    classes.textdec2,
                    localData?.formheadingfont,
                    "mb-3"
                  )}
                >
                  ADMISSION FORM
                </h3>
              </div>
              <div className="p-2 px-md-4">
                <form>
                  <div className=" d-flex justify-content-center align-items-center position-relative">
                    <Avatar
                      src={formData.img}
                      className={classes.Avatar}
                      style={{
                        width: "100px",
                        overflow: "hidden",
                        height: "100px",
                      }}
                    />
                    <input
                      type="file"
                      onChange={(e) => onProImageChange(e)}
                      className={classes.inputFile}
                      id="profile"
                      name="profile"
                    />
                    <label
                      style={{ color: localData?.formlabelcolor }}
                      className={classes.inputLabel}
                      htmlFor="profile"
                    >
                      <i className="fa fa-upload"></i>
                    </label>
                  </div>
                  <div className="form-row mt-1">
                    <div className="col">
                      <label
                        style={{ color: localData?.formlabelcolor }}
                        className={classes.textdec}
                        htmlFor="formGroupExampleInput"
                      >
                        Student Name*
                      </label>
                      <input
                        type="text"
                        name="student_name"
                        className="form-control"
                        placeholder="Student Name"
                        maxLength={50}
                        value={formData.student_name}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-row mt-1">
                    <div className="form-group col-md-6">
                      <label
                        style={{ color: localData?.formlabelcolor }}
                        className={classes.textdec}
                        htmlFor="inputState"
                      >
                        Previous school
                      </label>
                      <input
                        type="text"
                        name="previous_school"
                        className="form-control"
                        value={formData.previous_school}
                        placeholder="Previous school"
                        maxLength={100}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label
                        style={{ color: localData?.formlabelcolor }}
                        className={classes.textdec}
                        htmlFor="inputState"
                      >
                        Current Class*
                      </label>
                      <select
                        id="inputState"
                        name="current_class"
                        className="form-control"
                        onChange={(e) => onChange(e)}
                        value={formData.current_class}
                      >
                        {options.map((data, index) => (
                          <option key={index} value={data}>
                            {data}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row mt-1">
                    <div className="col">
                      <label
                        style={{ color: localData?.formlabelcolor }}
                        className={classes.textdec}
                        htmlFor="formGroupExampleInput"
                      >
                        Father's Name*
                      </label>
                      <input
                        type="text"
                        name="father_name"
                        className="form-control"
                        value={formData.father_name}
                        placeholder="Father's Name"
                        maxLength={50}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-row mt-1">
                    <div className="col">
                      <label
                        style={{ color: localData?.formlabelcolor }}
                        className={classes.textdec}
                        htmlFor="formGroupExampleInput"
                      >
                        Email Id*
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Id"
                        value={formData.email}
                        maxLength={50}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-row mt-1">
                    <div className="col">
                      <label
                        style={{ color: localData?.formlabelcolor }}
                        className={classes.textdec}
                        htmlFor="formGroupExampleInput"
                      >
                        Mobile Number*
                      </label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        value={formData.phone}
                        placeholder="Mobile Number"
                        maxLength={10}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-row mt-1">
                    <div className="col form-group">
                      <label
                        style={{ color: localData?.formlabelcolor }}
                        className={classes.textdec}
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        name="message"
                        value={formData.message}
                        onChange={(e) => onChange(e)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      style={{
                        color: localData?.buttontextcolor,
                        background: localData?.buttonbackground,
                      }}
                      className={clsx(classes.btnclr, "btn  btn-sm")}
                      onClick={(e) => formHandler(e)}
                    >
                      Send Application
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
