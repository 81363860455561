import React, { useContext, useState, useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import WebFont from "webfontloader";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";
import { font } from "../../../utilitis/Fonts";
import { HexColorPicker } from "react-colorful";
import clsx from "clsx";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      background: "#efefef",
      fontFamily: "Open Sans",
      padding: "70px 0px",
    },
    container: {
      position: "relative",
      width: "60%",
      margin: "auto",
      padding: "40px 20px",
      boxShadow:
        " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;",
      borderRadius: "15px",
      background: "white",
    },
    inside: {
      position: "relative",
    },
    introHeader: {
      fontSize: "40px",
      color: "#555",
      textTransform: "capitalize",
      textAlign: "center",
      paddingBottom: "1.5rem",
      background: "transparent",
      outline: 0,
      margin: "20px 0",
      border: "none",
      width: "100%",
      fontFamily: "Raleway",
      fontWeight: "700",
    },
    testName: {
      color: "#555",
      fontFamily: "Raleway",
      marginTop: "5px",
      fontSize: "30px",
      fontWeight: "600",
      textAlign: "center",
    },
    para: {
      textAlign: "center",
      fontSize: "20px",
    },
    editable: {
      color: "black",
      textAlign: "center",
      background: "transparent",
      outline: "0",
      border: "none",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    addCard: {
      borderRadius: "1rem",
      position: "absolute",
      background: "#fff",
      padding: "1rem 2rem",
      top: "1rem",
      color: "#9e3a8ccc",
      cursor: "pointer",
      right: "1rem",
      boxShadow: "2px 2px 3px 0 #ccc",
    },
    "@media (max-width: 600px)": {
      root: {
        padding: "20px 0px",
      },
      container: {
        width: "95%",
        padding: "20px",
      },
      introHeader: {
        fontSize: "20px",
        margin: "0px",
      },
      para: {
        textAlign: "center",
        fontSize: "11px",
      },
      testName: {
        fontSize: "18px",
      },
    },
  })
);

export const Slider1 = (props) => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Raleway"],
      },
    });
  }, []);
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const location = useLocation();

  const [imageindex, setImageindex] = useState();
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const cardData = {
    header: "what people Think About US",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    innerBackground: "",
    data: [
      {
        name: "Intro header 1",
        titlecolor: "",
        titlefont: "",
        para: "If Shai Reznik's TDD videos don't convince you to add automated testing your code, I don't know what will.This was the very best explanation of frameworks for brginners that I've ever seen",
        paracolor: "",
        parafont: "",
        img: "https://www.w3schools.com/howto/img_avatar.png",
        id: "0",
      },
      {
        name: "Intro header 1",
        titlecolor: "",
        titlefont: "",
        para: "If Shai Reznik's TDD videos don't convince you to add automated testing your code, I don't know what will.This was the very best explanation of frameworks for brginners that I've ever seen",
        paracolor: "",
        parafont: "",
        img: "https://www.w3schools.com/howto/img_avatar.png",
        id: "1",
      },
      {
        name: "Intro header 1",
        titlecolor: "",
        titlefont: "",
        para: "If Shai Reznik's TDD videos don't convince you to add automated testing your code, I don't know what will.This was the very best explanation of frameworks for brginners that I've ever seen",
        paracolor: "",
        parafont: "",
        img: "https://www.w3schools.com/howto/img_avatar.png",
        id: "2",
      },
    ],
  };

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : cardData
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [card, setCard] = useState(localData.data);
  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };
  const options = {
    loop: true,
    margin: 50,
    dots: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 2000,
    autoplayHoverPause: true,
    mouseDrag: location.pathname.includes("layout") ? false : true,
    items: 1,
  };

  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of card) {
      TempArr.push({
        ...obj,
        titlecolor: card[index].titlecolor,
        titlefont: card[index].titlefont,
        paracolor: card[index].paracolor,
        parafont: card[index].parafont,
        backgroundColor: card[index].backgroundColor,
      });
    }
    setCard(TempArr);
  }

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setCard((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const classes = useStyles();
  const onImageChange = (e, i) => {
    // setError(null);

    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    const storage = getStorage();
    const uploadPath =
      ctx.userId +
      "/" +
      card[i].name +
      card[i].id +
      ctx.selectedPage +
      props.id; // upload path
    const storageRef = ref(storage, uploadPath); // create refernce to store data
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      setloading(true);
      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setCard((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[i],
              img: url,
            };
            prevState[i] = updatedData;
            return [...prevState];
          });
          setTimeout(() => {
            setloading(false);
            toast.success("Image Uploaded");
          }, 2000);
        });
      });
    }

    // setError(null);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>

          <Button
            onClick={() => {
              setCard((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  titlecolor: "",
                  titlefont: "",
                  paracolor: "",
                  parafont: "",
                  backgroundColor: "",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>

          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>

          <Divider />

          <p variant="p" className=" my-2">
            Name Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={card[imageindex].titlefont}
            onChange={(e) =>
              setCard((prevState) => {
                prevState[imageindex].titlefont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={card[imageindex]?.titlecolor}
            onChange={(color) => {
              setCard((prevState) => {
                prevState[imageindex].titlecolor = color;
                return [...prevState];
              });
            }}
          />
          <input
            className="form-control my-2"
            value={card[imageindex]?.titlecolor}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].titlecolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Content Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={card[imageindex].parafont}
            onChange={(e) =>
              setCard((prevState) => {
                prevState[imageindex].parafont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small> Text Color</small>
          <HexColorPicker
            color={card[imageindex].paracolor}
            onChange={(color) =>
              setCard((prevState) => {
                prevState[imageindex].paracolor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={card[imageindex]?.paracolor}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].paracolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
                innerBackground: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <p variant="p" className=" my-2">
            Background Styles
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <small>Container Background</small>
          <HexColorPicker
            color={localData.innerBackground}
            onChange={(color) =>
              setLocalData({
                ...localData,
                innerBackground: color,
              })
            }
          />{" "}
          <input
            className="form-control my-2"
            value={localData?.innerBackground}
            onChange={(e) => {
              setLocalData({
                ...localData,
                innerBackground: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              // setCard(defaultData);
              toggleDrawer(e, false);
            } else {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      img: "https://www.w3schools.com/howto/img_avatar.png",
      name: "",
      para: "",
      id: card.length,
    };
    setCard((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setCard((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };
  let editable = (
    <div>
      <input
        className={clsx(classes.introHeader, localData?.fontFamily)}
        style={{ color: localData?.headerColor }}
        placeholder="Header"
        id="header"
        onChange={onChange}
        value={localData.header}
      />

      {card.length < ctx.user?.Card_limit && (
        <div className={classes.addCard} onClick={addCard}>
          <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add Card
        </div>
      )}
      <div
        className={classes.container}
        style={{ background: localData.innerBackground }}
      >
        {card.map((details, index) => (
          <div key={index} className={classes.inside}>
            <Button
              onClick={(e) => {
                setStyle("card");
                setImageindex(index);
                setDefaultdata(card);
                toggleDrawer(e, true);
              }}
              className=""
            >
              Change Styles
            </Button>
            <div
              onClick={() => removeCard(details.id)}
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                zIndex: 20,
                cursor: "pointer",
              }}
            >
              <DeleteIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  fill: "#dc3545",
                  padding: "5px",
                }}
              />
            </div>
            <div id="image-caption" className={classes.testName}>
              <input
                onChange={(e) => onChangeHandler(e, details, index)}
                id="name"
                placeholder="name"
                className={clsx(classes.editable, details?.titlefont)}
                style={{ color: details?.titlecolor }}
                value={details.name}
                maxLength={50}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
              }}
            >
              <input
                type="file"
                onChange={(e) => onImageChange(e, index)}
                className={clsx(classes.inputFile)}
                id={details.id}
                name={details.name}
              />
              <label className={classes.inputLabel} htmlFor={details.id}>
                <i className="fa fa-upload"></i>
              </label>
              <Avatar
                src={details.img}
                alt={details.name}
                // style={{ width: "50px", borderRadius: "50%" }}
              />
            </div>

            <textarea
              onChange={(e) => onChangeHandler(e, details, index)}
              id="para"
              className={clsx(classes.editable, details?.parafont)}
              style={{ width: "100%", color: details?.paracolor }}
              placeholder="Text"
              maxLength={300}
              value={details.para}
            />
          </div>
        ))}
      </div>
    </div>
  );

  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      data: card,
    };

    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "white",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}

      <div
        className={classes.root}
        style={{ background: localData?.backgroundColor }}
      >
        {ctx.isEditable ? (
          editable
        ) : (
          <>
            <h2
              className={clsx(classes.introHeader, localData?.fontFamily)}
              style={{ color: localData?.headerColor }}
            >
              {localData.header}
            </h2>
            <div
              className={classes.container}
              style={{ background: localData.innerBackground }}
            >
              <OwlCarousel className="owl-theme" {...options}>
                {card.map((item, index) => (
                  <div key={index}>
                    <div
                      id="image-caption"
                      className={clsx(classes.testName, item?.titlecolor)}
                      style={{ color: item?.titlecolor }}
                    >
                      {item.name}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px",
                      }}
                    >
                      <Avatar
                        src={item.img}
                        alt={item.name}
                        // style={{ width: "50px", borderRadius: "50%" }}
                      />
                    </div>
                    <p
                      className={clsx(classes.para, item?.parafont)}
                      style={{ color: item?.paracolor }}
                    >
                      {item.para}{" "}
                    </p>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </>
        )}
      </div>
    </>
  );
};
