import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../services/firebase";
import "./Messages.css";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { useContext, useEffect, useState, useCallback } from "react";
import * as React from "react";

import { createStyles, makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import PreviewIcon from "@mui/icons-material/Preview";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

import { toast } from "react-toastify";
import AuthContext from "../../Context/Context";
import { Appform, Contactform } from "../../Components/Messages/Popup";
import { Divider, IconButton } from "@mui/material";
import Loader from "../../loader/Loader";

const useStyles = makeStyles(() => createStyles({}));
const Nosections = (props) => {
  const { data } = props;
  return (
    <div
      className="p-2 mx-3 col-md-10 mt-5  m-auto"
      style={{
        boxShadow: "0px 3px 6px #00000036",
        borderRadius: "5rem",
        fontFamily: "raleway",
      }}
    >
      <h5 className="text-center font-weight-bold">{data}</h5>
      <div className="d-flex justify-content-center">
        <lottie-player
          src="https://assets6.lottiefiles.com/packages/lf20_pgeevipp.json"
          background="transparent"
          speed="1"
          style={{
            width: "700px",
            height: "500px",
            // transform: "scale(1.9)",
          }}
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
  );
};
export const Messages = () => {
  const classes = useStyles();
  const ctx = useContext(AuthContext);
  useEffect(() => {
    ctx.getFormData();
    ctx.getAppFormData();
  }, []);

  const [appmodal, setAppmodal] = useState(false);
  const [contactmodal, setContactmodal] = useState(false);

  const [data, setData] = useState();

  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function removeForm(e) {
    if (value === "two") {
      updateDoc(doc(db, "formdata", ctx.userId), {
        applicationform: ctx.appData.filter((x) => x.id !== e),
      }).then(() => {
        toast.success("Application was removed");

        ctx.getAppFormData();
      });
    } else {
      updateDoc(doc(db, "formdata", ctx.userId), {
        message: ctx.messageData.filter((x) => x.id !== e),
      }).then(() => {
        toast.success("Message was removed");
        ctx.getFormData();
      });
    }
  }

  const [posts, SetPosts] = useState([]);
  const [postPerPage, SetPostPerPage] = useState(10);
  const [currentPage, SetCurrentPage] = useState(1);

  function appHandler(data) {
    setAppmodal(true);
    setData(data);
  }
  function contactHandler(data) {
    setContactmodal(true);
    setData(data);
  }

  useEffect(() => {
    SetPosts(value === "two" ? ctx.appData : ctx.messageData);
  }, [ctx.messageData, ctx.appData, value]);

  const [pageItem, SetPageItem] = useState({
    start: 0,
    end: postPerPage,
  });

  const onPageChangeEvent = (start, end) => {
    SetPageItem({
      start: start,
      end: end,
    });
  };

  const OnPerPostChangeEvent = (e) => {
    SetPostPerPage(e.target.value);
    SetCurrentPage(1);
  };

  const numOfPages = Math.ceil(posts.length / postPerPage);

  // console.log(numOfPages);

  const numOfButtons = [];
  for (let i = 1; i <= numOfPages; i++) {
    numOfButtons.push(i);
  }

  const prevPageClick = () => {
    if (currentPage === 1) {
      SetCurrentPage(currentPage);
    } else {
      SetCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
    }
  };

  const nextPageClick = () => {
    if (currentPage === numOfButtons.length) {
      SetCurrentPage(currentPage);
    } else {
      SetCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  useEffect(() => {
    let tempNumberOfButtons = [...arrOfCurrButtons];

    let dotsInitial = "...";
    let dotsLeft = "... ";
    let dotsRight = " ...";

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons;
    } else if (currentPage >= 1 && currentPage <= 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dotsInitial, numOfButtons.length];
    } else if (currentPage === 4) {
      const sliced = numOfButtons.slice(0, 5);
      tempNumberOfButtons = [...sliced, dotsInitial, numOfButtons.length];
    } else if (currentPage > 4 && currentPage < numOfButtons.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numOfButtons.slice(currentPage - 2, currentPage);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numOfButtons.slice(currentPage, currentPage + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfButtons = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numOfButtons.length,
      ];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (currentPage > numOfButtons.length - 3) {
      // > 7
      const sliced = numOfButtons.slice(numOfButtons.length - 4);
      // slice(10-4)
      tempNumberOfButtons = [1, dotsLeft, ...sliced];
    } else if (currentPage === dotsInitial) {
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      SetCurrentPage(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentPage === dotsRight) {
      SetCurrentPage(arrOfCurrButtons[3] + 2);
    } else if (currentPage === dotsLeft) {
      SetCurrentPage(arrOfCurrButtons[3] - 2);
    }

    setArrOfCurrButtons(tempNumberOfButtons);
    const value = currentPage * postPerPage;

    onPageChangeEvent(value - postPerPage, value);
  }, [currentPage, postPerPage, numOfPages]);
  const timeString = (time) => {
    let d = new Date(time);
    var value = d.toLocaleString();
    return value;
  };

  return (
    <>
      {ctx.messageData.length === undefined && <Loader />}
      {appmodal && (
        <Appform id={data} submitHandler={() => setAppmodal(false)} />
      )}
      {contactmodal && (
        <Contactform id={data} submitHandler={() => setContactmodal(false)} />
      )}
      <div className="container ml-2 my-2 style-guide">
        <h2 className="text-center mb-3 " style={{ color: "var(--primary)" }}>
          {" "}
          Enquiry / Application Forms
        </h2>

        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="one" label="Enquiry" />
            <Tab value="two" label="Application Forms" />
          </Tabs>
        </Box>
        {value === "one" ? (
          <>
            {posts?.length === 0 && (
              <div className="">
                <Nosections data={"No Enquiries"} />
              </div>
            )}
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {posts?.slice(pageItem.start, pageItem.end).map((data, index) => (
                <>
                  <ListItem className="w-75  bg-white shadow m-2">
                    <ListItemAvatar>
                      <Avatar src={"ji"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={data.name}
                      // primary={"helloo"}
                      secondary={"Applied on" + " " + timeString(data.id)}
                      // secondary={"Applied on" + " " + "10/9/2022"}
                    />
                    <IconButton
                      className="mx-2"
                      style={{
                        background: "#9e3a8ccc",
                      }}
                      onClick={() => contactHandler(data.id)}
                    >
                      <PreviewIcon style={{ fill: "white" }} />
                    </IconButton>

                    <IconButton
                      style={{
                        background: "#ff3333",
                      }}
                      onClick={() => removeForm(data.id)}
                    >
                      <DeleteIcon style={{ fill: "white" }} />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </>
        ) : (
          <>
            {posts.length === 0 && <Nosections data={"No Applications"} />}
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {posts?.slice(pageItem.start, pageItem.end).map((data, index) => (
                <>
                  <ListItem className="w-75  bg-white shadow m-2">
                    <ListItemAvatar>
                      <Avatar src={data.img} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={data.student_name}
                      // primary={"helloo"}
                      secondary={"Applied on" + " " + timeString(data.id)}
                      // secondary={"Applied on" + " " + "10/9/2022"}
                    />
                    <IconButton
                      className="mx-2"
                      style={{
                        background: "#9e3a8ccc",
                      }}
                      onClick={() => appHandler(data.id)}
                    >
                      <PreviewIcon style={{ fill: "white" }} />
                    </IconButton>

                    <IconButton
                      style={{
                        background: "#ff3333",
                      }}
                      onClick={() => removeForm(data.id)}
                    >
                      <DeleteIcon style={{ fill: "white" }} />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </>
        )}

        {posts.length >= 4 && (
          <div className="row m-0   justify-content-center align-items-center">
            <div
              className={` btn  text-white px-4  ${
                currentPage === 1 ? "arrow-disabled" : "buttonnav"
              }`}
              onClick={prevPageClick}
              role="button"
            >
              <i className="fas fa-angle-double-left"></i>
            </div>
            {arrOfCurrButtons.map((data, index) => (
              <button
                key={index}
                className={` d-sm-flex justify-content-center btn align-items-center d-none  p-2 mx-1 pages
                    ${currentPage === data ? "active" : ""}`}
                onClick={() => {
                  SetCurrentPage(data);
                }}
              >
                {data}
              </button>
            ))}
            <div
              className={`btn  text-white px-4
                ${
                  currentPage === numOfButtons.length
                    ? "arrow-disabled"
                    : "buttonnav"
                }`}
              onClick={nextPageClick}
              role="button"
            >
              <i className="fas fa-angle-double-right"></i>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
