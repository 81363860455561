import Marquee from "react-fast-marquee";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../Context/Context";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Loader from "../../../loader/Loader";
import { toast } from "react-toastify";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  modalWidth: {},
  textPara: {
    fontWeight: "500",
    cursor: "pointer",
    color: "#777",
    fill: "#777",
    display: "inline",
    "&:hover": {
      fill: "#b500b2",
      color: "#b500b2",
    },
  },
  tickerBox: {
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    background: "#9467DE",
    // backgroundImage:
    //   "linear-gradient(to right top, #443899, #643393, #7d2d8b, #902882, #a02577)",
    borderBottomLeftRadius: "5px",
    borderTopLeftRadius: "5px",
  },
  btndec: {
    border: "1px solid #6E3666",
  },

  "@media (max-width: 600px)": {
    marqueeText: {
      fontSize: "10px",
    },
  },
}));

export const Ticker1 = (props) => {
  const classes = useStyles();
  const ctx = useContext(AuthContext);
  const [loading, setloading] = useState(false);

  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const newsData = {
    tabBackground: "",
    tabColor: "white",
    tabFont: "",
    paracolor: "",
    parafont: "",
    circlecolor: "",
    data: [
      {
        para: " Quality education in the early years of preschool sets a firm  foundation in the overall development of a child.",
        id: 1,
      },
    ],
  };
  let pageData = ctx.websiteData[ctx.selectedPage];

  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : newsData
  );
  const [newsItems, setNewsItems] = React.useState(newsData?.data);

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? newsData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  React.useEffect(() => {
    setNewsItems(localData?.data);
  }, [localData]);

  const [newsUpdate, setnewsUpdate] = useState();

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                tabBackground: "",
                tabColor: "white",
                tabFont: "",
                paracolor: "",
                parafont: "",
                circlecolor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Tab styles
          </p>
          <HexColorPicker
            color={localData.tabBackground}
            onChange={(color) =>
              setLocalData({
                ...localData,
                tabBackground: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.tabBackground}
            onChange={(e) => {
              setLocalData({
                ...localData,
                tabBackground: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.tabFont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                tabFont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>

          <HexColorPicker
            color={localData?.tabColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                tabColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.tabColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                tabColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <p variant="p" className="my-2">
            Para Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.parafont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                parafont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small>Text Color</small>
          <HexColorPicker
            color={localData?.paracolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                paracolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.paracolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                paracolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Circle Styles
          </p>
          <HexColorPicker
            color={localData?.circlecolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                circlecolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.circlecolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                circlecolor: e.target.value,
              });
            }}
            maxLength={10}
          />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      para: newsUpdate,
      id: newsItems.length + 1,
    };
    if (newsUpdate === "") {
      alert("Field was empty");
    } else {
      setNewsItems((prevState) => {
        return [...prevState, updatedData];
      });
    }

    setnewsUpdate("");
  };

  const removeCard = (value) => {
    setNewsItems((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  let editable = (
    <>
      <div className="container">
        <div>
          <button
            onClick={addCard}
            type="button"
            className={clsx(classes.btndec, "btn btn-light")}
          >
            Add news
          </button>
        </div>
        <div>
          <>
            <form className="mt-4">
              <div className="form-row">
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Put news here!!"
                    id=""
                    value={newsUpdate}
                    onChange={(e) => setnewsUpdate(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </>
        </div>
        <div className="mt-4">
          <ul className="list-group list-group-flush">
            {newsItems?.map((data, index) => (
              <li
                key={data.id}
                className="list-group-item list-group-item-action"
              >
                {data.para}{" "}
                <RemoveCircleIcon
                  onClick={() => removeCard(data.id)}
                  className="float-right"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );

  const onSaveHandler = () => {
    setloading(true);

    const data = {
      ...localData,
      data: newsItems,
    };
    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "#fff",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <>
        <ThemeProvider theme={theme}>
          <div className="d-flex  ">
            {/* container mb-2 */}
            <div
              className={clsx(
                classes.tickerBox,
                "col-md-2 col-lg-1 col-3 p-3 d-flex justify-content-center align-items-center"
              )}
              style={{
                background: localData.tabBackground,
                color: localData?.tabColor ? localData?.tabColor : "white",
              }}
            >
              <AssignmentTurnedInIcon className={classes.iconAd} />
              <h6 className={clsx(localData?.tabFont)}>News</h6>
            </div>
            <div className="col p-0 d-flex bg-white">
              <Marquee
                gradientColor="white"
                // reverse={true}
                // duration={20000}
                // height="3rem"
                // pauseOnHover={true}
              >
                {newsItems?.map((data, index) => (
                  <div
                    style={{ color: localData?.paracolor }}
                    className={clsx(localData?.parafont, classes.marqueeText)}
                    key={data.id}
                  >
                    <i
                      style={{ color: localData?.circlecolor }}
                      className="fa fa-circle mx-2"
                    ></i>
                    {data.para}
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </ThemeProvider>
      </>
      {ctx.isEditable && editable}
    </>
  );
};
