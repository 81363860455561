import React, { useContext, useState } from "react";
import styles from "./Card3.module.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import clsx from "clsx";
import { toast } from "react-toastify";
import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import getCroppedImg from "../../Crop/CropImage";
import { Box, Divider, SwipeableDrawer, Typography } from "@mui/material";
import { font } from "../../../utilitis/Fonts";
import { HexColorPicker } from "react-colorful";

const Card3 = (props) => {
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);

  const [imageindex, setImageindex] = useState();
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const cardData = {
    header: "Curriculam",
    headerColor: "",
    fontFamily: "",
    data: [
      {
        title: "Reading/write",
        titlecolor: "",
        titlefont: "",
        para: "It is a long established fact that a reader will be distracted",
        paracolor: "",
        parafont: "",
        backgroundColor: "",
        iconbackground: "",
        id: 0,
      },
      {
        title: "Maths/science",
        titlecolor: "",
        titlefont: "",
        para: "It is a long established fact that a reader will be distracted",
        paracolor: "",
        parafont: "",
        backgroundColor: "",
        iconbackground: "",
        id: 1,
      },
      {
        title: "Art",
        titlecolor: "",
        titlefont: "",
        para: "It is a long established fact that a reader will be distracted",
        paracolor: "",
        parafont: "",
        backgroundColor: "",
        iconbackground: "",
        id: 2,
      },
      {
        title: "Critical Thinking",
        titlecolor: "",
        titlefont: "",
        para: "It is a long established fact that a reader will be distracted",
        paracolor: "",
        parafont: "",
        backgroundColor: "",
        iconbackground: "",
        id: 3,
      },
    ],
  };

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : cardData
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [card, setCard] = useState(localData.data);
  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };
  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of card) {
      TempArr.push({
        ...obj,
        titlecolor: card[index].titlecolor,
        titlefont: card[index].titlefont,
        ratecolor: card[index].ratecolor,
        ratefont: card[index].ratefont,
        backgroundColor: card[index].backgroundColor,
        iconbackground: card[index].iconbackground,
      });
    }
    setCard(TempArr);
  }
  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.name] = e.target.value;
    } else {
      tempEventInputs["path"] = e.value;
    }
    setCard((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      title: "Add Title",
      para: "It is a long established fact that a reader will be distracted",
      id: card.length,
    };
    setCard((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setCard((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setCard((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  titlecolor: "",
                  titlefont: "",
                  paracolor: "",
                  parafont: "",
                  backgroundColor: "",
                  iconbackground: "",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>
          <Divider />
          <p variant="p" className="my-2">
            background Styles
          </p>

          <HexColorPicker
            color={card[imageindex].backgroundColor}
            onChange={(color) =>
              setCard((prevState) => {
                prevState[imageindex].backgroundColor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={card[imageindex]?.backgroundColor}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].backgroundColor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className=" my-2">
            Subtitle1 Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={card[imageindex].titlefont}
            onChange={(e) =>
              setCard((prevState) => {
                prevState[imageindex].titlefont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={card[imageindex]?.titlecolor}
            onChange={(color) => {
              setCard((prevState) => {
                prevState[imageindex].titlecolor = color;
                return [...prevState];
              });
            }}
          />
          <input
            className="form-control my-2"
            value={card[imageindex]?.titlecolor}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].titlecolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Subtitle2 Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={card[imageindex].parafont}
            onChange={(e) =>
              setCard((prevState) => {
                prevState[imageindex].parafont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small> Text Color</small>
          <HexColorPicker
            color={card[imageindex].paracolor}
            onChange={(color) =>
              setCard((prevState) => {
                prevState[imageindex].paracolor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={card[imageindex]?.paracolor}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].paracolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Icon Styles
          </p>

          <HexColorPicker
            color={card[imageindex].iconbackground}
            onChange={(color) =>
              setCard((prevState) => {
                prevState[imageindex].iconbackground = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={card[imageindex]?.iconbackground}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].iconbackground = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />

          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              // setCard(defaultData);
              toggleDrawer(e, false);
            } else {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  let editable = (
    <>
      <section className="culm position-relative" id="#curriculam">
        <div className={styles.curriculamheading}>
          <input
            placeholder="Header"
            id="header"
            style={{
              background: "transparent",
              fontSize: "30px",
              outline: "0",
              border: "none",
              width: "100%",
              textAlign: "center",
              color: localData?.headerColor,
            }}
            className={localData?.fontFamily}
            onChange={onChange}
            value={localData.header}
          />
        </div>
        <div className={clsx("container position-relative", styles.curriculam)}>
          {card.map((details, index) => (
            <div
              className={styles.curriculamcol}
              key={index}
              style={{ background: details?.backgroundColor }}
            >
              <Button
                onClick={(e) => {
                  setStyle("card");
                  setImageindex(index);
                  setDefaultdata(card);
                  toggleDrawer(e, true);
                }}
                className=""
              >
                Change Styles
              </Button>
              <div className={styles.cardin}>
                <div
                  className={clsx(styles.round, styles.ron3)}
                  style={{ background: details?.iconbackground }}
                >
                  <i
                    className="fa fa-pencil-square icon"
                    aria-hidden="true"
                  ></i>
                </div>

                <div
                  onClick={() => removeCard(details.id)}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: 20,
                    cursor: "pointer",
                  }}
                >
                  <DeleteIcon
                    style={{
                      width: "2rem",
                      height: "2rem",
                      fill: "#dc3545",
                      padding: "5px",
                    }}
                  />
                </div>
                <input
                  onChange={(e) => onChangeHandler(e, details, index)}
                  className={clsx(styles.inputHeading, details?.titlefont)}
                  style={{ color: details?.titlecolor }}
                  id="title"
                  name="title"
                  value={details.title}
                  maxLength={25}
                />
                <textarea
                  onChange={(e) => onChangeHandler(e, details, index)}
                  className={clsx(styles.inputPara, details?.parafont)}
                  style={{ color: details?.paracolor }}
                  id="para"
                  name="para"
                  value={details.para}
                />
              </div>
            </div>
          ))}
        </div>

        {card.length < ctx.user?.Card_limit && (
          <div className={styles.addCard} onClick={addCard}>
            <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add
            Card
          </div>
        )}
      </section>
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      data: card,
    };
    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "#fff",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      {ctx.isEditable ? (
        editable
      ) : (
        <section className="culm" id="#curriculam">
          <div className={styles.curriculamheading}>
            <h2
              style={{ color: localData?.headerColor }}
              className={localData?.fontFamily}
            >
              {localData.header}
            </h2>
          </div>
          <div className={clsx("container  text-center", styles.curriculam)}>
            {card.map((details, index) => (
              <div
                className={clsx(
                  "col-md-3 col-9 mx-auto m-1",
                  styles.curriculamcol
                )}
                key={index}
                style={{ background: details?.backgroundColor }}
              >
                <div className={styles.cardin}>
                  <div
                    className={clsx(styles.round, styles.ron3)}
                    style={{ background: details?.iconbackground }}
                  >
                    <i
                      className="fa fa-pencil-square icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <h2
                    style={{ color: details?.titlecolor }}
                    className={clsx(styles.cardin_h2, details?.titlefont)}
                  >
                    {details.title}
                  </h2>
                  <p
                    style={{ color: details?.paracolor }}
                    className={clsx(styles.cardin_p, details?.parafont)}
                  >
                    {details.para}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
};

export default Card3;
