import React, { useContext, useState, useCallback } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Divider, SwipeableDrawer, Typography } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import Dress1 from "../../../Assests/images/dress1.jpg";
import Dress2 from "../../../Assests/images/dress2.jpg";
import Dress3 from "../../../Assests/images/dress3.jpg";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { toast } from "react-toastify";
import clsx from "clsx";
import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import getCroppedImg from "../../Crop/CropImage";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { font } from "../../../utilitis/Fonts";
import { HexColorPicker } from "react-colorful";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      backgroundColor: "#efefef",
      padding: "1rem",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      // justifyContent:"space-between"
    },
    card: {
      position: "relative",
      background: "#fff",
      padding: "1rem",
      marginBottom: "0.5rem",
      "& img": {
        maxWidth: "100%",
        // height: "auto",
      },
      border: "10px solid transparent",
    },
    introHeader: {
      fontSize: "1.75rem !important",
      color: "#000",
      textTransform: "capitalize",
      textAlign: "center",
      paddingBottom: "1.5rem",
      background: "transparent",
      outline: 0,
      border: "none",
      width: "100%",
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    imgText: {
      fontSize: "0.75rem",
      color: "#333",
      fontWeight: "200",
      padding: "0.75rem 0",
      textTransform: "capitalize",
      background: "transparent",
      outline: 0,
      border: "none",
    },
    rate: {
      background: "transparent",
      outline: 0,
      border: "none",
      fontSize: "0.85rem",
      color: "#000",
      fontWeight: "500",
    },
    cardImage: {
      objectFit: "cover",
      width: "100%",
      height: "auto",
      overflow: "hidden",
    },
    addCard: {
      borderRadius: "1rem",
      position: "absolute",
      background: "#fff",
      padding: "1rem 2rem",
      top: "1rem",
      color: "#9e3a8ccc",
      cursor: "pointer",
      right: "1rem",
      boxShadow: "2px 2px 3px 0 #ccc",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    modalBox: {
      position: "fixed",

      left: "0",
      top: "0",
      bottom: "0",
      right: "0",
      margin: "auto",
      zIndex: "9999",
    },
    btnStyle: {
      background: "#9e3a8ccc",
      fontSize: "20px",
      color: "white",
      borderRadius: "20px",
      boxShadow: "0 3px 6px #00000036",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
      owlSlide: {
        ".owl-nav": {
          top: "16px",
        },
        "& .owl-nav.disabled": {
          display: "blockn",
        },
      },
      "@media (max-width: 600px)": {
        owlSlide: {
          ".owl-nav": {
            top: "20px",
          },
        },
      },
    },
  })
);

export const Gallery2 = (props) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const location = useLocation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imagecrop, setImagecrop] = useState();
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [defaultData, setDefaultdata] = useState();

  const [imageindex, setImageindex] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const [style, setStyle] = React.useState("full");

  const options = {
    loop: true,
    margin: 30,
    dots: false,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 2000,
    autoplayHoverPause: true,
    mouseDrag: location.pathname.includes("layout") ? false : true,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      750: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  const cardData = {
    header: "New Arrivals",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    data: [
      {
        img: Dress1,
        backgroundColor: "",
        id: "0",
      },
      {
        img: Dress2,
        backgroundColor: "",
        id: "1",
      },
      {
        img: Dress3,
        backgroundColor: "",
        id: "2",
      },
    ],
  };

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : cardData
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [card, setCard] = useState(localData?.data);

  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of card) {
      TempArr.push({
        ...obj,
        backgroundColor: card[index].backgroundColor,
      });
    }
    setCard(TempArr);
  }

  const onSelectFile = (e, i) => {
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImagecrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageindex(i);
    }
  };

  function cancelCrop() {
    setImagecrop();
    setImageindex();
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imagecrop,
        croppedAreaPixels
        // rotation
      );
      setloading(true);

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }
      let d = new Date().getTime();

      //Usage example:
      var file = dataURLtoFile(croppedImage, "txt");

      const storage = getStorage();
      const uploadPath =
        ctx.userId + "/" + card[imageindex].id + ctx.selectedPage + props.id; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setCard((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[imageindex],
              img: url,
            };
            prevState[imageindex] = updatedData;
            return [...prevState];
          });

          setImagecrop();
          setImageindex();
          setTimeout(() => {
            setloading(false);
            toast.success("Image uploaded");
          }, 2000);
        });
      });
    } catch (e) {}
  }, [croppedAreaPixels]);

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      img: Dress1,
      title: "",
      rate: "",
      id: card.length,
    };
    setCard((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setCard((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setCard((prevState) => {
                prevState[imageindex].backgroundColor = "";
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>
          <p variant="p" className="my-2">
            Border Styles
          </p>

          <HexColorPicker
            color={card[imageindex].backgroundColor}
            onChange={(color) =>
              setCard((prevState) => {
                prevState[imageindex].backgroundColor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={card[imageindex]?.backgroundColor}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].backgroundColor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Gallery background
          </p>

          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>

          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              // setCard(defaultData);
              toggleDrawer(e, false);
            } else {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  let editable = (
    <>
      <input
        className={clsx(classes.introHeader, localData?.fontFamily)}
        style={{ color: localData?.headerColor }}
        placeholder="Header"
        id="header"
        onChange={onChange}
        value={localData?.header}
      />
      <div className={classes.row}>
        {card?.map((details, index) => (
          <div
            key={index}
            className={classes.card}
            style={{
              width: "24%",
              marginRight: "1%",
              background: details?.backgroundColor,
            }}
          >
            <Button
              onClick={(e) => {
                setStyle("card");
                setImageindex(index);
                setDefaultdata(card);
                toggleDrawer(e, true);
              }}
              className=""
            >
              Change Styles
            </Button>
            <div
              onClick={() => removeCard(details.id)}
              style={{
                position: "absolute",
                background: "#fff",
                top: "0",
                left: "0",
                zIndex: 20,
                cursor: "pointer",
              }}
            >
              <DeleteIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  fill: "#dc3545",
                  padding: "5px",
                }}
              />
            </div>
            <input
              type="file"
              onChange={(e) => onSelectFile(e, index)}
              className={classes.inputFile}
              id={details.id}
              name={details.title}
            />
            <label className={classes.inputLabel} htmlFor={details.id}>
              <i className="fa fa-upload"></i>
            </label>
            <img
              src={details.img}
              alt={details.title}
              className={classes.cardImage}
            />
          </div>
        ))}
      </div>
      {card?.length < ctx.user?.Card_limit && (
        <div className={classes.addCard} onClick={addCard}>
          <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add Card
        </div>
      )}
    </>
  );

  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      data: card,
    };

    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <>
          <div className="row py-3 justify-content-end">
            <div className="row py-3 justify-content-end">
              <button
                className="btn px-5"
                onClick={onSaveHandler}
                style={{
                  background: "#9e3a8ccc",
                  fontSize: "20px",
                  color: "#fff",
                  borderRadius: "20px",
                  boxShadow: "0 3px 6px #00000036",
                }}
              >
                Save<i className="fa fa-save mx-2"></i>{" "}
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {imagecrop && ctx.isEditable && (
        <div className={classes.modalBox}>
          <Cropper
            image={imagecrop}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            onCropChange={setCrop}
            aspect={2 / 3}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

          <div
            className="text-center my-2"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              onClick={showCroppedImage}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-4")}
              style={{ fontSize: "30px" }}
            >
              Crop
            </Button>
            <Button
              onClick={cancelCrop}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-3")}
              style={{ fontSize: "30px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      <div
        className={clsx(classes.root, "")}
        style={{ background: localData?.backgroundColor }}
      >
        {ctx.isEditable && (
          <div>
            <React.Fragment>
              <Button
                onClick={(e) => {
                  setStyle("full");
                  setDefaultdata(localData);
                  toggleDrawer(e, true);
                }}
              >
                Change Styles
              </Button>
              <SwipeableDrawer
                anchor="right"
                open={state.right}
                // onClose={(e) => {
                //   toggleDrawer(e, false);
                // }}
                onOpen={(e) => {
                  toggleDrawer(e, true);
                }}
              >
                {state.right && list()}
              </SwipeableDrawer>
            </React.Fragment>
          </div>
        )}
        {ctx.isEditable ? (
          editable
        ) : (
          <>
            <p
              className={clsx(classes.introHeader, localData?.fontFamily)}
              style={{ color: localData?.headerColor }}
            >
              {localData?.header}
            </p>
            <OwlCarousel
              className={clsx(classes.owlSlide, "owl-theme")}
              {...options}
            >
              {card?.map((item, index) => (
                <div
                  className={classes.card}
                  key={index}
                  style={{ background: item.backgroundColor }}
                >
                  <img
                    src={item.img}
                    alt={item.title}
                    className={classes.cardImage}
                  />
                </div>
              ))}
            </OwlCarousel>
          </>
        )}
      </div>
    </>
  );
};
