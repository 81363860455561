import React, { useContext, useState, useCallback } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import clsx from "clsx";
import "./ContactForm1.css";
import Cat2 from "../../../Assests/images/cat2.jpg";
import Cat1 from "../../../Assests/images/cat1.jpg";
import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import getCroppedImg from "../../Crop/CropImage";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles(() =>
  createStyles({
    contactTitle: {
      position: "relative",
      display: "block",
      color: "#131313",
      fontSize: "36px",
      lineHeight: "46px",
      fontWeight: "700",
      textTransform: "uppercase",
    },
    cardImage: {
      objectFit: "contain",
      width: "100%",
      height: "auto",
      overflow: "hidden",
    },
    editableHeader: {
      width: "100%",
      fontSize: "25px",
      textAlign: "center",
      color: "red",
      background: "transparent",
      outline: "0",
      border: "none",
      marginBottom: "10px",
    },
    editable: {
      width: "100%",
      textAlign: "center",
      color: "black",
      background: "transparent",
      outline: "0",
      border: "none",
    },
    btnStyle: {
      background: "#9e3a8ccc",
      fontSize: "20px",
      color: "white",
      borderRadius: "20px",
      boxShadow: "0 3px 6px #00000036",
    },
    modalBox: {
      position: "fixed",

      left: "0",
      top: "0",
      bottom: "0",
      right: "0",
      margin: "auto",
      zIndex: "9999",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    "@media (max-width: 600px)": {
      col: {
        width: "100%",
      },
    },
  })
);

export const ContactForm1 = (props) => {
  const [loading, setloading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imagecrop, setImagecrop] = useState();
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [imageid, setImageid] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const ctx = useContext(AuthContext);
  const data = [
    {
      header: "layatex",
      para: "Fixyman is proud to be the name that nearly 1 million homeowners have trusted since 1996 for home improvement and repair,providing virtually any home rep.",
      address: "Intro header 1 Vel Nagar, Aavadi",
      contact: "l472487229",
      email: "loerum@gmail.com",
      img1: Cat1,
      img2: Cat2,
    },
  ];

  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined ? data : ctx.websiteData[props.id]
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);
  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setLocalData((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };
  const onSelectFile = (e) => {
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImagecrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageid(e.target.id);
    }
  };

  function cancelCrop() {
    setImagecrop();
    setImageid();
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imagecrop,
        croppedAreaPixels
        // rotation
      );
      setloading(true);

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }

      //Usage example:
      var file = dataURLtoFile(croppedImage, "txt");
      const storage = getStorage();

      const uploadPath =
        ctx.userId + "/" + imageid + ctx.selectedPage + props.id; // upload path

      const storageRef = ref(storage, uploadPath); // create refernce to store data

      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          if (imageid === "img1") {
            setLocalData((prevState) => {
              let updatedData = null;
              updatedData = {
                ...prevState[0],
                img1: url,
              };

              prevState[0] = updatedData;
              return [...prevState];
            });
          } else {
            setLocalData((prevState) => {
              let updatedData = null;
              updatedData = {
                ...prevState[0],
                img2: url,
              };

              prevState[0] = updatedData;
              return [...prevState];
            });
          }
          setImagecrop();
          setImageid();
          setTimeout(() => {
            setloading(false);
            toast.success(" Image Uploaded");
          }, 2000);
        });
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const classes = useStyles();
  let editable = (
    <div className="contact-address-area">
      {localData?.map((details, index) => (
        <div key={index} className="container">
          <div className="sec-title-style1 text-center max-width">
            <div className={classes.contactTitle}>Contact Us</div>
            <div className="text">
              <div className="decor-left">
                <span></span>
              </div>
              <p>Quick Contact</p>
              <div className="decor-right">
                <span></span>
              </div>
            </div>
            <div className="bottom-text">
              {/* <p>{details.para}</p> */}
              <input
                onChange={(e) => onChangeHandler(e, details, index)}
                className={classes.editable}
                id="para"
                placeholder="para"
                value={details.para}
              />
            </div>
          </div>
          <div className="contact-address-box row">
            {/* <!--Start Single Contact Address Box--> */}
            <div className="col-sm-4 single-contact-address-box text-center">
              <input
                type="file"
                onChange={(e) => onSelectFile(e, index)}
                className={classes.inputFile}
                id={"img1"}
                name={details.address}
              />
              <label className={classes.inputLabel} htmlFor="img1">
                <i className="fa fa-upload"></i>
              </label>
              <img src={details.img1} className={classes.cardImage} />
            </div>
            {/* <!--End Single Contact Address Box-->
              <!--Start Single Contact Address Box--> */}
            <div className="col-sm-4 single-contact-address-box main-branch rounded">
              {/* <h3>{item.header}</h3> */}
              <input
                onChange={(e) => onChangeHandler(e, details, index)}
                className={classes.editableHeader}
                id="header"
                placeholder="header"
                value={details.header}
              />
              <div className="inner">
                <ul>
                  <li>
                    <div className="text">
                      <h5>
                        <i className="fa fa-map-marker mx-2"></i>Address:
                      </h5>
                      <p className="mx-5">
                        {" "}
                        <input
                          onChange={(e) => onChangeHandler(e, details, index)}
                          className={classes.editable}
                          id="address"
                          placeholder="address"
                          maxLength={50}
                          value={details.address}
                        />
                        {/* {item.address} */}
                        <br />
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="text">
                      <h5>
                        {" "}
                        <i className="fa fa-phone mx-2"></i>Contact No:
                      </h5>
                      {/* <p className="mx-5">{item.contact}</p> */}
                      <input
                        onChange={(e) => onChangeHandler(e, details, index)}
                        className={classes.editable}
                        id="contact"
                        maxLength={15}
                        placeholder="contact"
                        value={details.contact}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="text">
                      <h5>
                        {" "}
                        <i className="fa fa-envelope mx-2"></i>Email:
                      </h5>
                      {/* <p className="mx-5">{item.email}</p> */}
                      <input
                        onChange={(e) => onChangeHandler(e, details, index)}
                        className={classes.editable}
                        id="email"
                        maxLength={50}
                        placeholder="email"
                        value={details.email}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!--End Single Contact Address Box-->
              <!--Start Single Contact Address Box--> */}
            <div className="col-sm-4 single-contact-address-box text-center">
              <input
                type="file"
                onChange={(e) => onSelectFile(e, index)}
                className={classes.inputFile}
                id={"img2"}
                name={details.contact}
              />
              <label className={classes.inputLabel} htmlFor="img2">
                <i className="fa fa-upload"></i>
              </label>
              <img src={details.img2} className={classes.cardImage} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {imagecrop && ctx.isEditable && (
        <div className={classes.modalBox}>
          <Cropper
            image={imagecrop}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

          <div
            className="text-center my-2"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              onClick={showCroppedImage}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-4")}
              style={{ fontSize: "30px" }}
            >
              Crop
            </Button>
            <Button
              onClick={cancelCrop}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-3")}
              style={{ fontSize: "30px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      {ctx.isEditable ? (
        editable
      ) : (
        <div className="contact-address-area">
          {localData?.map((item, index) => (
            <div key={index} className="container">
              <div className="sec-title-style1 text-center max-width">
                <div className="title">Contact Us</div>
                <div className="text">
                  <div className="decor-left">
                    <span></span>
                  </div>
                  <p>Quick Contact</p>
                  <div className="decor-right">
                    <span></span>
                  </div>
                </div>
                <div className="bottom-text">
                  <p>{item.para}</p>
                </div>
              </div>
              <div className="contact-address-box row">
                {/* <!--Start Single Contact Address Box--> */}
                <div className="col-sm-4 single-contact-address-box text-center">
                  <img
                    src={item.img1}
                    alt={item.header}
                    className={classes.cardImage}
                  />
                </div>
                {/* <!--End Single Contact Address Box-->
              <!--Start Single Contact Address Box--> */}
                <div className="col-sm-4 single-contact-address-box main-branch rounded">
                  <h3>{item.header}</h3>
                  <div className="inner">
                    <ul>
                      <li>
                        <div className="text">
                          <h5>
                            <i className="fa fa-map-marker mx-2"></i>Address:
                          </h5>
                          <p className="mx-2">
                            {" "}
                            {item.address}
                            <br />
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="text">
                          <h5>
                            {" "}
                            <i className="fa  fa-phone  mx-2"></i>Contact No:
                          </h5>
                          <p className="mx-2">{item.contact}</p>
                        </div>
                      </li>
                      <li>
                        <div className="text">
                          <h5>
                            {" "}
                            <i className="fa fa-envelope mx-2"></i>Email:
                          </h5>
                          <p className="mx-2">{item.email}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!--End Single Contact Address Box-->
              <!--Start Single Contact Address Box--> */}
                <div className="col-sm-4 single-contact-address-box text-center">
                  <img
                    src={item.img2}
                    alt={item.header}
                    className={classes.cardImage}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default ContactForm1;
