import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import "./Card4.css";
import child2 from "../../../Assests/images/child2.png";
import child3 from "../../../Assests/images/child3.png";
import child4 from "../../../Assests/images/child4.png";
import React, { useContext, useState, useCallback } from "react";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import { toast } from "react-toastify";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import getCroppedImg from "../../Crop/CropImage";
import { Box, Divider, SwipeableDrawer, Typography } from "@mui/material";
import { font } from "../../../utilitis/Fonts";
import { HexColorPicker } from "react-colorful";
import { color } from "@mui/system";

const useStyles = makeStyles(() => ({
  cardshadow: {
    width: "300px",
    borderRadius: "20px",
  },
  editable: {
    width: "100%",
    background: "transparent",
    outline: 0,
    border: "none",
    height: "fitContent",
    // textAlign: "center",
    color: "#fff",
  },
  subtitle: {
    width: "100%",
    background: "transparent",
    outline: 0,
    border: "none",
    textAlign: "center",
    color: "#6a6363",
  },
  addCard: {
    borderRadius: "1rem",
    heigh: "fit-content",
    background: "#fff",
    padding: "1rem 2rem",
    cursor: "pointer",
    top: "1rem",
    color: "#9e3a8ccc",
    right: "1rem",
    boxShadow: "2px 2px 3px 0 #ccc",
  },
  inputFile: {
    width: 0,
    height: 0,
    opacity: 0,
    zIndex: "0",
  },
  inputLabel: {
    position: "absolute",
    background: "#fff",
    width: "2.5rem",
    height: "2.5rem",
    padding: "0.3rem",
    bottom: "0",
    right: "0",
    zIndex: 20,
    textAlign: "center",
    cursor: "pointer",
    "& i": {
      fontSize: "1.75rem",
    },
  },
  cardBtn: {
    background: "linear-gradient(140deg, #f30c80 30%, #9c03c2)",
    outline: "transparent",
    border: "none",
    "& focus": {
      outline: "none !important",
      border: "none",
    },
    "& hover": {
      transition: "0.8s",
      backgroundColor: "rgba(0, 0, 0, 0.616)",
    },
  },
  modalBox: {
    position: "fixed",
    left: "0",
    top: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
    zIndex: "9999",
  },
  btnStyle: {
    background: "#9e3a8ccc",
    fontSize: "20px",
    color: "white",
    borderRadius: "20px",
    boxShadow: "0 3px 6px #00000036",
  },
  zoom: {
    overflow: "hidden",
    height: "auto",
  },

  zoomImg: {
    transition: "transform 0.3s ease-in-out",
    "& hover": {
      transform: "scale(1.5)",
    },
  },
  bright: {
    borderRight: "1px dashed rgba(0, 0, 0, 0.397)",
  },
  cardHover: {
    "& hover img": {
      transform: "scale(1.5)",
    },
  },
  cardhead: {
    borderBottom: "2px solid black",
    borderBottomColor: "white",
    width: "100px",
  },
  "@media (max-width: 600px)": {
    headText: {
      fontSize: "20px",
    },
    paraText: {
      fontSize: "12px",
    },
    tabText: {
      fontSize: "12px",
    },
    cardBtn: {
      fontSize: "13px",
    },
  },
}));

export const Card4 = (props) => {
  const [loading, setloading] = useState(false);

  const ctx = useContext(AuthContext);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imagecrop, setImagecrop] = useState();
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [imageindex, setImageindex] = useState();
  const [defaultData, setDefaultdata] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const [style, setStyle] = React.useState("full");

  const cardData = [
    {
      title: "Nursery",
      titlecolor: "",
      titlefont: "",
      para: "Quality education in the early years of preschool sets a firm  foundation in the overall development of a child.",
      paracolor: "white",
      parafont: "",
      subtitle1: "Age group",
      subtitle1color: "grey",
      subtitle1font: "",
      subtitle2: "Duration",
      subtitle2color: "grey",
      subtitle2font: "",
      backgroundColor: "#007bff",
      cardBackground: "white",
      buttontextcolor: "white",
      buttontextfont: "",
      buttonbackround: "",
      image: child2,
      id: 1,
    },

    {
      title: "Nursery",
      titlecolor: "",
      titlefont: "",
      para: "Quality education in the early years of preschool sets a firm  foundation in the overall development of a child.",
      paracolor: "white",
      parafont: "",
      subtitle1: "Age group",
      subtitle1color: "grey",
      subtitle1font: "",
      subtitle2: "Duration",
      subtitle2color: "grey",
      subtitle2font: "",
      backgroundColor: "#007bff",
      cardBackground: "white",
      buttontextcolor: "white",
      buttontextfont: "",
      buttonbackround: "",
      image: child2,
      id: 2,
    },
    {
      title: "Nursery",
      titlecolor: "",
      titlefont: "",
      para: "Quality education in the early years of preschool sets a firm  foundation in the overall development of a child.",
      paracolor: "white",
      parafont: "",
      subtitle1: "Age group",
      subtitle1color: "grey",
      subtitle1font: "",
      subtitle2: "Duration",
      subtitle2color: "grey",
      subtitle2font: "",
      backgroundColor: "#007bff",
      cardBackground: "white",
      buttontextcolor: "white",
      buttontextfont: "",
      buttonbackround: "",
      image: child2,
      id: 3,
    },
  ];

  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined
      ? cardData
      : ctx.websiteData[props.id]
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setLocalData((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const classes = useStyles();

  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of localData) {
      TempArr.push({
        ...obj,
        titlecolor: localData[index].titlecolor,
        titlefont: localData[index].titlefont,
        paracolor: localData[index].paracolor,
        parafont: localData[index].parafont,
        subtitle1color: localData[index].subtitle1color,
        subtitle1font: localData[index].subtitle1font,
        subtitle2color: localData[index].subtitle2color,
        subtitle2font: localData[index].subtitle2font,
        backgroundColor: localData[index].backgroundColor,
        cardBackground: localData[index].cardBackground,
        buttontextcolor: localData[index].buttontextcolor,
        buttontextfont: localData[index].buttontextfont,
        buttonbackround: localData[index].buttonbackround,
      });
    }
    setLocalData(TempArr);
  }
  const onSelectFile = (e, i) => {
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImagecrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageindex(i);
    }
  };

  function cancelCrop() {
    setImagecrop();
    setImageindex();
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imagecrop,
        croppedAreaPixels
        // rotation
      );
      setloading(true);

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }

      //Usage example:
      var file = dataURLtoFile(croppedImage, "txt");

      const storage = getStorage();
      const uploadPath =
        ctx.userId +
        "/" +
        localData[imageindex].title +
        localData[imageindex].id +
        localData[imageindex].subtitle1 +
        ctx.selectedPage +
        props.id;

      // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[imageindex],
              image: url,
            };
            prevState[imageindex] = updatedData;
            return [...prevState];
          });
          setImagecrop();
          setImageindex();
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      title: "Nursery",
      titlecolor: "",
      titlefont: "",
      para: "Quality education in the early years of preschool sets a firm  foundation in the overall development of a child.",
      paracolor: "white",
      parafont: "",
      subtitle1: "Age group",
      subtitle1color: "grey",
      subtitle1font: "",
      subtitle2: "Duration",
      subtitle2color: "grey",
      subtitle2font: "",
      backgroundColor: "#007bff",
      cardBackground: "white",
      buttontextcolor: "white",
      buttontextfont: "",
      buttonbackround: "",
      image: child2,
      id: localData.length + 1,
    };
    setLocalData((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setLocalData((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  titlecolor: "",
                  titlefont: "",
                  paracolor: "white",
                  parafont: "",
                  subtitle1color: "grey",
                  subtitle1font: "",
                  subtitle2color: "grey",
                  subtitle2font: "",
                  backgroundColor: "#007bff",
                  cardBackground: "white",
                  buttontextcolor: "white",
                  buttontextfont: "",
                  buttonbackround: "",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>

          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>
          <Divider />
          <p variant="p" className=" my-2">
            Background Styles
          </p>

          <HexColorPicker
            color={localData[imageindex].backgroundColor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.backgroundColor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className=" my-2">
            Title Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].titlefont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].titlefont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={localData[imageindex]?.titlecolor}
            onChange={(color) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = color;
                return [...prevState];
              });
            }}
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.titlecolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Para Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].parafont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].parafont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small> Text Color</small>
          <HexColorPicker
            color={localData[imageindex].paracolor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.paracolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Card Styles
          </p>
          <HexColorPicker
            color={localData[imageindex].cardBackground}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].cardBackground = color;
                return [...prevState];
              })
            }
          />

          <Divider />
          <p variant="p" className="my-2">
            subtitle1 Styles
          </p>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].subtitle1font}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].subtitle1font = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <HexColorPicker
            color={localData[imageindex].subtitle1color}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].subtitle1color = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.subtitle1color}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].subtitle1color = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />
          <Divider />
          <p variant="p" className="my-2">
            subtitle2 Styles
          </p>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].subtitle2font}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].subtitle2font = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <HexColorPicker
            color={localData[imageindex].subtitle2color}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].subtitle2color = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.subtitle2color}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].subtitle2color = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Button Styles
          </p>
          <small>Button Background</small>
          <HexColorPicker
            color={localData[imageindex].buttonbackround}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].buttonbackround = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.buttonbackround}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].buttonbackround = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />
          <small>Text-color</small>
          <HexColorPicker
            color={localData[imageindex].buttontextcolor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].buttontextcolor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.buttontextcolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].buttontextcolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  let editable = (
    <div className="postion-relative">
      <div className="container">
        <div className="row mt-5">
          {localData.map((data, index) => (
            <div
              key={index}
              className={clsx(
                classes.cardshadow,
                "col-sm-12 col-lg-4 position-relative"
              )}
            >
              <Button
                onClick={(e) => {
                  setStyle("card");
                  setImageindex(index);
                  setDefaultdata(localData);
                  toggleDrawer(e, true);
                }}
                className=""
              >
                Change Styles
              </Button>
              <div
                onClick={() => removeCard(data.id)}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: 20,
                  cursor: "pointer",
                }}
              >
                <DeleteIcon
                  style={{
                    width: "2rem",
                    height: "2rem",
                    fill: "#dc3545",
                    padding: "5px",
                  }}
                />
              </div>
              <div className="card shadow">
                <div className={clsx(classes.zoom, " position-relative")}>
                  <input
                    type="file"
                    onChange={(e) => onSelectFile(e, index)}
                    className={classes.inputFile}
                    id={data.id}
                    name={data.title}
                  />
                  <label className={classes.inputLabel} htmlFor={data.id}>
                    <i className="fa fa-upload"></i>
                  </label>
                  <img
                    className={clsx("card-img-top")}
                    src={data.image}
                    alt="Card image"
                  />
                </div>
                <div
                  className="card-body "
                  style={{ background: data.backgroundColor }}
                >
                  <input
                    onChange={(e) => onChangeHandler(e, data, index)}
                    className={clsx(classes.editable, data?.titlefont)}
                    style={{ color: data?.titlecolor }}
                    id="title"
                    placeholder="title"
                    maxLength={25}
                    value={data.title}
                  />
                  <div className={clsx(classes.cardhead, "head mb-2")}></div>

                  <textarea
                    onChange={(e) => onChangeHandler(e, data, index)}
                    className={clsx(classes.editable, data?.parafont)}
                    style={{ color: data?.paracolor }}
                    id="para"
                    placeholder="paragraph"
                    maxLength={100}
                    value={data.para}
                  />
                  <div
                    className="row text-center my-2  rounded p-3"
                    style={{ background: data?.cardBackground }}
                  >
                    <div className={clsx(classes.bright, "col ")}>
                      <input
                        onChange={(e) => onChangeHandler(e, data, index)}
                        className={clsx(classes.subtitle, data?.subtitle1font)}
                        style={{ color: data?.subtitle1color }}
                        id="subtitle1"
                        placeholder="title"
                        maxLength={25}
                        value={data.subtitle1}
                      />
                    </div>
                    <div className="col">
                      <input
                        onChange={(e) => onChangeHandler(e, data, index)}
                        className={clsx(classes.subtitle, data?.subtitle2font)}
                        style={{ color: data?.subtitle2color }}
                        id="subtitle2"
                        placeholder="title"
                        maxLength={25}
                        value={data.subtitle2}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <a
                      className={clsx(classes.cardBtn, "btn px-3 mt-3 ")}
                      style={{
                        borderRadius: "30px",
                        color: data.buttontextcolor,
                        background: data.buttonbackround,
                      }}
                    >
                      Read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div
            className={clsx(
              classes.cardshadow,
              "col-sm-12 col-lg-4  d-flex align-items-center justify-content-center"
            )}
          >
            {localData.length < ctx.user?.Card_limit && (
              <div className={classes.addCard} onClick={addCard}>
                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>{" "}
                Add Card
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "#fff",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {imagecrop && ctx.isEditable && (
        <div className={classes.modalBox}>
          <Cropper
            image={imagecrop}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            aspect={6 / 4}
            onCropChange={setCrop}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

          <div
            className="text-center my-2"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              onClick={showCroppedImage}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-4")}
              style={{ fontSize: "30px" }}
            >
              Crop
            </Button>
            <Button
              onClick={cancelCrop}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-3")}
              style={{ fontSize: "30px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      {ctx.isEditable ? (
        editable
      ) : (
        <div className="container">
          <div className="row mt-5">
            {localData.map((data, index) => (
              <div
                key={index}
                className={clsx(classes.cardshadow, "col-sm-12 col-lg-4")}
              >
                <div className={clsx(classes.cardHover, "card shadow")}>
                  <div className={classes.zoom}>
                    <img
                      className={clsx(classes.zoomImg, "card-img-top")}
                      src={data.image}
                      alt="Card image"
                    />
                  </div>
                  <div
                    className={clsx("card-body")}
                    style={{ background: data.backgroundColor }}
                  >
                    <h4
                      className={clsx(
                        "card-title",
                        classes.headText,
                        data?.titlefont
                      )}
                      style={{ color: data?.titlecolor }}
                    >
                      {data.title}
                    </h4>
                    <div className="head mb-2"></div>
                    <p
                      className={clsx(classes.paraText, data?.parafont)}
                      style={{ color: data?.paracolor }}
                    >
                      {data.para}
                    </p>
                    <div
                      className={clsx(
                        classes.tabText,
                        "row text-center my-2  rounded p-md-3 p-1"
                      )}
                      style={{ background: data?.cardBackground }}
                    >
                      <div className={clsx(classes.bright, "col ")}>
                        <p
                          className={clsx(data.subtitle1font)}
                          style={{ color: data?.subtitle1color }}
                        >
                          {data.subtitle1}
                        </p>
                      </div>
                      <div className="col">
                        <p
                          className={clsx(data?.subtitle2font)}
                          style={{ color: data?.subtitle2color }}
                        >
                          {data.subtitle2}
                        </p>
                      </div>
                    </div>
                    <center>
                      <a
                        href="#"
                        className={clsx(classes.cardBtn, "btn px-3 mt-3 ")}
                        style={{
                          borderRadius: "30px",
                          color: data.buttontextcolor,
                          background: data.buttonbackround,
                        }}
                      >
                        Read more
                      </a>
                    </center>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
