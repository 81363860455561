// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAjQ50tRao8-cteWUX5zZihZQ6U1NVe3Z8",
  authDomain: "fickle-campuzone.firebaseapp.com",
  projectId: "fickle-campuzone",
  storageBucket: "fickle-campuzone.appspot.com",
  messagingSenderId: "837238902071",
  appId: "1:837238902071:web:8941e3718b0c73fc1cdeb2",
  measurementId: "G-ZQ7S1LSZXQ"
};

initializeApp(firebaseConfig); //default

const analytics = getAnalytics();
const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

export { auth, db, analytics, storage };
