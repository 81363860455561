export const font = [
  {
    label: "Times new Roman",
    classname: "Tinos",
  },
  {
    label: "Baskerville old face",
    classname: "Baskervville",
  },
  {
    label: "Cooper black",
    classname: "Cooper",
  },
  {
    label: "Algerian",
    classname: "Algerian",
  },
  {
    label: "Andalu",
    classname: "Andalu",
  },
  {
    label: "nunito",
    classname: "Nunito",
  },
  {
    label: "Poppins",
    classname: "Poppins",
  },
];

export const variants = ["h1", "h2", "h3", "h4", "h5", "h6"];
