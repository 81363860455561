import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Divider, SwipeableDrawer, Typography } from "@mui/material";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import Cat1 from "../../../Assests/images/cat1.jpg";
import Cat2 from "../../../Assests/images/cat2.jpg";
import Cat3 from "../../../Assests/images/cat3.jpg";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";
import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import getCroppedImg from "../../Crop/CropImage";
import { font } from "../../../utilitis/Fonts";
import { HexColorPicker } from "react-colorful";

const useStyles = makeStyles(() =>
  createStyles({
    // Modal
    modalBox: {
      position: "fixed",
      left: "0",
      top: "0",
      bottom: "0",
      right: "0",
      margin: "auto",
      zIndex: "9999",
    },
    root: {
      backgroundColor: "#fff",
      display: "flex",
      padding: "1rem",
      flexWrap: "wrap",
      position: "relative",
    },

    editable: {
      width: "100%",
      background: "transparent",
      outline: 0,
      border: "none",
      textAlign: "center",
    },
    addCard: {
      borderRadius: "1rem",
      heigh: "fit-content",
      background: "#fff",
      padding: "1rem 2rem",
      cursor: "pointer",
      top: "1rem",
      color: "#9e3a8ccc",
      right: "1rem",
      boxShadow: "2px 2px 3px 0 #ccc",
    },
    btnStyle: {
      background: "#9e3a8ccc",
      fontSize: "20px",
      color: "white",
      borderRadius: "20px",
      boxShadow: "0 3px 6px #00000036",
    },
    card: {
      position: "relative",
      width: "33.33%",
      padding: "1rem",
      "& img": {},
    },
    cardImage: {
      objectFit: "cover",
      width: "100%",
      // height: "300px",
      // overflow: "hidden",
    },

    overlay: {
      position: "absolute",
      display: "flex",
      flexWrap: "wrap",
      width: "85%",
      height: "fit-content",
      margin: "auto",
      left: 0,
      right: 0,
      bottom: "2rem",
      background: "#fff",
      "& h6": {
        color: "#000",
        textTransform: "uppercase",
        textAlign: "center",
        width: "100%",
        paddingBottom: "0.5rem",
      },
      "& p": {
        color: "#ccc",
        width: "100%",
        textAlign: "center",
      },
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    "@media (max-width: 900px)": {
      card: {
        width: "50%",
      },
      cardImage: {
        // height: "300px",
      },
    },
    "@media (max-width: 600px)": {
      card: {
        width: "100%",
      },
      cardImage: {
        // height: "250px",
      },
      overlay: {
        "& h6": {
          fontSize: "14px",
        },
        "& p": {
          color: "#ccc",
          width: "100%",
          textAlign: "center",
        },
      },
    },
  })
);

export const Card1 = (props) => {
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imagecrop, setImagecrop] = useState();
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [defaultData, setDefaultdata] = useState();

  const [imageindex, setImageindex] = useState();
  const [style, setStyle] = React.useState("full");

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cardData = [
    {
      img: Cat1,
      title: "Casual Dresses",
      titlecolor: "",
      titlefont: "",
      count: "1",
      countcolor: "",
      countfont: "",
      backgroundColor: "",
      id: "0",
    },
    {
      img: Cat2,
      title: "Evening Dresses",
      titlecolor: "",
      titlefont: "",
      count: "1",
      countcolor: "",
      countfont: "",
      backgroundColor: "",
      id: "1",
    },
    {
      img: Cat3,
      title: "Summer Dresses",
      titlecolor: "",
      titlefont: "",
      count: "1",
      countcolor: "",
      countfont: "",
      backgroundColor: "",
      id: "2",
    },
  ];
  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined
      ? cardData
      : ctx.websiteData[props.id]
  );
  useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setLocalData((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of localData) {
      TempArr.push({
        ...obj,
        titlecolor: localData[index].titlecolor,
        titlefont: localData[index].titlefont,
        countcolor: localData[index].countcolor,
        countfont: localData[index].countfont,
        backgroundColor: localData[index].backgroundColor,
      });
    }
    setLocalData(TempArr);
  }

  const onSelectFile = (e, i) => {
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImagecrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageindex(i);
    }
  };

  function cancelCrop() {
    setImagecrop();
    setImageindex();
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imagecrop,
        croppedAreaPixels
        // rotation
      );
      setloading(true);
      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }

      //Usage example:
      var file = dataURLtoFile(croppedImage, "txt");
      let d = new Date().getTime();

      const storage = getStorage();
      const uploadPath =
        ctx.userId +
        "/" +
        localData[imageindex].title +
        localData[imageindex].id +
        ctx.selectedPage +
        props.id; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[imageindex],
              img: url,
            };
            prevState[imageindex] = updatedData;
            return [...prevState];
          });
          setImagecrop();
          setImageindex();
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      img: Cat1,
      title: "Casual Dresses",
      count: "1",
      id: localData.length,
    };
    setLocalData((prevState) => {
      return [...prevState, updatedData];
    });
  };

  const removeCard = (value) => {
    setLocalData((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  titlecolor: "",
                  titlefont: "",
                  countcolor: "",
                  countfont: "",
                  backgroundColor: "",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>
          <Divider />
          <p variant="p" className=" my-2">
            Subtitle1 Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].titlefont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].titlefont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={localData[imageindex]?.titlecolor}
            onChange={(color) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = color;
                return [...prevState];
              });
            }}
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.titlecolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Subtitle2 Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].countfont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].countfont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small> Text Color</small>
          <HexColorPicker
            color={localData[imageindex].countcolor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].countcolor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.countcolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].countcolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            background Styles
          </p>

          <HexColorPicker
            color={localData[imageindex].backgroundColor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.backgroundColor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      ) : (
        <></>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            } else {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  let editable = (
    <div className="position-relative">
      <div className={classes.root}>
        {localData?.map((details, index) => (
          <div className={classes.card} key={index}>
            <Button
              onClick={(e) => {
                setStyle("card");
                setImageindex(index);
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
              className=""
            >
              Change Styles
            </Button>
            <div
              onClick={() => removeCard(details.id)}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 20,
                cursor: "pointer",
              }}
            >
              <DeleteIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  fill: "#dc3545",
                  padding: "5px",
                }}
              />
            </div>
            <input
              type="file"
              onChange={(e) => onSelectFile(e, index)}
              className={classes.inputFile}
              id={details.id}
              name={details.title}
            />
            <label className={classes.inputLabel} htmlFor={details.id}>
              <i className="fa fa-upload"></i>
            </label>

            <img
              className={classes.cardImage}
              src={details.img}
              alt={details.title}
            />
            <div
              className={classes.overlay}
              style={{ background: details?.backgroundColor }}
            >
              <input
                onChange={(e) => onChangeHandler(e, details, index)}
                style={{
                  color: details?.titlecolor,
                }}
                className={clsx(classes.editable, details?.titlefont)}
                id="title"
                placeholder="title"
                value={details.title}
                maxLength={50}
              />
              <input
                onChange={(e) => onChangeHandler(e, details, index)}
                className={clsx(classes.editable, details.countfont)}
                id="count"
                style={{
                  color: details.countcolor,
                }}
                placeholder="count"
                value={details.count}
                maxLength={100}
              />
            </div>
          </div>
        ))}
        <div
          className={clsx(
            classes.card,
            "d-flex align-items-center justify-content-center"
          )}
        >
          {localData.length < ctx.user?.Card_limit && (
            <div className={classes.addCard} onClick={addCard}>
              <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add
              Card
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success(" Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className={clsx(classes.btnStyle, "btn px-5")}
              onClick={onSaveHandler}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {imagecrop && ctx.isEditable && (
        <div className={classes.modalBox}>
          <Cropper
            image={imagecrop}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            // cropSize={{ width: 4000, height: 2000 }}
            onCropChange={setCrop}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

          <div
            className="text-center my-2"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              onClick={showCroppedImage}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-4")}
              style={{ fontSize: "30px" }}
            >
              Crop
            </Button>
            <Button
              onClick={cancelCrop}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-3")}
              style={{ fontSize: "30px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}

      {ctx.isEditable ? (
        editable
      ) : (
        <div className={classes.root}>
          {localData?.map((item, index) => (
            <div className={classes.card} key={index}>
              <img
                src={item.img}
                alt={item.title}
                className={classes.cardImage}
              />
              <div
                className={classes.overlay}
                style={{ background: item?.backgroundColor }}
              >
                <Typography
                  style={{
                    color: item?.titlecolor,
                  }}
                  className={clsx("font-weight-bold", item?.titlecolor)}
                  variant="h6"
                >
                  {item.title}
                </Typography>
                <Typography
                  style={{
                    overflowWrap: "anywhere",
                    color: item?.countcolor,
                  }}
                  className={clsx(item?.countfont)}
                  variant="body1"
                >
                  {item.count}&ensp;items
                </Typography>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
