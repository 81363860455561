import React, { useContext, useState, useCallback } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Divider, SwipeableDrawer, Typography } from "@mui/material";
import BannerImage1 from "../../../Assests/images/banner1.jpg";
import BannerImage2 from "../../../Assests/images/banner2.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import { toast } from "react-toastify";
import clsx from "clsx";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import getCroppedImg from "../../Crop/CropImage";
import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import { font, variants } from "../../../utilitis/Fonts";
import { HexColorPicker } from "react-colorful";
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#efefef",
      position: "relative",
    },
    addCard: {
      borderRadius: "1rem",
      position: "absolute",
      background: "#fff",
      padding: "1rem 2rem",
      cursor: "pointer",
      top: "1rem",
      color: "#9e3a8ccc",
      right: "1rem",
      boxShadow: "2px 2px 3px 0 #ccc",
    },
    container: {
      width: "100%",
      position: "relative",
      padding: "1rem",
    },
    modalBox: {
      position: "fixed",

      left: "0",
      top: "0",
      bottom: "0",
      right: "0",
      margin: "auto",
      zIndex: "9999",
    },
    btnStyle: {
      background: "#9e3a8ccc",
      fontSize: "20px",
      color: "white",
      borderRadius: "20px",
      boxShadow: "0 3px 6px #00000036",
    },
    carouselContainer: {
      maxHeight: "600px",
      overflow: "hidden",
      padding: "0",
    },
    bannerImg: {
      position: "relative",
      width: "100%",
      height: "600px",
      backgroundSize: "cover",
      "& img": {
        width: "100%",
        height: "auto",
        objectFit: "contain",
      },
    },
    introHeader: {
      position: "absolute",
      color: "red",
      background: "transparent",
      outline: 0,
      border: "none",
      // fontSize: "1.5rem !important",
      paddingBottom: "1.5rem !important",
      textTransform: "capitalize",

      width: "60%",
      height: "fit-content",
      margin: "auto !important",
      left: 0,
      right: 0,
      top: "30%",
      fontSize: "40px",
      textAlign: "center",
    },
    introText: {
      position: "absolute",
      color: "#000",
      background: "transparent",
      outline: 0,
      border: "none",
      fontSize: "1rem !important",
      textTransform: "lowercase",
      letterSpacing: "0.25rem",
      textAlign: "justify",
      width: "60%",
      margin: "auto !important",
      left: 0,
      right: 0,
      top: "42%",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    "@media (max-width: 600px)": {
      introText: {
        fontSize: "12px !important",
      },
      introHeader: {
        fontSize: "20px !important",
      },
      carouselContainer: {
        maxHeight: "250px",
      },
      bannerImg: {
        height: "250px",
      },
    },
  })
);

export const Hero1 = (props) => {
  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imagecrop, setImagecrop] = useState();
  const location = useLocation();

  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [defaultData, setDefaultdata] = useState();

  const [imageindex, setImageindex] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const [style, setStyle] = React.useState("full");

  const data = [
    {
      img: BannerImage1,
      header: "Intro header 1",
      titlecolor: "",
      titlefont: "",
      variant: "h2",
      para: "loerum ipsum is a dummy language for website content writing",
      paracolor: "",
      parafont: "",
      backgroundColor: "",
      id: "0",
    },
    {
      img: BannerImage2,
      header: "Intro header 2",
      titlecolor: "",
      titlefont: "",
      variant: "h2",
      para: "loerum ipsum is a dummy language for website content writing",
      paracolor: "",
      parafont: "",
      backgroundColor: "",
      id: "1",
    },
  ];

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : data
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setLocalData((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const options = {
    loop: true,
    margin: 0,
    dots: false,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 3000,
    autoplayHoverPause: true,
    mouseDrag: location.pathname.includes("layout") ? false : true,
    items: 1,
    touchDrag: false,
  };

  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of localData) {
      TempArr.push({
        ...obj,
        titlecolor: localData[index].titlecolor,
        titlefont: localData[index].titlefont,
        paracolor: localData[index].paracolor,
        parafont: localData[index].parafont,
        backgroundColor: localData[index].backgroundColor,
      });
    }
    setLocalData(TempArr);
  }
  const onSelectFile = (e, i) => {
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImagecrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageindex(i);
    }
  };

  function cancelCrop() {
    setImagecrop();
    setImageindex();
  }
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imagecrop,
        croppedAreaPixels
        // rotation
      );
      setloading(true);

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }

      //Usage example:
      var file = dataURLtoFile(croppedImage, "txt");
      // let d = new Date().getTime();
      let d = new Date().getTime();
      const storage = getStorage();
      const uploadPath =
        ctx.userId +
        "/" +
        localData[imageindex].title +
        localData[imageindex].id +
        ctx.selectedPage +
        props.id;
      // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[imageindex],
              img: url,
            };
            prevState[imageindex] = updatedData;
            return [...prevState];
          });

          setImagecrop();
          setImageindex();
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // setError(null);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  titlecolor: "",
                  titlefont: "",
                  ratecolor: "",
                  ratefont: "",
                  variant: "h2",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>

          <Divider />
          <p variant="p" className=" my-2">
            Subtitle1 Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].titlefont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].titlefont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={localData[imageindex]?.titlecolor}
            onChange={(color) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = color;
                return [...prevState];
              });
            }}
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.titlecolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <small> Font-Variant</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].variant}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].variant = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {variants?.map((variant) => (
              <option value={variant}>{variant}</option>
            ))}
          </select>

          <Divider />
          <p variant="p" className="my-2">
            Subtitle2 Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].parafont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].parafont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small> Text Color</small>
          <HexColorPicker
            color={localData[imageindex].paracolor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.paracolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      ) : (
        <></>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            } else {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      img: BannerImage1,
      header: "",
      para: "",
      id: localData.length,
    };
    setLocalData((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setLocalData((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  const classes = useStyles();
  let editable = (
    <>
      {localData?.map((details, index) => (
        <div key={index}>
          <Button
            onClick={(e) => {
              setStyle("card");
              setImageindex(index);
              setDefaultdata(localData);
              toggleDrawer(e, true);
            }}
            className=""
          >
            Change Styles
          </Button>
          <div
            className={classes.bannerImg}
            style={{
              backgroundImage: `url(${details.img})`,
              marginBottom: "1rem",
              width: "auto",
            }}
          >
            <div
              onClick={() => removeCard(details.id)}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 20,
                cursor: "pointer",
              }}
            >
              <DeleteIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  fill: "#dc3545",
                  padding: "5px",
                }}
              />
            </div>
            <input
              type="file"
              onChange={(e) => onSelectFile(e, index)}
              className={classes.inputFile}
              id={details.id}
              name={details.header}
            />
            <label className={classes.inputLabel} htmlFor={details.id}>
              <i className="fa fa-upload"></i>
            </label>

            <input
              onChange={(e) => onChangeHandler(e, details, index)}
              style={{
                color: details?.titlecolor,
                width: "75%",
              }}
              className={clsx(classes.introHeader, details?.titlefont)}
              id="header"
              placeholder="Header"
              maxLength={100}
              value={details.header}
            />
            <textarea
              onChange={(e) => onChangeHandler(e, details, index)}
              style={{
                color: details?.paracolor,
                width: "75%",
              }}
              className={clsx(classes.introText, details?.parafont)}
              id="para"
              placeholder="text"
              value={details.para}
            />
          </div>
        </div>
      ))}
      {localData.length < ctx.user?.Card_limit && (
        <div className={classes.addCard} onClick={addCard}>
          <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add Card
        </div>
      )}
    </>
  );

  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}

      {imagecrop && ctx.isEditable && (
        <div className={classes.modalBox}>
          <Cropper
            image={imagecrop}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            onCropChange={setCrop}
            aspect={7 / 5}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

          <div
            className="text-center my-2"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              onClick={showCroppedImage}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-4")}
              style={{ fontSize: "30px" }}
            >
              Crop
            </Button>
            <Button
              onClick={cancelCrop}
              variant="contained"
              className={clsx(classes.btnStyle, "mx-2 px-3")}
              style={{ fontSize: "30px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div className={classes.root}>
        <div
          className={clsx(
            classes.container,
            ctx.isEditable ? "" : classes.carouselContainer
          )}
        >
          {ctx.isEditable ? (
            editable
          ) : (
            <OwlCarousel className="owl-theme" {...options}>
              {localData.map((item, index) => (
                <div
                  key={index}
                  className={classes.bannerImg}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  {/* <img src={item.img} alt="bannerImage" /> */}
                  <Typography
                    variant={item?.variant ? item?.variant : "h2"}
                    style={{
                      color: item?.titlecolor,
                    }}
                    className={clsx(classes.introHeader, item?.titlecolor)}
                  >
                    {item.header}
                  </Typography>
                  <Typography
                    style={{
                      color: item?.paracolor,
                    }}
                    className={clsx(classes.introText, item?.parafont)}
                  >
                    {item.para}
                  </Typography>
                </div>
              ))}
            </OwlCarousel>
          )}
        </div>
      </div>
    </>
  );
};
