import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";


const useStyles = makeStyles(() =>
  createStyles({

    divider:{
        borderTop: "3px dotted #bbb",
        margin:"40px"
    }

})
);


function Divider1 (){

    const classes = useStyles();
    return(
        <>
        <div>
            <hr className={clsx(classes.divider)}></hr>
        </div>

        
        </>
    );
}

export default Divider1;