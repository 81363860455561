import axios from "./Utilities";

const URL = "https://czerpbackend.anichadigitalinfra.com/api/ficklecontest";

export async function AdminServices(method, url, data) {
  try {
    const response = await axios({
      method,
      url: `${URL}/${url}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
