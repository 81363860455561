import React, { useContext, useEffect, useState } from "react";
import EditableList from "../../Components/Edit/EditableList";
import AuthContext from "../../Context/Context";
import { createStyles, makeStyles } from "@mui/styles";
import WebFont from "webfontloader";
import clsx from "clsx";
import { ReactComponent as EditIcon } from "../../Assests/pencil.svg";
import { Layout } from "../../utilitis/Layout";
import { Chip } from "@material-ui/core";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Loader from "../../loader/Loader";
const useStyles = makeStyles(() =>
  createStyles({
    editBox: {
      color: "#6a6363",
      margin: "10px 0px",
      position: "relative",
      height: "45px",

      borderRadius: "10px",
      cursor: "pointer",
      boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.025)",
      "& .editIcon": {
        display: "inline",
        margin: "0px 10px",
      },
      "&:focus": {
        outline: "0",
        boxShadow: "none",
      },
    },
  })
);
const Edit = () => {
  const classes = useStyles();
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Raleway"],
      },
    });
  }, []);
  const ctx = useContext(AuthContext);
  const [active, setActive] = useState(0);
  const [mountedData, setMountedData] = useState([]);
  const [mountedComponent, setMountedComponent] = useState([]);
  const [selectedPage, setSelectedPage] = useState(
    ctx.pageFlow?.length > 0 ? ctx.pageFlow[0] : ""
  );
  useEffect(() => {
    ctx.updateIsEditable(true);
  }, []);

  useEffect(() => {
    setSelectedPage(ctx.pageFlow?.length > 0 ? ctx.pageFlow[0] : "");
  }, [ctx.pageFlow]);

  useEffect(() => {
    let pageData = [];
    if (selectedPage !== "") {
      if (ctx.layoutData[selectedPage] !== undefined) {
        pageData = ctx.layoutData[selectedPage];
      }
      let newArr = [];
      for (var i = 0; i < pageData.length; i++) {
        let tempArr = Layout.filter((x) => pageData[i].id === x.id);
        newArr = newArr.concat(tempArr);
      }

      let finalArray = newArr?.map((item, i) =>
        Object.assign({}, item, pageData[i])
      );
      setMountedData(finalArray);
      setMountedComponent([]);
      setActive(0);
      ctx.updateSelectedPage(selectedPage);
    }
  }, [selectedPage, ctx.layoutData]);

  useEffect(() => {
    if (
      mountedData &&
      mountedComponent?.length === 0 &&
      mountedData?.length > 0
    ) {
      setMountedComponent((prevState) => {
        return [...prevState, mountedData[0]];
      });
    }
  }, [mountedData]);
  const onMount = (id) => {
    setMountedComponent((prevState) => {
      prevState = [];
      return [...prevState, mountedData[id]];
    });
  };
  const CreateComponent = ({ component, id }) => {
    const Component = component;
    return <Component id={id} />;
  };
  const rename = (name, i) => {
    let firstName = name.substring(0, name.length - 2);
    let lastName = name.charAt(name.length - 1);
    let newName = firstName + "_" + lastName;
    return newName;
  };

  const Nosections = () => {
    return (
      <div
        className="p-2 mx-3 col-md-10 mt-3  mx-auto"
        style={{
          boxShadow: "0px 3px 6px #00000036",
          borderRadius: "5rem",
          fontFamily: "raleway",
          overflow: "hidden",
        }}
      >
        <h5 className="text-center font-weight-bold">Add Sections to edit</h5>
        <div className="d-flex justify-content-center">
          <lottie-player
            src="https://assets6.lottiefiles.com/packages/lf20_pgeevipp.json"
            background="transparent"
            speed="1"
            style={{
              width: "500px",
              height: "300px",
              transform: "scale(1.9)",
            }}
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    );
  };
  return (
    <>
      {ctx.pageFlow?.length === 0 ? (
        <Nosections />
      ) : (
        <>
          <div className=" d-flex align-item-center mt-1 ">
            <div className="col-md-2 p-0" style={{ width: "160px" }}>
              <button
                className={clsx(classes.editBox, "btn  m-0")}
                style={{
                  borderRadius: "5px",
                  height: "48px",
                  background: "#efefef",
                  color: "#9e3a8ccc",
                  fill: "#9e3a8ccc",
                }}
              >
                <EditIcon className="editIcon" style={{ width: "7%" }} />
                <span> Select Page </span>
              </button>
            </div>

            <Box
              sx={{
                width: "80%",
                // maxWidth: { xs: 320, sm: 480 },
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                value={selectedPage}
                // onChange={handleChange}
                // textColor="secondary"
                style={{
                  borderWidth: "0",
                }}
                TabIndicatorProps={{ style: { display: "none" } }}
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {ctx.pageFlow?.map((page, index) => (
                  <Tab
                    className="mx-2"
                    style={{
                      borderRadius: "5px",
                      borderWidth: "0",
                      background: selectedPage === page ? "#9e3a8ccc" : "white",
                      color: selectedPage === page ? "white" : "",
                      fill: selectedPage === page ? "white" : "",
                    }}
                    value={page}
                    label={page}
                    onClick={() => setSelectedPage(page)}
                  />
                ))}
              </Tabs>
            </Box>
          </div>

          {mountedData?.length === 0 ? <Nosections /> : <></>}
          <div className="row m-0">
            <div className="all-section-list col-2  m-0 p-0">
              <div className="Editlist">
                {mountedData &&
                  mountedData.map((item, index) => (
                    <button
                      className={clsx(classes.editBox, "btn ")}
                      style={{
                        borderRadius: "0",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        background: active === index ? "#9e3a8ccc" : "white",
                        color: active === index ? "white" : "#777",
                        fill: active === index ? "white" : "#777",
                      }}
                      key={index}
                      onClick={() => {
                        onMount(index);
                        setActive(index);
                      }}
                    >
                      <EditIcon className="editIcon" style={{ width: "7%" }} />
                      <h6 className="  d-inline mx-2">
                        {rename(item.uniqId, index)}
                      </h6>
                    </button>
                  ))}
              </div>
            </div>

            {/* <EditableList /> */}
            <div
              className="col-10 p-2 special-scroll"
              style={{
                height: "91vh",
                overflowX: "hidden",
                overflowY: "auto",
                scrollBehavior: "smooth",
              }}
            >
              {mountedComponent.map((single) => (
                <div key={single.uniqId} style={{ zoom: "0.7" }}>
                  <CreateComponent component={single.c} id={single.uniqId} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Edit;
