import React, { useContext, useState } from "react";
import styles from "./Slider2.module.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import clsx from "clsx";
import { toast } from "react-toastify";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { font } from "../../../utilitis/Fonts";
import { HexColorPicker } from "react-colorful";

const Slider2 = (props) => {
  const [loading, setloading] = useState(false);

  const ctx = useContext(AuthContext);
  const location = useLocation();
  const [imageindex, setImageindex] = useState();
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const cardData = {
    header: "What Our Families are Saying",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    paracolor: "",
    parafont: "",
    data: [
      {
        para: "It is a long established fact that a reader will be distracted by the readable",

        id: 0,
      },
      {
        para: "It is a long established fact that a reader will be distracted by the readable",

        id: 1,
      },
      {
        para: "It is a long established fact that a reader will be distracted by the readable",

        id: 2,
      },
    ],
  };
  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : cardData
  );
  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [card, setCard] = useState(localData.data);
  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };
  const options = {
    loop: false,
    margin: 50,
    dots: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 2000,
    autoplayHoverPause: true,
    mouseDrag: location.pathname.includes("layout") ? false : true,
    items: 1,
  };

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setCard((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>

          <p variant="p" className=" my-2">
            Content Styles
          </p>

          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={card[imageindex].parafont}
            onChange={(e) =>
              setCard((prevState) => {
                prevState[imageindex].parafont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small> Text Color</small>

          <Divider />
        </div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
                paracolor: "",
                parafont: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Background Styles
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>

          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.parafont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                parafont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData?.paracolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                paracolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.paracolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                paracolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      para: "It is a long established fact that a reader will be distracted by the readable",
      id: card.length,
    };
    setCard((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setCard((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };
  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      data: card,
    };

    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <>
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      <section className="my-4 py-2">
        {ctx.isEditable && (
          <div>
            <React.Fragment>
              <Button
                onClick={(e) => {
                  setStyle("full");
                  setDefaultdata(localData);
                  toggleDrawer(e, true);
                }}
              >
                Change Styles
              </Button>
              <SwipeableDrawer
                anchor="right"
                open={state.right}
                // onClose={(e) => {
                //   toggleDrawer(e, false);
                // }}
                onOpen={(e) => {
                  toggleDrawer(e, true);
                }}
              >
                {state.right && list()}
              </SwipeableDrawer>
            </React.Fragment>
          </div>
        )}
        <div
          className={clsx(
            ctx.isEditable ? "h-100 container m-auto " : "container m-auto",
            styles.test
          )}
          style={{ background: localData?.backgroundColor }}
        >
          {ctx.isEditable ? (
            <div className="text-center ">
              <input
                className={clsx(
                  styles.test_text_h2,
                  styles.inputHeader,
                  localData?.fontFamily
                )}
                style={{ color: localData?.headerColor }}
                placeholder="Header"
                id="header"
                maxLength={50}
                onChange={onChange}
                value={localData.header}
              />
              <div className="d-flex justify-content-center mb-5">
                {card.length < ctx.user?.Card_limit && (
                  <div className={styles.addCard} onClick={addCard}>
                    <i
                      className="fa fa-plus-circle mx-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Add Card
                  </div>
                )}
              </div>
              {card.map((details, index) => (
                <div
                  className="row position-relative justify-content-center align-items-center mb-2"
                  key={index}
                >
                  {" "}
                  <div
                    onClick={() => removeCard(details.id)}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: 20,
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon
                      style={{
                        width: "2rem",
                        height: "2rem",
                        fill: "#dc3545",
                        padding: "5px",
                      }}
                    />
                  </div>
                  <h5 className="pr-4">{"Slider " + `${index + 1}`}</h5>
                  <textarea
                    key={index}
                    onChange={(e) => onChangeHandler(e, details, index)}
                    style={{ color: localData?.paracolor, width: "75%" }}
                    className={clsx(localData?.parafont, styles.inputPara)}
                    id="para"
                    name="para"
                    maxLength={300}
                    defaultValue={details.para}
                  ></textarea>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center ">
              <h2
                className={clsx(styles.test_text_h2, localData?.fontFamily)}
                style={{ color: localData?.headerColor }}
              >
                {localData.header}
              </h2>
              <OwlCarousel className="owl-theme" {...options}>
                {card.map((details, index) => (
                  <p
                    style={{ color: localData?.paracolor }}
                    className={localData?.parafont}
                    key={index}
                  >
                    {details.para}
                  </p>
                ))}
              </OwlCarousel>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Slider2;
