import React, { useContext, useState, useCallback } from "react";
import AuthContext from "../../../Context/Context";
import styles from "./Gallery.module.css";
import Loader from "../../../loader/Loader";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Gallery from "../../../Assests/diviImages/gallery.jpg";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { toast } from "react-toastify";

import Cropper from "react-easy-crop";
import Button from "@material-ui/core/Button";
import getCroppedImg from "../../Crop/CropImage";
import { Box, Divider, SwipeableDrawer, Typography } from "@mui/material";
import { font } from "../../../utilitis/Fonts";
import clsx from "clsx";
import { HexColorPicker } from "react-colorful";

const Gallery3 = (props) => {
  const ctx = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imagecrop, setImagecrop] = useState();
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [imageindex, setImageindex] = useState();

  const [defaultData, setDefaultdata] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [style, setStyle] = React.useState("full");

  const cardData = {
    header: "Gallery",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    data: [
      {
        name: "Image Name",
        nameColor: "",
        nameFont: "",
        img: Gallery,
        id: 0,
      },
      {
        name: "Image Name",
        nameColor: "",
        nameFont: "",
        img: Gallery,
        id: 1,
      },
      {
        name: "Image Name",
        nameColor: "",
        nameFont: "",
        img: Gallery,
        id: 2,
      },
    ],
  };

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : cardData
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [card, setCard] = useState(localData.data);
  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.name] = e.target.value;
    } else {
      tempEventInputs["path"] = e.value;
    }
    setCard((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of card) {
      TempArr.push({
        ...obj,
        nameColor: card[index].nameColor,
        nameFont: card[index].nameFont,
      });
    }
    setCard(TempArr);
  }
  const onSelectFile = (e, i) => {
    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImagecrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageindex(i);
    }
  };

  function cancelCrop() {
    setImagecrop();
    setImageindex();
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imagecrop,
        croppedAreaPixels
        // rotation
      );
      setloading(true);

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }

      //Usage example:
      var file = dataURLtoFile(croppedImage, "txt");
      const storage = getStorage();
      const uploadPath =
        ctx.userId + "/" + card[imageindex].id + ctx.selectedPage + props.id; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, file).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setCard((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[imageindex],
              img: url,
            };
            prevState[imageindex] = updatedData;
            return [...prevState];
          });
          setImagecrop();
          setImageindex();
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    } catch (e) {
      // console.error(e);
    }
  }, [croppedAreaPixels]);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setCard((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  nameColor: "",
                  nameFont: "",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>
          <Divider />
          <p variant="p" className=" my-2">
            Title Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={card[imageindex].nameFont}
            onChange={(e) =>
              setCard((prevState) => {
                prevState[imageindex].nameFont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={card[imageindex]?.nameColor}
            onChange={(color) => {
              setCard((prevState) => {
                prevState[imageindex].nameColor = color;
                return [...prevState];
              });
            }}
          />

          <input
            className="form-control my-2"
            value={card[imageindex]?.nameColor}
            onChange={(e) => {
              setCard((prevState) => {
                prevState[imageindex].nameColor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />
          <Divider />
        </div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className="my-2">
            Background Styles
          </p>

          <HexColorPicker
            color={localData.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />
          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>

          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              // setCard(defaultData);
              toggleDrawer(e, false);
            } else {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      name: "Image Name",
      img: Gallery,
      id: card.length,
    };
    setCard((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setCard((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  let editable = (
    <>
      <section
        id="#Gallery"
        className="position-relative"
        style={{ background: localData?.backgroundColor }}
      >
        {ctx.isEditable && (
          <div>
            <React.Fragment>
              <Button
                onClick={(e) => {
                  setStyle("full");
                  setDefaultdata(localData);
                  toggleDrawer(e, true);
                }}
              >
                Change Styles
              </Button>
              <SwipeableDrawer
                anchor="right"
                open={state.right}
                // onClose={(e) => {
                //   toggleDrawer(e, false);
                // }}
                onOpen={(e) => {
                  toggleDrawer(e, true);
                }}
              >
                {state.right && list()}
              </SwipeableDrawer>
            </React.Fragment>
          </div>
        )}
        <div className={styles.galleryheading}>
          <input
            placeholder="Header"
            id="header"
            onChange={onChange}
            style={{ color: localData?.headerColor }}
            className={clsx(localData?.fontFamily, styles.inputHeader)}
            value={localData.header}
          />
        </div>
        <div className={styles.gallery}>
          {card.map((details, index) => (
            <div key={index}>
              <Button
                onClick={(e) => {
                  setStyle("card");
                  setImageindex(index);
                  setDefaultdata(card);
                  toggleDrawer(e, true);
                }}
                className=""
              >
                Change Styles
              </Button>
              <div className={`position-relative w-100 ${styles.uploadImage}`}>
                <div
                  onClick={() => removeCard(details.id)}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: 20,
                    cursor: "pointer",
                  }}
                >
                  <DeleteIcon
                    style={{
                      width: "2rem",
                      height: "2rem",
                      fill: "#dc3545",
                      padding: "5px",
                    }}
                  />
                </div>
                <input
                  type="file"
                  onChange={(e) => onSelectFile(e, index)}
                  className={styles.fileType}
                  id={details.name + index}
                  name={index}
                />
                <label
                  className={styles.fileLabel}
                  htmlFor={details.name + index}
                >
                  <i className="fa fa-upload fa-3x"></i>
                </label>
                <img
                  src={details.img}
                  className={styles.image}
                  alt="preview"
                  style={{ zIndex: "2" }}
                />
              </div>
              <input
                type="text"
                onChange={(e) => onChangeHandler(e, details, index)}
                style={{
                  color: details?.nameColor,
                  fontFamily: details?.nameFont === "" ? "Oswald" : "",
                }}
                className={clsx(details?.nameFont, styles.inputPara)}
                placeholder="title"
                id="name"
                name="name"
                maxLength={30}
                value={details.name}
              />
            </div>
          ))}

          {card.length < ctx.user?.Card_limit && (
            <div className={styles.addCard} onClick={addCard}>
              <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add
              Card
            </div>
          )}
        </div>
      </section>
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    let data = {
      ...localData,
      data: card,
    };

    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };
  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "#fff",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {imagecrop && ctx.isEditable && (
        <div
          style={{
            position: "fixed",
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            margin: "auto",
            zIndex: "9999",
          }}
        >
          <Cropper
            image={imagecrop}
            crop={crop}
            // rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            // onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />

          <div
            className="text-center my-2"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              onClick={showCroppedImage}
              variant="contained"
              className={"mx-2 px-4"}
              style={{
                fontSize: "30px",
                background: "#9e3a8ccc",
                color: "white",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Crop
            </Button>
            <Button
              onClick={cancelCrop}
              variant="contained"
              className={"mx-2 px-3"}
              style={{
                fontSize: "30px",
                background: "#9e3a8ccc",
                color: "white",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      {ctx.isEditable ? (
        editable
      ) : (
        <section
          id="#Gallery"
          style={{ background: localData?.backgroundColor }}
        >
          <div className={styles.galleryheading}>
            <h2
              style={{ color: localData?.headerColor }}
              className={localData?.fontFamily}
            >
              {localData.header}
            </h2>
          </div>
          <div className={styles.gallery}>
            {card.map((details, index) => (
              <a href="#" className="gal" key={index}>
                <div className={styles.gal_wrapper}>
                  <div className={styles.hidden_cover}></div>
                  <img
                    className={styles.image}
                    src={details.img}
                    alt={details.name}
                  />
                  <h4
                    style={{
                      color: details?.nameColor,
                      fontFamily: details?.nameFont === "" ? "Oswald" : "",
                    }}
                    className={details?.nameFont}
                  >
                    {details.name}
                  </h4>
                </div>
              </a>
            ))}
          </div>
        </section>
      )}
    </>
  );
};

export default Gallery3;
