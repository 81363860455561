import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { auth } from "../services/firebase";
import { Layout } from "../utilitis/Layout";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { updatePassword } from "firebase/auth";
import { env } from "../env";
const AuthContext = React.createContext({
  user: "",
  userId: "",
  selectedPage: "",
  pageFlow: [],
  layoutFlow: [],
  layoutData: {},
  isLoggedIn: false,
  isEditable: Boolean,
  websiteData: {},
  userWebsiteData: () => {},
  userLayoutData: () => {},
  getWebsiteData: () => {},
  getUserData: () => {},
  getLayoutData: () => {},
  setUserId: () => {},
  updateUser: () => {},
  updateSelectedPage: () => {},
  pageState: "",
  updateData: () => {},
  deleteData: () => {},
  deletePage: () => {},
  setLayoutData: () => {},
  updateIsEditable: () => {},
  updateLayout: () => {},
  addLayout: () => {},
  siteSettings: {},
  setSiteSettings: () => {},
  formHandler: () => {},
  getFormData: () => {},
  messageData: [],
  appformHandler: () => {},
  getAppFormData: () => {},
  appData: [],
  emailcred: {},
  mailSend: () => {},
  setLoading: () => {},
  loading: Boolean,
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  let navigate = useNavigate();

  const [websiteData, setWebsiteData] = useState({});
  const [domains, setDomains] = useState();

  const [user, setUser] = useState("");
  const [userId, setId] = useState(localStorage.getItem("EditableCampuz"));
  const [layoutData, setLayoutData] = useState(null);
  const [selectedPage, setSelectedPage] = useState("");
  const [pageFlow, setPageFlow] = useState(null);
  const [layoutFlow, setLayoutFlow] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [domainname, setDomainname] = useState();
  const [emailcred, setEmailcred] = useState({
    from_email: "",
    email_password: "",
    to_email: "",
  });
  const [siteSettings, setSiteSettings] = React.useState({
    siteBackground: "",
    Title: "Fickle",
    Logo: "/jj",
    Description: "Website Builder",
    Keywords: "Fickle",
  });
  const [appData, setAppData] = useState([]);
  const [pageState, setPageState] = useState("");

  const [isLoggedIn, setIsLoggedIn] = useState(userId ? true : false);
  const [loading, setLoading] = useState(false);

  async function getDomains() {
    const docRef = doc(db, "domains", "h4tewoKQ0vcQCxEAaWRR");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let host = window.location.hostname;
      let domain = host.split(".");
      if (domain[0].includes("www")) {
        setDomains(docSnap?.data().domains[domain[1]]);
      } else {
        setDomains(docSnap?.data().domains[domain[0]]);
      }
    } else {
      // console.log("No such document!");
    }
  }

  React.useEffect(() => {
    getDomains();
  }, []);

  React.useEffect(() => {
    setId(domains ? domains : localStorage.getItem("EditableCampuz"));
  }, [domains]);

  // get website data for end user
  async function userWebsiteData() {
    const querySnapshot = await getDocs(collection(db, "websitedata"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      setWebsiteData(doc.data().websiteData);
      setDomainname(doc.data().domainame);
      setEmailcred(doc.data().emailcred);
      setSiteSettings({
        ...doc?.data().siteSettings,
        siteBackground: doc?.data().siteSettings
          ? doc?.data().siteSettings.siteBackground
          : doc?.data().siteBackground,
      });
    });
  }

  async function getAppFormData() {
    const docRef = doc(db, "formdata", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setAppData(docSnap.data().applicationform);
    } else {
      // console.log("No such document!");
    }
  }

  // get website layout information for end user
  async function userLayoutData() {
    const querySnapshot = await getDocs(collection(db, "layout"));
    querySnapshot.forEach((doc) => {
      let val = doc.data();
      setLayoutData(val);
      var newArr = [];
      for (var i = 0; i < val.layout.length; i++) {
        let tempArr = Layout.filter((x) => val.layout[i].id === x.id);
        newArr = newArr.concat(tempArr);
      }

      let finalArray = newArr?.map((item, i) =>
        Object.assign({}, item, val.layout[i])
      );
      setLayoutFlow(finalArray);
    });
  }
  // function to get website data by admin
  async function getWebsiteData() {
    setLoading(true);
    const docRef = doc(db, "websitedata", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setWebsiteData(docSnap.data().websiteData);
      setDomainname(docSnap.data().domainname);
      setEmailcred(docSnap.data().emailcred);
      setSiteSettings({
        ...docSnap?.data().siteSettings,
        siteBackground: docSnap?.data().siteSettings
          ? docSnap?.data().siteSettings.siteBackground
          : docSnap?.data().siteBackground,
      });
    } else {
      // console.log("No such document!");
    }
    setLoading(false);
  }

  // function to get user data of admin
  async function getUserData() {
    setLoading(true);
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUser(docSnap.data());
      setLoading(false);
    } else {
      // console.log("No such document!");
      setLoading(false);
    }
  }
  // function to get website layout & page information for admin
  async function getLayoutData() {
    setLoading(true);
    const docRef = doc(db, "layout", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let val = docSnap.data();

      setLayoutData(val.layout);
      setPageFlow(val.pages);
      setPageState(val.pageState);
      if (val.pages.length > 0) {
        setSelectedPage(val.pages[0]);
      }
      let initial = val.pages[0]; // get the name oof first page
      let newArr = [];
      for (const data in val.layout[initial]) {
        let tempArr = Layout.filter((x) => data.id === x.id);
        newArr = newArr.concat(tempArr);
      }

      let finalArray = newArr?.map((item, i) =>
        Object.assign({}, item, val.layout[i])
      );
      setLayoutFlow(finalArray);
      setLoading(false);
    } else {
      // console.log("No such document!");
      setLoading(false);
    }
  }

  const updateSelectedPage = (value) => {
    setSelectedPage(value);
  };

  const updateData = (data, Identifier) => {
    let validateValue = false;
    for (const key of Object.keys(websiteData)) {
      if (key.toLocaleLowerCase() === selectedPage.toLocaleLowerCase()) {
        validateValue = true;
      }
    }
    let updatedData = {};
    if (validateValue) {
      const pageData = websiteData[selectedPage];
      pageData[Identifier] = data;
      updatedData = Object.assign(websiteData, { [selectedPage]: pageData });
    } else {
      const newObj = { [Identifier]: data };
      updatedData = Object.assign(websiteData, { [selectedPage]: newObj });
    }
    if (Identifier.includes("Nav") || Identifier.includes("Foo")) {
      if (
        layoutData[pageFlow[0]].findIndex(
          ({ uniqId }) => uniqId === Identifier
        ) === 0 ||
        layoutData[pageFlow[0]].findIndex(
          ({ uniqId }) => uniqId === Identifier
        ) ===
          layoutData[pageFlow[0]].length - 1
      ) {
        let website = updatedData;
        let tempObj = {};
        for (const key of pageFlow) {
          Object.assign(tempObj, { [key]: { [Identifier]: data } });
        }
        for (const key of Object.keys(tempObj)) {
          if (website[key] === undefined) {
            Object.assign(website, { [key]: tempObj[key] });
          } else {
            Object.assign(website[key], tempObj[key]);
          }
        }
        updateDoc(doc(db, "websitedata", userId), {
          websiteData: website,
        });
      } else {
        updateDoc(doc(db, "websitedata", userId), {
          websiteData: updatedData,
        });
      }
    } else {
      // upadte in db
      updateDoc(doc(db, "websitedata", userId), {
        websiteData: updatedData,
      });
    }
  };

  const deleteData = (Identifier, page) => {
    if (websiteData[page] !== undefined) {
      let tempArr = websiteData[page];
      if (tempArr[Identifier] !== undefined) {
        delete tempArr[Identifier];
        setWebsiteData((prevState) => {
          prevState[page] = tempArr;
          return {
            ...prevState,
          };
        });
      }
    }
  };

  const deletePage = (pageId) => {
    let newPageFlow = pageFlow.filter((page) => page !== pageId); // remove page from pages

    let newLayoutData = layoutData;
    delete newLayoutData[pageId]; // remove page data from layout

    updateDoc(doc(db, "layout", userId), {
      layout: newLayoutData,
      pages: newPageFlow,
    });

    let newWebsiteData = websiteData;
    delete newWebsiteData[pageId]; // remove respective websitedata stored for that page

    updateDoc(doc(db, "websitedata", userId), {
      websiteData: newWebsiteData,
    });
  };

  const updateUser = (data) => {
    setUser(data);

    if (data.newPassword === "") {
      updateDoc(doc(db, "users", userId), data);
    } else {
      // function to update new passsowrd
      updatePassword(auth.currentUser, data.newPassword)
        .then(() => {
          alert("New password was changed");
          updateDoc(doc(db, "users", userId), {
            ...data,
            password: data.newPassword,
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  // function to update layout array
  const updateLayout = (data) => {
    setLayoutFlow(data);
    var tempArr = [];
    for (var i = 0; i < data.length; i++) {
      let newData = {
        id: data[i].id,
        uniqId: data[i].uniqId,
      };
      tempArr = tempArr.concat(newData);
    }
    setLayoutData({
      layout: tempArr,
    });
  };

  const addLayout = (data) => {
    setLayoutFlow((prevState) => {
      return [...prevState, data];
    });
  };

  // toggles between editing
  const updateIsEditable = (data) => {
    setIsEditable(data);
  };

  // function to update & set userId in local storage && session storage
  const setUserId = (uId) => {
    setId(uId); // set userId
    // setIsAdmin(uId);
    setIsLoggedIn(true);
    sessionStorage.setItem("EditableCampuz", uId);
    localStorage.setItem("EditableCampuz", uId);
  };

  // Get Contact Form Data
  async function getFormData() {
    const docRef = doc(db, "formdata", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setMessageData(docSnap.data().message);
    } else {
      // console.log("No such document!");
    }
  }
  // Get Application Form Data
  async function getAppFormData() {
    const docRef = doc(db, "formdata", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setAppData(docSnap.data().applicationform);
    } else {
      // console.log("No such document!");
    }
  }
  // submit function for message
  function formHandler(data) {
    let d = new Date();
    let newContent = {
      ...data,
      id: d.getTime(),
    };
    updateDoc(doc(db, "formdata", userId), {
      message: arrayUnion(newContent),
    });
  }
  // application function for message
  function appformHandler(data) {
    let d = new Date();
    let newContent = {
      ...data,
      id: d.getTime(),
    };
    updateDoc(doc(db, "formdata", userId), {
      applicationform: arrayUnion(newContent),
    });
  }

  //send email details
  function mailSend(title, data) {
    let mailData = {};
    if (title === "Application Form") {
      mailData = {
        title: "Application Form",
        Student_name: data.student_name,
        Previous_school: data.previous_school,
        Current_class: data.current_class,
        Father_name: data.father_name,
        Email: data.email,
        Mob_no: data.phone,
        Profile_pic: data.img,
        Message: data.message,
      };
    } else {
      mailData = {
        title: "Enquiry Form",
        Company_name: data.name,
        Gmail: data.email,
        Mob_no: data.phone,
        Name: data.message,
        Message: data.company,
      };
    }
    if (
      emailcred?.from_email !== undefined &&
      emailcred?.email_password !== undefined
    ) {
      axios.post(
        "https://devczerpbackend.anichadigitalinfra.com/api/ficklecontest/form/mail/?to_mail=" +
          emailcred.to_email,
        mailData,
        {
          headers: {
            Authorization:
              emailcred.from_email +
              " " +
              window.atob(emailcred.email_password),
          },
        }
      );
    }
  }

  // logout function
  const logoutHandler = () => {
    auth
      .signOut() // end user session in firebase
      .then(() => {
        setIsLoggedIn(false);
        setId(); // empty user Id
        // console.log("Signed out successfully!!!");
        sessionStorage.clear("editablecampuz"); // removes item from session storage
        localStorage.clear("editablecampuz"); // removes item from local storage
        navigate("/login", { replace: true });
      })
      .catch((e) => console.log(e));
  };

  return (
    <AuthContext.Provider
      value={{
        setUserId: setUserId,
        logout: logoutHandler,
        userId: userId,
        user: user,
        websiteData: websiteData,
        pageState: pageState,
        selectedPage: selectedPage,
        pageFlow: pageFlow,
        layoutFlow: layoutFlow,
        layoutData: layoutData,
        isLoggedIn: isLoggedIn,
        isEditable: isEditable,
        updateIsEditable: updateIsEditable,
        userWebsiteData: userWebsiteData,
        userLayoutData: userLayoutData,
        getWebsiteData: getWebsiteData,
        getUserData: getUserData,
        getLayoutData: getLayoutData,
        updateUser: updateUser,
        updateSelectedPage: updateSelectedPage,
        updateData: updateData,
        deleteData: deleteData,
        deletePage: deletePage,
        updateLayout: updateLayout,
        setLayoutData: setLayoutData,
        addLayout: addLayout,
        formHandler: formHandler,
        getFormData: getFormData,
        messageData: messageData,
        appformHandler: appformHandler,
        getAppFormData: getAppFormData,
        appData: appData,
        mailSend: mailSend,
        emailcred: emailcred,
        domainname: domainname,
        setSiteSettings: setSiteSettings,
        siteSettings: siteSettings,
        loading: loading,
        setLoading: setLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
