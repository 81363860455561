import React, { useContext, useState } from "react";
import AuthContext from "../../../Context/Context";
import styles from "./Hero3.module.css";
import Loader from "../../../loader/Loader";
import clsx from "clsx";
import { toast } from "react-toastify";
import banner from "../../../Assests/diviImages/banner1.jpg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createStyles, makeStyles } from "@mui/styles";

// import { DefaultEditor } from "react-simple-wysiwyg";
import { Markup } from "interweave";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const useStyles = makeStyles(() =>
  createStyles({
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
  })
);

const Hero3 = (props) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");
  const ctx = useContext(AuthContext);

  const data = {
    header: "Our Pre-School. Our Family. Our Community",
    para: "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various version</p>",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    img: banner,
  };

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : data
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(localData.para))
    )
  );
  React.useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(localData.para))
      )
    );
  }, [localData]);

  const [convertedstate, setConvertedContent] = useState(localData.para);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    // let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const onChangeHandler = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: val,
      };
    });
  };

  const onImageChange = (e) => {
    // setError(null);
    let d = new Date().getTime();
    let selected = e.target.files[0];

    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;

    const storage = getStorage();
    const uploadPath = ctx.userId + "/hero3" + ctx.selectedPage + props.id;
    const storageRef = ref(storage, uploadPath);
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    // upload path
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else {
      setloading(true);
      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            return {
              ...prevState,
              img: url,
            };
          });
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    }
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div></div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>

          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Hero background
          </p>
          <HexColorPicker
            color={localData.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Footer Text Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text-Color</small>
          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const onSaveHandler = () => {
    setloading(true);

    let data = {
      ...localData,
      para: convertedstate,
    };
    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };
  return (
    <>
      {ctx.isEditable ? (
        <>
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div
        style={{ background: localData?.backgroundColor }}
        className={clsx("container my-0", styles.preschool)}
      >
        <div className="row m-0">
          <div className={`col-md-7 ${styles.boxen}`}>
            <div className={` ${styles.preschool_text}`}>
              {ctx.isEditable ? (
                <>
                  <input
                    name="header"
                    className={clsx(styles.inputHeading, localData?.fontFamily)}
                    style={{
                      color: localData?.headerColor,
                    }}
                    onChange={onChangeHandler}
                    value={localData.header}
                  />
                  {/* <DefaultEditor
                    name="para"
                    value={localData.para}
                    onChange={(e) => onChangeHandler(e)}
                  /> */}
                  <Editor
                    name="para"
                    // editorState={localData.para}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                  />
                </>
              ) : (
                <>
                  <h2
                    className={clsx(
                      styles.inputHeading,
                      localData?.fontFamily,
                      "mb-3"
                    )}
                    style={{
                      color: localData?.headerColor,
                    }}
                  >
                    {localData.header}
                  </h2>

                  <div className={`${styles.inputPara}`}>
                    <Markup content={localData?.para} />
                    {/* <div dangerouslySetInnerHTML={{ __html: yourhtml }} /> */}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={`col-md-5 ${styles.bot} ${styles.rt_pad}`}>
            <div className="position-relative">
              <div className={` ${styles.image}`}>
                <img className="img-fluid" src={localData.img} />
              </div>
              {ctx.isEditable ? (
                <>
                  <input
                    type="file"
                    onChange={(e) => onImageChange(e)}
                    className={classes.inputFile}
                    id={localData.header}
                    name={localData.header}
                  />
                  <label
                    className={classes.inputLabel}
                    htmlFor={localData.header}
                  >
                    <i className="fa fa-upload"></i>
                  </label>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero3;
