import React, { useContext, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import { Update } from "../../../loader/Update";
import clsx from "clsx";
import { fontSize, margin, textAlign } from "@mui/system";
// import clsx from "clsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#555",
    },
    editable: {
      textAlign: "center",
      color: "white",
      background: "transparent",
      outline: "0",
      border: "none",
    },
    row: {
      position: "relative",
      display: "flex",
      padding: "10px",
      flexWrap: "wrap",
    },
    col: {
      width: "33.33%",
    },
    divider: {
      height: "5px",
      width: "40%",
      backgroundColor: "white",
      margin: "auto",
    },
    introHeader: {
      textAlign: "center",
      color: "white",
      fontSize: "30px",
    },
    list: {
      margin: "10px",
      listStyle: "none",
      fontSize: "25px",
      color: "white",
      textAlign: "center",
    },
    "@media (max-width: 900px)": {
      introHeader: {
        fontSize: "22px",
      },
      divider: {
        height: "2px",
      },
      list: {
        fontSize: "20px",
      },
    },
    "@media (max-width: 600px)": {
      col: {
        width: "100%",
      },
      introHeader: {
        fontSize: "20px",
      },
      divider: {
        height: "2px",
      },
      list: {
        fontSize: "18px",
      },
    },
  })
);

export const Footer1 = (props) => {
  const [loading, setloading] = useState(false);
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const ctx = useContext(AuthContext);
  const cardData = {
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    paracolor: "",
    parafont: "",
    underlinecolor: "",
    data: [
      {
        header: "Intro header 1",
        line1: "loerum",
        line2: "loerum",
        line3: "loerum",
        line4: "loerum",
        id: "0",
      },
      {
        header: "Intro header 2",
        line1: "loerum",
        line2: "loerum",
        line3: "loerum",
        line4: "loerum",
        id: "1",
      },
      {
        header: "Intro header 2",
        line1: "loerum",
        line2: "loerum",
        line3: "loerum",
        line4: "loerum",
        id: "1",
      },
    ],
  };
  let pageData = ctx.websiteData[ctx.selectedPage];

  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : cardData
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [menuItems, setMenuItems] = React.useState(localData?.data);
  const onChangeHandler = (e, details, index) => {
    setMenuItems((prevState) => {
      let updatedData = null;
      if (e.target.id === "header") {
        updatedData = {
          ...details,
          header: e.target.value,
        };
      } else if (e.target.id === "line1") {
        updatedData = {
          ...details,
          line1: e.target.value,
        };
      } else if (e.target.id === "line2") {
        updatedData = {
          ...details,
          line2: e.target.value,
        };
      } else if (e.target.id === "line3") {
        updatedData = {
          ...details,
          line3: e.target.value,
        };
      } else if (e.target.id === "line4") {
        updatedData = {
          ...details,
          line4: e.target.value,
        };
      }
      prevState[index] = updatedData;
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                fontFamily: "",
                backgroundColor: "",
                paracolor: "",
                parafont: "",
                underlinecolor: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Footer background
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Menu Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.parafont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                parafont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>

          <HexColorPicker
            color={localData?.paracolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                paracolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.paracolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                paracolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            underline Styles
          </p>

          <HexColorPicker
            color={localData.underlinecolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                underlinecolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.underlinecolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                underlinecolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const classes = useStyles();
  let editable = (
    <>
      <div className={classes.row}>
        {menuItems?.map((details, index) => (
          <div key={index} className={classes.col}>
            <input
              onChange={(e) => onChangeHandler(e, details, index)}
              className={clsx(classes.editable, localData?.fontFamily)}
              style={{
                color: localData?.headerColor,
                width: "75%",
                fontSize: "30px",
              }}
              id="header"
              placeholder="header"
              value={details.header}
              maxLength={20}
            />
            <div
              className={classes.divider}
              style={{ background: localData?.underlinecolor }}
            ></div>
            <ul className={clsx(classes.list)}>
              <li>
                <input
                  onChange={(e) => onChangeHandler(e, details, index)}
                  className={clsx(classes.editable, localData?.parafont)}
                  style={{ color: localData?.paracolor, width: "75%" }}
                  id="line1"
                  placeholder="line1"
                  value={details.line1}
                  maxLength={25}
                />
              </li>
              <li>
                <input
                  onChange={(e) => onChangeHandler(e, details, index)}
                  className={clsx(classes.editable, localData?.parafont)}
                  id="line2"
                  placeholder="line2"
                  value={details.line2}
                  maxLength={25}
                  style={{ color: localData?.paracolor, width: "75%" }}
                />
              </li>
              <li>
                <input
                  onChange={(e) => onChangeHandler(e, details, index)}
                  className={clsx(classes.editable, localData?.parafont)}
                  id="line3"
                  placeholder="line3"
                  value={details.line3}
                  maxLength={25}
                  style={{ color: localData?.paracolor, width: "75%" }}
                />
              </li>
              <li>
                <input
                  onChange={(e) => onChangeHandler(e, details, index)}
                  className={clsx(classes.editable, localData?.parafont)}
                  id="line4"
                  placeholder="line4"
                  value={details.line4}
                  maxLength={25}
                  style={{ color: localData?.paracolor, width: "75%" }}
                />
              </li>
            </ul>
          </div>
        ))}
      </div>
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    const data = {
      ...localData,
      data: menuItems,
    };
    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success(" Sucessfully Updated");
    }, 2000);
  };

  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div
        className={classes.root}
        style={{ background: localData?.backgroundColor }}
      >
        {ctx.isEditable ? (
          editable
        ) : (
          <div className={classes.row}>
            {menuItems?.map((details, index) => (
              <div key={index} className={classes.col}>
                <h2
                  className={clsx(classes.introHeader, localData?.fontFamily)}
                  style={{ color: localData?.headerColor }}
                >
                  {details.header}
                </h2>
                <div
                  className={classes.divider}
                  style={{ background: localData?.underlinecolor }}
                ></div>
                <ul
                  className={clsx(classes.list)}
                  style={{ color: localData?.paracolor }}
                >
                  <li
                    className={localData?.parafont}
                    style={{ color: localData?.paracolor }}
                  >
                    {details.line1}
                  </li>
                  <li
                    className={localData?.parafont}
                    style={{ color: localData?.paracolor }}
                  >
                    {details.line2}
                  </li>
                  <li
                    className={localData?.parafont}
                    style={{ color: localData?.paracolor }}
                  >
                    {details.line3}
                  </li>
                  <li
                    className={localData?.parafont}
                    style={{ color: localData?.paracolor }}
                  >
                    {details.line4}
                  </li>
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
