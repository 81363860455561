import React, { useContext, useState } from "react";
import styles from "./Footer.module.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import { createStyles, makeStyles } from "@mui/styles";
import { Update } from "../../../loader/Update";
import clsx from "clsx";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import SubscriptionsIcon from "@mui/icons-material/YouTube";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { HashLink } from "react-router-hash-link";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#555",
    },
    editable: {
      textAlign: "center",
      color: "black",
      background: "transparent",
      outline: "0",
      border: "none",
    },

    editableAlign: {
      textAlign: "left",
      color: "black",
      background: "transparent",
      outline: "0",
      border: "none",
      width: "100%",
    },

    listStyling: {
      "& ul": {
        listStyle: "none",
        "& li": {
          padding: "5px",
        },
        "& li a": {
          color: "black",
        },
        "& li a:hover": {
          color: "green",
        },
      },
    },

    logoContainer: {
      width: "20%",
      // marginLeft:"150px",
      marginTop: "20px",
      position: "relative",

      "& img": {
        width: "50%",
        height: "3rem",
        objectFit: "cover",
        marginBottom: "10px",
        borderRadius: "5px",
      },
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    pathSelector: {
      padding: "1rem 0",
    },

    "@media (max-width: 600px)": {
      headingFt: {
        fontSize: "20px",
      },
      listStyling: {
        "& ul": {
          fontSize: "15px",
        },
      },
    },
  })
);

const Footer3 = (props) => {
  const classes = useStyles();

  const [loading, setloading] = useState(false);
  const ctx = useContext(AuthContext);
  const location = useLocation();
  const [pageState, setPageState] = useState("single");
  const [defaultData, setDefaultdata] = useState();

  const [style, setStyle] = React.useState("full");

  const data = {
    logo: "HeaderLogo",
    header1: "Follow",
    twitterLink: "https://www.mockplus.com/blog/post/website-footer-design ",
    instagramLink: "https://www.godaddy.com/en-in ",
    facebookLink: "https://www.shopify.in/",
    linkedinLink: "https://www.flipkart.com/",
    youtubeLink: "https://www.youtube.com/",
    pageState: pageState,
    header2: "Quicklink",
    headerColor: "",
    fontFamily: "",
    backgroundColor: "",
    paracolor: "",
    parafont: "",
    menus: [
      {
        label: "menu1",
        link: "twitter",
      },
      {
        label: "menu2",

        link: "instagram",
      },
      {
        label: "menu3",

        link: "facebook",
      },
      {
        label: "menu4",

        link: "facebook",
      },
      {
        label: "menu5",

        link: "whatsapp",
      },
    ],

    header3: "Address",
    address1: "Anicha infotech private ltd,",
    address2: " VelTech",
    phoneno: " 00-8888-9999",
  };

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : data
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);

  const [menu, setMenu] = useState(localData?.menus);
  const [pathArray, setPathArray] = useState([]);
  const onChange = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  React.useEffect(() => {
    setMenu(localData?.menus);
  }, [localData]);

  React.useEffect(() => {
    setPageState(localData?.pageState);
  }, [localData]);

  React.useEffect(() => {
    setPageState(ctx.pageState ? ctx.pageState : pageState);
  }, [ctx.pageState, pageState]);

  React.useEffect(() => {
    if (ctx.selectedPage !== "") {
      if (ctx.layoutData[ctx.selectedPage] !== undefined) {
        if (pageState === "single") {
          var newObj = ctx.layoutData[ctx.selectedPage];
          var tempArray = newObj.map(function (mopt) {
            var info = {
              value: mopt.uniqId,
              label: rename(mopt.uniqId),
            };
            return info;
          });
          setPathArray(tempArray);
        } else if (pageState === "multiple") {
          var tempArray = ctx.pageFlow?.map(function (mopt) {
            var info = {
              value: mopt,
              label: mopt,
            };
            return info;
          });
          setPathArray(tempArray);
        }
      }
    }
  }, [pageState]);

  const rename = (name, i) => {
    let firstName = name.substring(0, name.length - 2);
    let lastName = name.charAt(name.length - 1);
    let newName = firstName + " " + lastName;
    return newName;
  };

  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.name] = e.target.value;
    } else {
      tempEventInputs["link"] = e.value;
    }
    setMenu((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const onImageChange = (e, i) => {
    // setError(null);

    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;

    if (!selected) {
      // setError("Please select file");
      return;
    }

    if (!selected.type.includes("image")) {
      // setError("Please select image file");
      return;
    }
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else {
      setloading(true);
      const storage = getStorage();
      const uploadPath = ctx.userId + "/footer33437487432b"; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data

      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            return {
              ...prevState,
              logo: url,
            };
          });
          setTimeout(() => {
            setloading(false);
            toast.success(" Image uploaded");
          }, 2000);
        });
      });
    }

    // setError(null);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <></>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                headerColor: "",
                fontFamily: "",
                backgroundColor: "",
                paracolor: "",
                parafont: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Footer background
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.fontFamily}
            onChange={(e) =>
              setLocalData({
                ...localData,
                fontFamily: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData.headerColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                headerColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.headerColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                headerColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Menu Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.parafont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                parafont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />

          <small>Text Color</small>
          <HexColorPicker
            color={localData?.paracolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                paracolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.paracolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                paracolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  let editable = (
    <>
      <div
        className="row py-3 row justify-content-center"
        style={{
          backgroundColor: localData?.backgroundColor
            ? localData?.backgroundColor
            : "#F9F9F9",
          color: "black",
          fontSize: "20px",
        }}
      >
        <div className="col-lg-4  ml-2">
          <div className={classes.logoContainer}>
            <input
              type="file"
              onChange={(e) => onImageChange(e)}
              className={classes.inputFile}
              id="logo"
              name="logo"
            />
            <label className={classes.inputLabel} htmlFor="logo">
              <i className="fa fa-upload"></i>
            </label>
            <img src={localData.logo} alt="headerLogo" />
          </div>
        </div>

        <div className="col-lg-4 py-3">
          <h3 className="text-center">
            <input
              className={clsx(classes.editable, localData?.fontFamily)}
              style={{ color: localData?.headerColor }}
              id="header1"
              placeholder="header"
              onChange={onChange}
              value={localData.header1}
            />
          </h3>

          <div className="row justify-content-center m-5">
            <div className="col ">
              <div className="row  justify-content-center">
                <div className="col">
                  <Grid item xs={1}>
                    <a href={localData.twitterLink} className={styles.link1}>
                      <TwitterIcon />
                    </a>
                  </Grid>
                </div>
                {localData.instagramLink !== "" && (
                  <div className="col">
                    <Grid item xs={1}>
                      <a
                        href={localData.instagramLink}
                        className={styles.link2}
                      >
                        <InstagramIcon />
                      </a>
                    </Grid>
                  </div>
                )}
                {localData.facebookLink !== "" && (
                  <div className="col">
                    <Grid item xs={1}>
                      <a href={localData.facebookLink} className={styles.link3}>
                        <FacebookIcon />
                      </a>
                    </Grid>
                  </div>
                )}
                {localData.instagramLink !== "" && (
                  <div className="col">
                    <Grid item xs={1}>
                      <a
                        href={localData.instagramLink}
                        className={styles.link4}
                      >
                        <LinkedInIcon />
                      </a>
                    </Grid>
                  </div>
                )}
                {localData.youtubeLink !== "" && (
                  <div className="col">
                    <Grid item xs={1}>
                      <a href={localData.youtubeLink} className={styles.link5}>
                        <SubscriptionsIcon />
                      </a>
                    </Grid>
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </div>

        <div
          className={clsx(classes.listStyling, "col-4 px-5 text-center  mt-3")}
        >
          <ul>
            <h3>
              {" "}
              <input
                // onChange={(e) => onChangeHandler(e, details, index)}

                className={clsx(localData?.fontFamily, classes.editable)}
                style={{ color: localData?.headerColor }}
                id="header2"
                placeholder="header"
                value={localData.header2}
                onChange={onChange}
              />
            </h3>
            {menu.map((data, index) => (
              <li key={index} className="ml-4">
                <input
                  value={data.label}
                  className={clsx(localData?.parafont, classes.editable)}
                  style={{ color: localData?.paracolor }}
                  name="label"
                  placeholder="label"
                  onChange={(e) => onChangeHandler(e, data, index)}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className={clsx(classes.listStyling, "col-lg-4 px-5  mt-3")}>
          <h3>
            {" "}
            <input
              className={clsx(classes.editable, localData?.fontFamily)}
              style={{ color: localData?.headerColor }}
              id="header3"
              placeholder="header"
              onChange={onChange}
              value={localData.header3}
            />
          </h3>

          <ul>
            <li>
              {" "}
              <input
                className={clsx(localData?.parafont, classes.editableAlign)}
                style={{ color: localData?.paracolor }}
                id="address1"
                placeholder="address1"
                onChange={onChange}
                value={localData.address1}
              />
            </li>
            <li>
              {" "}
              <input
                className={clsx(localData?.parafont, classes.editableAlign)}
                style={{ color: localData?.paracolor }}
                id="address2"
                placeholder="address2"
                onChange={onChange}
                value={localData.address2}
              />
            </li>
            <li>
              {" "}
              <input
                className={clsx(localData?.parafont, classes.editableAlign)}
                style={{ color: localData?.paracolor }}
                id="phoneno"
                placeholder="phoneno"
                onChange={onChange}
                value={localData.phoneno}
              />
            </li>
          </ul>
        </div>
      </div>
      {/* <FormControl className="mt-2">
        <FormLabel id="demo-controlled-radio-buttons-group">
          Menu Type
        </FormLabel>
        <RadioGroup
          row
          color="secondary"
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={pageState}
          onChange={(e) => {
            setPageState(e.target.value);
          }}
        >
          <FormControlLabel
            disabled={ctx.pageFlow?.length === 1}
            value="single"
            control={<Radio color="secondary" />}
            label="Single Page"
          />
          <FormControlLabel
            disabled={ctx.pageFlow?.length === 1}
            value="multiple"
            control={<Radio color="secondary" />}
            label="Multiple Page"
          />
        </RadioGroup>
      </FormControl> */}
      <div className="row">
        <div className={clsx(classes.editFont, "col m-1")}>
          <div className="p-3 ">
            <h3 className=" mb-3">Social media Links</h3>
            <span className="mx-2">Twitter Link</span>{" "}
            <input
              type="text"
              id="twitterLink"
              value={localData.twitterLink}
              onChange={onChange}
              className="w-100 form-control"
            ></input>
          </div>

          <div className="p-3 ">
            <span className="mx-2">Instagram Link</span>{" "}
            <input
              type="text"
              id="instagramLink"
              value={localData.instagramLink}
              onChange={onChange}
              className="w-100 form-control"
            ></input>
          </div>

          <div className="p-3 ">
            <span className="mx-2">Facebook Link</span>{" "}
            <input
              type="text"
              id="facebookLink"
              value={localData.facebookLink}
              onChange={onChange}
              className="w-100 form-control"
            ></input>
          </div>

          <div className="p-3 ">
            <span className="mx-2">Linkedin Link</span>{" "}
            <input
              type="text"
              id="linkedinLink"
              value={localData.linkedinLink}
              onChange={onChange}
              className="w-5O form-control"
            ></input>
          </div>
          <div className="p-3 ">
            <span className="mx-2">Youtube Link</span>{" "}
            <input
              type="text"
              id="youtubeLink"
              value={localData.youtubeLink}
              onChange={onChange}
              className="w-5O form-control"
            ></input>
          </div>
        </div>

        <div className={clsx(classes.editFont, "col-md-6 m-1 ")}>
          <h3 className="pt-3">Navigation Links</h3>

          <div className={classes.pathSelector}>
            {menu.map((item, index) => (
              <div className={classes.row} key={index}>
                <Typography className="mx-2">{item.label}</Typography>
                <Select
                  className="pb-4 "
                  id="link"
                  name="link"
                  options={pathArray}
                  value={pathArray?.filter(function (option) {
                    return option.value === item.link;
                  })}
                  onChange={(e) => onChangeHandler(e, item, index)}
                  styles={{ width: "20%" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  const onSaveHandler = () => {
    setloading(true);
    const data = {
      ...localData,
      menus: menu,
      pageState: pageState,
    };

    ctx.updateData(data, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success(" Sucessfully Updated");
    }, 2000);
  };

  //
  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <div className={classes.modalBox}>
        {ctx.isEditable ? (
          editable
        ) : (
          <div
            className="row d-flex "
            style={{
              backgroundColor: localData?.backgroundColor
                ? localData?.backgroundColor
                : "#F9F9F9",
              color: "black",
              fontSize: "20px",
            }}
          >
            <div
              className={clsx(classes.listStyling, "col-lg-4 text-lg-center")}
            >
              <ul>
                <li className="d-flex justify-content-lg-center">
                  <div className={classes.logoContainer}>
                    <img src={localData.logo} alt="headerLogo" />
                  </div>
                </li>
                <li>
                  <h3
                    className={clsx(
                      "text-md-center ml-md-5",
                      classes.headingFt,
                      localData?.fontFamily
                    )}
                    style={{ color: localData?.headerColor }}
                  >
                    {localData.header1}
                  </h3>
                </li>

                <div className="row d-flex justify-content-md-center mt-md-5 mt-2 ml-md-5 ">
                  {localData.twitterLink !== "" && (
                    <div className="mx-1">
                      <a href={localData.twitterLink} className={styles.link1}>
                        <TwitterIcon />
                      </a>
                    </div>
                  )}
                  {localData.instagramLink !== "" && (
                    <div className="mx-1">
                      <a
                        href={localData.instagramLink}
                        className={styles.link2}
                      >
                        <InstagramIcon />
                      </a>
                    </div>
                  )}
                  {localData.facebookLink !== "" && (
                    <div className="mx-1">
                      <a href={localData.facebookLink} className={styles.link3}>
                        <FacebookIcon />
                      </a>
                    </div>
                  )}
                  {localData.linkedinLink !== "" && (
                    <div className="px-1">
                      <a href={localData.linkedinLink} className={styles.link4}>
                        <LinkedInIcon />
                      </a>
                    </div>
                  )}
                  {localData.youtubeLink !== "" && (
                    <div className="mx-1">
                      <a href={localData.youtubeLink} className={styles.link5}>
                        <SubscriptionsIcon />
                      </a>
                    </div>
                  )}
                </div>
              </ul>
            </div>

            <div
              className={clsx(
                classes.listStyling,
                "col-lg-4 d-flex justify-content-md-center mt-3"
              )}
            >
              <ul>
                <h3
                  className={clsx(classes.headingFt, localData?.fontFamily)}
                  style={{ color: localData?.headerColor }}
                >
                  {localData.header2}
                </h3>

                {pageState === "single"
                  ? menu.map((item, index) => (
                      <li key={index}>
                        <a
                          className={localData?.parafont}
                          style={{ color: localData?.paracolor }}
                          href={`#${item.link}`}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))
                  : menu.map((item, index) => (
                      <li key={index}>
                        <a
                          className={localData?.parafont}
                          style={{
                            color: localData?.paracolor,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (ctx.pageFlow.includes(item.link))
                              ctx.setLoading(true);
                            ctx.updateSelectedPage(item.link);
                          }}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>

            <div
              className={clsx(
                classes.listStyling,
                "col-lg-4 d-flex justify-content-md-center py-3"
              )}
            >
              <ul>
                <h3
                  className={clsx(
                    classes.headingFt,
                    "text-md-center",
                    localData?.fontFamily
                  )}
                  style={{ color: localData?.headerColor }}
                >
                  {localData.header3}
                </h3>
                <li
                  className={localData?.parafont}
                  style={{
                    color: localData?.paracolor,
                  }}
                >
                  {localData.address1}
                </li>
                <li
                  className={localData?.parafont}
                  style={{
                    color: localData?.paracolor,
                  }}
                >
                  {localData.address2}
                </li>
                <li
                  className={localData?.parafont}
                  style={{
                    color: localData?.paracolor,
                  }}
                >
                  {localData.phoneno}
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Footer3;
