import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../Context/Context";
import { ReactComponent as Bgicon1 } from "../../Assests/xd1.svg";
import { ReactComponent as Bgicon2 } from "../../Assests/xd4.svg";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import { GInput } from "../../Components/Settings/GInput";
import { GButton } from "../../Components/Settings/GButton";
import { doc, updateDoc } from "firebase/firestore";
import validator from "validator";
import { db } from "../../services/firebase";
import Loader from "../../loader/Loader";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import clsx from "clsx";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
const useStyles = makeStyles({
  actions: {
    width: "10%",
    cursor: "pointer",

    "& svg": {
      width: "0.75rem",
      height: "0.75rem",
    },
  },
  Bg1: {
    position: "absolute",
    bottom: "-23%",
    left: "-15%",
    fill: "rgba(158, 58, 140, 0.8)",
  },
  Bg2: {
    position: "absolute",
    top: "1%",
    left: "-50%",
  },
  errorMsg: {
    fontSize: "0.75rem",
    color: "#dc3545",
  },
  inputDomainField: {
    "& .MuiFormControl-root": {
      width: "75%",
    },
  },
  "@media (max-width: 1200px)": {
    Bg2: {
      left: "-73% ",
    },
  },
});

const Settings = () => {
  const ctx = useContext(AuthContext);
  let name = ctx.user.Contestant_name;

  let [updatedvalue, setUpdatedvalue] = useState({});
  const ProfilePic = (name) => {
    var FirstLetter = name.match(/\b(\w)/g); // returns an array of first letter of each word
    var Profile = FirstLetter.join(""); // joins each letter in an array to form a single word
    return Profile.toUpperCase();
    // console.log(Profile);
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showFromemailpassword, setShowFromemailpassword] =
    React.useState(false);

  useEffect(() => {
    ctx.updateIsEditable(false);
    ctx.getUserData();
  }, []);

  const classes = useStyles();
  const defaultvalues = {
    Contestant_name: ctx.user.Contestant_name,
    School_name: ctx.user.School_name,
    Mobile_no: ctx.user.Mobile_no,
    Mail_id: ctx.user.Mail_id,
    password: ctx.user.password,
    oldPassword: "",
    newPassword: "",
    from_email: ctx.emailcred.from_email,
    to_email: ctx.emailcred.to_email,
    email_password: ctx.emailcred.email_password,
    Domain_name: ctx.user.Domain_name,
  };
  const [formValues, setFormValues] = useState(defaultvalues);
  const [enableUsername, setEnableUsername] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const submitHandler = () => {
    if (
      formValues.Contestant_name === "" ||
      formValues.School_name === "" ||
      formValues.Domain_name === ""
    ) {
      setError("Field Values Cannot be empty");
    } else if (
      formValues.from_email !== "" &&
      validator.isEmail(formValues.from_email) === false
    ) {
      setError("Provide valid Email");
    } else if (formValues.newPassword === "" && formValues.oldPassword === "") {
      setloading(true);
      ctx.updateUser(formValues);
      updateDoc(doc(db, "websitedata", ctx.userId), {
        domainame: formValues.Domain_name,
        emailcred: {
          from_email: formValues.from_email,
          email_password: window.btoa(formValues.email_password),
          to_email: formValues.to_email,
        },
      });
      setEnableSave(false);
      setEnableUsername(!enableUsername);
      setError();
      setTimeout(() => {
        setloading(false);
        toast.success("Sucessfully Updated");
      }, 2000);
    } else if (formValues.newPassword === formValues.oldPassword) {
      setError("New password and old password was same");
    } else if (formValues.oldPassword !== formValues.password) {
      setError("Wrong old password");
    } else if (formValues.newPassword !== "" && formValues.oldPassword !== "") {
      setloading(true);
      ctx.updateUser(formValues);
      updateDoc(doc(db, "websitedata", ctx.userId), {
        domainame: formValues.Domain_name,
        emailcred: {
          from_email: formValues.from_email,
          email_password: window.btoa(formValues.email_password),
          to_email: formValues.to_email,
        },
      });
      setEnableSave(false);
      setEnableUsername(!enableUsername);
      setError();
      setTimeout(() => {
        setloading(false);
        toast.success("Sucessfully Updated");
      }, 2000);
    }
  };

  const handleChange = (inputObj) => {
    if (inputObj.target) {
      setFormValues((prevState) => {
        return {
          ...prevState,
          [inputObj.target.id]: inputObj.target.value,
        };
      });
    } else {
      setFormValues((prevState) => {
        return {
          ...prevState,
          [inputObj.id]: inputObj.value,
        };
      });
    }
    setEnableSave(true);
  };

  const handleCancel = () => {
    setFormValues(defaultvalues);
    setEnableSave(false);
    setEnableUsername(false);
  };

  useEffect(() => {
    setFormValues(defaultvalues);
  }, [ctx.user]);

  return (
    <>
      {loading && (
        <>
          <Loader />
        </>
      )}

      <div
        className=" settings page-content page-container py-2"
        id="page-content"
      >
        <div
          className="row container d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <div className="col-xl-12 p-0 col-md-12">
            <div className="card user-card-full">
              <div className="row m-l-0 m-r-0">
                <div className="col-sm-3 postion-relative border-right bg-c-lite-green d-flex align-items-center justify-content-center user-profile">
                  <Bgicon1 className={classes.Bg1} />
                  <Bgicon2 className={classes.Bg2} />
                  <div className="card-block text-center text-white">
                    <div className="m-b-25">
                      <div className="d-flex justify-content-center text-center ml-2">
                        <div className="profile_img_top">
                          {name && ProfilePic(name)}
                        </div>
                      </div>
                    </div>
                    <h5 className="f-w-600" style={{ color: "var(--primary)" }}>
                      {formValues.Contestant_name}
                    </h5>
                    <p style={{ color: "var(--primary)" }}>
                      {formValues.School_name}
                    </p>{" "}
                    <i
                      className=" fa fa-pencil-square-o feather pl-2 py-2 my-3 icon-edit  m-t-10 f-16"
                      style={{
                        color: !enableUsername ? "#fff" : "var(--primary)",
                        background: !enableUsername ? "var(--primary)" : "#fff",
                        borderRadius: "20px",
                        padding: "5px",
                      }}
                      onClick={() => setEnableUsername(!enableUsername)}
                    ></i>
                  </div>
                </div>
                <div className="col-sm-9 p-0">
                  <div className="card-block ">
                    <h5
                      className=" w-50 text-white pl-2  py-1 p-b-5 b-b-default f-w-600"
                      style={{
                        backgroundColor: "rgba(158, 58, 140, 0.8)",
                        borderBottomRightRadius: "15px",
                      }}
                    >
                      Profile Information
                    </h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <p className="m-b-10 f-w-600">User Name</p>
                        <div className={classes.input}>
                          <GInput
                            id={"Contestant_name"}
                            value={formValues.Contestant_name}
                            onInputChange={handleChange}
                            variant="standard"
                            maxLength={30}
                            disabled
                            inputclassName={classes.inputField}
                            placeholder="Username"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <p className="m-b-10 f-w-600 ">Domain Name</p>
                        <div>
                          <GInput
                            id={"Domain_name"}
                            value={formValues.Domain_name}
                            type="text"
                            onInputChange={handleChange}
                            maxLength={50}
                            variant="standard"
                            disabled
                            inputclassName={classes.inputField}
                            placeholder="Domain Name"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <p className="m-b-10 f-w-600">Institution Name</p>
                        <div className={classes.input}>
                          <GInput
                            id={"School_name"}
                            value={formValues.School_name}
                            onInputChange={handleChange}
                            maxLength={50}
                            variant="standard"
                            disabled
                            inputclassName={classes.inputDomainField}
                            placeholder="Enter company Name"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <p className="m-b-10 f-w-600">Phone No</p>
                        <div className={classes.input}>
                          <GInput
                            id={"Mobile_no"}
                            value={formValues.Mobile_no}
                            onInputChange={handleChange}
                            variant="standard"
                            disabled
                            inputclassName={classes.inputField}
                            placeholder="Mobile No"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <p className="m-b-10 f-w-600">Email</p>
                        <div className={classes.input}>
                          <GInput
                            id={"Mail_id"}
                            value={formValues.Mail_id}
                            maxLength={50}
                            onInputChange={handleChange}
                            variant="standard"
                            disabled
                            inputclassName={classes.inputField}
                            placeholder="email"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 mt-2">
                        <small style={{ color: "rgba(158, 58, 140, 0.8)" }}>
                          This is for form email notifications
                        </small>
                      </div>

                      <div className="col-sm-6">
                        <p className="m-b-10 f-w-600 ">From Email </p>
                        <div className={classes.input}>
                          <GInput
                            id={"from_email"}
                            value={formValues.from_email}
                            type="email"
                            onInputChange={handleChange}
                            maxLength={50}
                            variant="standard"
                            className="w-100"
                            disabled={!enableUsername}
                            inputclassName={classes.inputField}
                            placeholder="From email"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <p className="m-b-10 f-w-600 ">From Email Password</p>
                        <div>
                          <TextField
                            id={"email_password"}
                            value={formValues.email_password}
                            type={showFromemailpassword ? "text" : "password"}
                            onChange={handleChange}
                            className="w-50"
                            maxLength={20}
                            variant="standard"
                            disabled={!enableUsername}
                            inputclassName={classes.inputField}
                            placeholder="Email Password"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowFromemailpassword(
                                        !showFromemailpassword
                                      )
                                    }
                                  >
                                    {showFromemailpassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <p className="m-b-10 f-w-600">To Email</p>
                        <div className={classes.input}>
                          <GInput
                            id={"to_email"}
                            value={formValues.to_email}
                            onInputChange={handleChange}
                            maxLength={50}
                            variant="standard"
                            disabled={!enableUsername}
                            inputclassName={classes.inputDomainField}
                            placeholder=" Enter Your To Email"
                          />
                        </div>
                      </div>
                    </div>
                    <h5
                      className=" mt-3 w-50 text-white pl-2  py-1 p-b-5 b-b-default f-w-600"
                      style={{
                        backgroundColor: "rgba(158, 58, 140, 0.8)",
                        borderBottomRightRadius: "15px",
                      }}
                    >
                      Password Reset
                    </h5>
                    <div className="row">
                      <div className="col-sm-6 my-2  mb-4">
                        <p className="m-b-10 f-w-600">Old Password</p>
                        <TextField
                          id={"oldPassword"}
                          className="w-50"
                          value={formValues.oldPassword}
                          onChange={handleChange}
                          variant="standard"
                          type={showOldPassword ? "text" : "password"}
                          disabled={!enableUsername}
                          inputclassName={classes.inputField}
                          placeholder="Old Password"
                          maxLength={20}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowOldPassword(!showOldPassword)
                                  }
                                >
                                  {showOldPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="col-sm-6 my-2 mb-4">
                        <p className="m-b-10 f-w-600">New Password</p>
                        <TextField
                          id={"newPassword"}
                          className="w-50"
                          value={formValues.newPassword}
                          onChange={handleChange}
                          variant="standard"
                          type={showPassword ? "text" : "password"}
                          disabled={!enableUsername}
                          inputclassName={classes.inputField}
                          placeholder="New Password"
                          maxLength={20}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    {error !== "" && (
                      <Typography className={classes.errorMsg}>
                        {error}
                      </Typography>
                    )}
                    <GButton
                      onClick={() => submitHandler()}
                      label="Save"
                      disabled={!enableSave}
                      boxShadow
                      isLong
                    />
                    <GButton
                      onClick={() => handleCancel()}
                      label="cancel"
                      disabled={!enableSave}
                      boxShadow
                      isLong
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
