import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles(() =>
  createStyles({
    cardHead: {
      backgroundColor: "#fff",
      borderRadius: "30px",
      position: "relative",
      padding: "30px",
    },
    leftH2: {
      marginTop: "30px",
      fontSize: "30px",
      color: "#9e3a8ccc",
    },
    pDate: {
      marginTop: "16px",
      fontSize: "14px",
      color: "#22215b",
    },
    pText: {
      marginTop: "14px",
      fontSize: "14px",
      color: "#22215b",
      opacity: "60%",
    },
    rightH2: {
      fontSize: "30px",
      color: "#9e3a8ccc",
    },
    todoCard: {
      backgroundColor: "#eef7fe",
      borderRadius: "10px",
      padding: "14px 31px 14px 14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "12px",
      "& p": {
        fontWeight: 700,
        color: "#22215b",
      },
      "& span": {
        width: "42px",
        height: "42px",
        borderRadius: "50%",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    todoCardHead: {
      display: "flex",
      alignItems: "center",
      gap: "14px",
    },
  })
);

export const About = () => {
  const classes = useStyles();
  return (
    <>
      <h3
        className="text-center mt-2"
        style={{
          color: "var(--primary)",
        }}
      >
        {" "}
        <InfoIcon className="mx-2" />
        About
      </h3>

      <div className="container d-flex justify-content-center align-items-center">
        <div className="left col-md-6">
          <div className={classes.cardHead}>
            <div className="image">
              <img
                src="https://i.postimg.cc/Kzzx26yz/Image-2x.jpg"
                alt="header-image"
              />
            </div>
            <h2 className={classes.leftH2}>Fickle</h2>
            <p className={classes.pDate}> Last update on Monday 11 18 , 2022</p>
            {/* <p className={classes.pText}>
              What is a microinteraction? Microinteractions are small moments
              where the user and design interact. When they're well designed,
              micro interactions enhance the user's experience...
            </p> */}
          </div>
        </div>

        <div className="right col-md-6">
          <div className={classes.cardHead}>
            <h2 className={classes.rightH2}>Latest version</h2>
            <p className={clsx(classes.pText, " mb-4")}>
              Micro-interactions play an essential role in a user's
              understanding of computer systems.
            </p>
            <div className="todo-cards">
              <div className={clsx(classes.todoCard, "todocard")}>
                <div className={classes.todoCardHead}>
                  <span>
                    <img
                      src="https://i.postimg.cc/J0wyzNcS/Tick.png"
                      alt="tick"
                    />
                  </span>
                  <p>Fickle</p>
                </div>
                <p>3.0</p>
              </div>
              <div className={clsx(classes.todoCard, "todocard")}>
                <div className={classes.todoCardHead}>
                  <span>
                    <img
                      src="https://i.postimg.cc/J0wyzNcS/Tick.png"
                      alt="tick"
                    />
                  </span>
                  <p>Firebase</p>
                </div>
                <p> 9.14.0</p>
              </div>
              <div className={clsx(classes.todoCard, "todocard")}>
                <div className={classes.todoCardHead}>
                  <span>
                    <img
                      src="https://i.postimg.cc/J0wyzNcS/Tick.png"
                      alt="tick"
                    />
                  </span>
                  <p>React js</p>
                </div>
                <p>18.2.0</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
