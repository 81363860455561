import React, { useContext, useState } from "react";
import AuthContext from "../../../Context/Context";
import styles from "./Hero6.module.css";
import Loader from "../../../loader/Loader";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const Hero6 = (props) => {
  const [loading, setloading] = useState(false);
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const ctx = useContext(AuthContext);
  // const data = {
  //   container: {
  //     style: `col-10 ${styles.boxen}`,
  //     value: "",
  //   },
  //   heading: {
  //     style: `${styles.boxen_visitus_h2}`,
  //     value: "Come Visit Us",
  //   },
  //   paragraph: {
  //     style: `${styles.boxen_visitus_p}`,
  //     value:
  //       "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout It is a long established fact that a reader will be distracted by the  readable content of a page when looking at its layout",
  //   },
  // };
  const data = {
    header: "Come Visit Us",
    backgroundColor: "",
    titlecolor: "",
    titlefont: "",
    para: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout It is a long established fact that a reader will be distracted by the  readable content of a page when looking at its layout",
    paracolor: "",
    parafont: "",
    buttontextcolor: "",
    buttontextfont: "",
    buttonbackground: "",
  };
  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined ? data : ctx.websiteData[props.id]
  );
  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);
  const onChangeHandler = (event) => {
    let val = event.target.value;
    setLocalData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: val,
      };
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div></div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData({
                ...localData,
                backgroundColor: "",
                titlecolor: "",
                titlefont: "",
                paracolor: "",
                parafont: "",
                buttontextcolor: "",
                buttontextfont: "",
                buttonbackground: "",
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Divider />
          <p variant="p" className=" my-2">
            Hero background
          </p>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                backgroundColor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.backgroundColor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                backgroundColor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Heading Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.titlefont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                titlefont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <Divider />
          <small>Text Color</small>
          <HexColorPicker
            color={localData?.titlecolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                titlecolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.titlecolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                titlecolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />

          <p variant="p" className="my-2">
            Para Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData?.parafont}
            onChange={(e) =>
              setLocalData({
                ...localData,
                parafont: e.target.value,
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small> Text Color</small>

          <HexColorPicker
            color={localData?.paracolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                paracolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.paracolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                paracolor: e.target.value,
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Button Styles
          </p>
          <small>Button Background</small>
          <HexColorPicker
            color={localData?.backgroundColor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                buttonbackground: color,
              })
            }
          />
          <small>Text-color</small>
          <HexColorPicker
            color={localData?.buttontextcolor}
            onChange={(color) =>
              setLocalData({
                ...localData,
                buttontextcolor: color,
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData?.buttontextcolor}
            onChange={(e) => {
              setLocalData({
                ...localData,
                buttontextcolor: e.target.value,
              });
            }}
            maxLength={10}
          />
        </div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };
  return (
    <>
      {ctx.isEditable ? (
        <>
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "#fff",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <Button
              onClick={(e) => {
                setStyle("full");
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
            >
              Change Styles
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      <section>
        <div
          className={clsx("", styles.boxen)}
          style={{ background: localData?.backgroundColor }}
        >
          <div className={` ${styles.visitus}`}>
            {ctx.isEditable ? (
              <>
                <input
                  id="header"
                  className={clsx(styles.inputHeading, localData?.titlefont)}
                  style={{
                    color: localData?.titlecolor,
                  }}
                  onChange={onChangeHandler}
                  value={localData.header}
                />
                <textarea
                  id="para"
                  className={clsx(styles.inputPara, localData?.parafont)}
                  style={{
                    color: localData?.paracolor,
                  }}
                  onChange={onChangeHandler}
                  value={localData.para}
                  maxLength={425}
                  // maxLength={}
                />
              </>
            ) : (
              <>
                <h2
                  className={clsx(
                    localData?.titlefont,
                    styles.boxen_visitus_h2
                  )}
                  style={{
                    color: localData?.titlecolor,
                  }}
                >
                  {localData.header}
                </h2>
                <p
                  className={clsx(styles.boxen_visitus_p, localData?.parafont)}
                  style={{
                    color: localData?.paracolor,
                  }}
                >
                  {localData.para}
                </p>
              </>
            )}
            <button
              type="button"
              style={{
                color: localData?.buttontextcolor,
                background: localData?.buttonbackground,
              }}
              className={`btn btn-warning mt-2 ${styles.boxen_visitus_btn}`}
            >
              Schedule a Tour
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero6;
