import React, { useContext, useState } from "react";
import styles from "./Card2.module.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import clsx from "clsx";
import { Update } from "../../../loader/Update";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { font } from "../../../utilitis/Fonts";

const Card2 = (props) => {
  // const classes = useStyles();

  const [loading, setloading] = useState(false);
  const [imageindex, setImageindex] = useState();
  const [defaultData, setDefaultdata] = useState();
  const [style, setStyle] = React.useState("full");

  const ctx = useContext(AuthContext);
  const cardData = [
    {
      title: "Enrollement",
      titlecolor: "black",
      titlefont: "",
      para: "It is a long established fact that a reader will be distracted",
      paracolor: "black",
      parafont: "",
      backgroundColor: "white",
      iconbackground: "",
      id: "0",
    },
    {
      title: "Curriculam",
      titlecolor: "black",
      titlefont: "",
      para: "It is a long established fact that a reader will be distracted",
      paracolor: "black",
      parafont: "",
      backgroundColor: "white",
      iconbackground: "",
      id: "1",
    },
    {
      title: "Programs",
      titlecolor: "black",
      titlefont: "",
      para: "It is a long established fact that a reader will be distracted",
      paracolor: "black",
      parafont: "",
      backgroundColor: "white",
      iconbackground: "",
      id: "2",
    },
  ];

  const [localData, setLocalData] = useState(
    ctx.websiteData[props.id] === undefined
      ? cardData
      : ctx.websiteData[props.id]
  );
  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of localData) {
      TempArr.push({
        ...obj,
        titlecolor: localData[index].titlecolor,
        titlefont: localData[index].titlefont,
        paracolor: localData[index].paracolor,
        parafont: localData[index].parafont,
        backgroundColor: localData[index].backgroundColor,
        iconbackground: localData[index].iconbackground,
      });
    }
    setLocalData(TempArr);
  }
  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? cardData : pageData[props.id]
      );
    }
  }, [ctx.selectedPage]);
  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setLocalData((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  titlecolor: "black",
                  titlefont: "",
                  paracolor: "black",
                  parafont: "",
                  backgroundColor: "white",
                  iconbackground: "",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>
          <Divider />

          <p variant="p" className="my-2">
            Background Styles
          </p>

          <HexColorPicker
            color={localData[imageindex].backgroundColor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.backgroundColor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className=" my-2">
            Subtitle1 Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].titlefont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].titlefont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={localData[imageindex]?.titlecolor}
            onChange={(color) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = color;
                return [...prevState];
              });
            }}
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.titlecolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
          <p variant="p" className="my-2">
            Subtitle2 Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].parafont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].parafont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small> Text Color</small>
          <HexColorPicker
            color={localData[imageindex].paracolor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = color;
                return [...prevState];
              })
            }
          />

          <input
            className="form-control my-2"
            value={localData[imageindex]?.paracolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />

          <p variant="p" className="my-2">
            Icon Styles
          </p>

          <HexColorPicker
            color={localData[imageindex].iconbackground}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].iconbackground = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.iconbackground}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].iconbackground = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />
          <Divider />
        </div>
      ) : (
        <div></div>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            if (style === "card") {
              setLocalData(defaultData);
              toggleDrawer(e, false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );

  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      title: "Programs",
      para: "It is a long established fact that a reader will be distracted",
      id: localData.length,
    };
    setLocalData((prevState) => {
      return [...prevState, updatedData];
    });
  };

  const removeCard = (value) => {
    setLocalData((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };

  let editable = (
    <>
      <div className={clsx("row position-relative", styles.cards)}>
        {localData.map((details, index) => (
          <div
            className={`col-md-3  ${styles.card} `}
            key={index}
            style={{ background: details?.backgroundColor }}
          >
            <Button
              onClick={(e) => {
                setStyle("card");
                setImageindex(index);
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
              className=""
            >
              Change Styles
            </Button>
            <div className={`${styles.cardin}`}>
              <div
                className={`${styles.round}`}
                style={{ background: details?.iconbackground }}
              >
                <i
                  className="fa fa-pencil-square-o icon"
                  aria-hidden="true"
                ></i>
              </div>
              <div
                onClick={() => removeCard(details.id)}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: 20,
                  cursor: "pointer",
                }}
              >
                <DeleteIcon
                  style={{
                    width: "2rem",
                    height: "2rem",
                    fill: "#dc3545",
                    padding: "5px",
                  }}
                />
              </div>
              <input
                type="text"
                onChange={(e) => onChangeHandler(e, details, index)}
                className={`${styles.inputHeading} ${details?.titlefont}`}
                style={{ color: details?.titlecolor }}
                id="title"
                value={details.title}
                maxLength={25}
              />
              <textarea
                onChange={(e) => onChangeHandler(e, details, index)}
                className={`${styles.inputPara} ${details?.parafont}`}
                style={{ color: details?.paracolor }}
                id="para"
                value={details.para}
              />
            </div>
          </div>
        ))}
        {localData.length < ctx.user?.Card_limit && (
          <div className={styles.addCard} onClick={addCard}>
            <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add
            Card
          </div>
        )}
      </div>
    </>
  );

  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };
  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <button
            className="btn px-5"
            onClick={onSaveHandler}
            style={{
              background: "#9e3a8ccc",
              fontSize: "20px",
              color: "#fff",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #00000036",
            }}
          >
            Save<i className="fa fa-save mx-2"></i>{" "}
          </button>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      {ctx.isEditable ? (
        editable
      ) : (
        <>
          <div className={clsx("row", styles.cards)}>
            {localData.map((details, index) => (
              <div
                style={{ background: details?.backgroundColor }}
                className={`col-md-3  ${styles.card} `}
                key={index}
              >
                <div className={`${styles.cardin}`}>
                  <div
                    className={`${styles.round}`}
                    style={{ background: details?.iconbackground }}
                  >
                    <i
                      className="fa fa-pencil-square-o icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <h2
                    className={details?.titlefont}
                    style={{ color: details?.titlecolor }}
                  >
                    {details.title}
                  </h2>
                  <p
                    className={details?.titlefont}
                    style={{ color: details?.paracolor }}
                  >
                    {details.para}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Card2;
