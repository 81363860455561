import React, { useContext, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import BannerImage1 from "../../../Assests/images/banner1.jpg";
import BannerImage2 from "../../../Assests/images/banner2.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import AuthContext from "../../../Context/Context";
import Loader from "../../../loader/Loader";
import clsx from "clsx";
import { toast } from "react-toastify";
import "./Hero2.css";
import { ReactComponent as DeleteIcon } from "../../../Assests/delete.svg";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";
import { HexColorPicker } from "react-colorful";
import { font, variants } from "../../../utilitis/Fonts";
const useStyles = makeStyles(() =>
  createStyles({
    addCard: {
      borderRadius: "1rem",
      position: "absolute",
      background: "#fff",
      padding: "1rem 2rem",
      cursor: "pointer",
      top: "1rem",
      color: "#9e3a8ccc",
      right: "1rem",
      boxShadow: "2px 2px 3px 0 #ccc",
    },

    introHeader: {
      background: "transparent",
      outline: 0,
      border: "none",
      // fontSize: "1.5rem !important",
      width: "100%",
      height: "fit-content",
      fontSize: "40px",
    },
    introText: {
      background: "transparent",
      outline: 0,
      border: "none",
      fontSize: "1rem !important",
      height: "fit-content",
      width: "100%",
    },
    inputFile: {
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: "0",
    },
    inputLabel: {
      position: "absolute",
      background: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      padding: "0.3rem",
      bottom: "0",
      right: "0",
      zIndex: 20,
      textAlign: "center",
      cursor: "pointer",
      "& i": {
        fontSize: "1.75rem",
      },
    },
    "@media (max-width: 600px)": {
      introText: {
        fontSize: "12px !important",
      },
      introHeader: {
        fontSize: "20px !important",
      },
    },
  })
);
export const Hero2 = (props) => {
  const ctx = useContext(AuthContext);
  const classes = useStyles();
  const location = useLocation();
  const [defaultData, setDefaultdata] = useState();
  const [imageindex, setImageindex] = useState();
  const [style, setStyle] = React.useState("full");

  const data = [
    {
      img: BannerImage1,
      header: "Intro header 1",
      titlecolor: "",
      titlefont: "",
      variant: "h2",
      para: "loerum ipsum is a dummy language for website content writing",
      paracolor: "",
      parafont: "",
      backgroundColor: "",
      id: "0",
    },
    {
      img: BannerImage2,
      header: "Intro header 2",
      titlecolor: "",
      titlefont: "",
      variant: "h2",
      para: "loerum ipsum is a dummy language for website content writing",
      paracolor: "",
      parafont: "",
      backgroundColor: "",
      id: "1",
    },
  ];

  let pageData = ctx.websiteData[ctx.selectedPage];
  const [localData, setLocalData] = useState(
    ctx.websiteData[ctx.selectedPage] !== undefined &&
      pageData[props.id] !== undefined
      ? pageData[props.id]
      : data
  );

  React.useEffect(() => {
    if (ctx.websiteData[ctx.selectedPage] !== undefined) {
      let pageData = ctx.websiteData[ctx.selectedPage];
      setLocalData(
        pageData[props.id] === undefined ? data : pageData[props.id]
      );
    }
  }, [ctx.selectedPage, ctx.websiteData]);

  const [loading, setloading] = useState(false);

  const options = {
    loop: true,
    margin: 0,
    dots: false,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 3000,
    autoplayHoverPause: true,
    mouseDrag: location.pathname.includes("layout") ? false : true,
    items: 1,
    touchDrag: false,
  };

  function ApplyAll(index) {
    let TempArr = [];
    for (const obj of localData) {
      TempArr.push({
        ...obj,
        titlecolor: localData[index].titlecolor,
        titlefont: localData[index].titlefont,
        paracolor: localData[index].paracolor,
        parafont: localData[index].parafont,
        backgroundColor: localData[index].backgroundColor,
      });
    }
    setLocalData(TempArr);
  }
  const onChangeHandler = (e, details, index) => {
    const tempEventInputs = JSON.parse(JSON.stringify(details));
    if (e.target) {
      tempEventInputs[e.target.id] = e.target.value;
    }
    setLocalData((prevState) => {
      prevState[index] = tempEventInputs;
      return [...prevState];
    });
  };

  const onImageChange = (e, i) => {
    // setError(null);

    let selected = e.target.files[0];
    const data = parseInt(selected.size);
    const imagesize = data / 1024 ** 2;
    var allowedExtensions = /(\.jpeg|\.png|\.jpg|\.tif|\.tiff)$/i;

    if (!selected) {
      // setError("Please select file");
      return;
    }

    if (!selected.type.includes("image")) {
      // setError("Please select image file");
      return;
    }
    if (imagesize > ctx.user?.Image_size) {
      toast.error(
        "Image size cannot be exceeded" + ctx.user?.Image_size + " mb"
      );
    } else if (!allowedExtensions.exec(selected?.name)) {
      toast.error("Please upload only images");
    } else {
      setloading(true);
      const storage = getStorage();
      let d = new Date().getTime();

      const uploadPath =
        ctx.userId +
        "/" +
        localData[i].header +
        localData[i].id +
        ctx.selectedPage +
        props.id; // upload path
      const storageRef = ref(storage, uploadPath); // create refernce to store data
      uploadBytes(storageRef, selected).then((snapshot) => {
        // console.log(snapshot);
        getDownloadURL(storageRef).then((url) => {
          setLocalData((prevState) => {
            let updatedData = null;
            updatedData = {
              ...prevState[i],
              img: url,
            };
            prevState[i] = updatedData;
            return [...prevState];
          });
          setTimeout(() => {
            setloading(false);
            toast.success(" Sucessfully Updated");
          }, 2000);
        });
      });
    } // setError(null);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      className="p-4"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={(e) => {
      //   toggleDrawer(e, false);
      // }}
    >
      {style !== "full" ? (
        <div>
          <Typography variant="h6" className="text-center my-2">
            Edit Section
          </Typography>
          <Button
            onClick={() => {
              setLocalData((prevState) => {
                let data = {
                  ...prevState[imageindex],
                  titlecolor: "",
                  titlefont: "",
                  parafont: "",
                  paracolor: "",
                  backgroundColor: "",
                };
                prevState[imageindex] = data;
                return [...prevState];
              });
            }}
          >
            {" "}
            Reset Default
          </Button>
          <Button onClick={() => ApplyAll(imageindex)}>Apply for All</Button>

          <Divider />
          <p variant="p" className=" my-2">
            Subtitle1 Styles
          </p>
          <small>Font-Family</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].titlefont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].titlefont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>
          <small>Text Color</small>

          <HexColorPicker
            color={localData[imageindex]?.titlecolor}
            onChange={(color) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = color;
                return [...prevState];
              });
            }}
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.titlecolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].titlecolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />
          <small> Font-Variant</small>
          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].variant}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].variant = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {variants?.map((variant) => (
              <option value={variant}>{variant}</option>
            ))}
          </select>

          <Divider />
          <p variant="p" className="my-2">
            Subtitle2 Styles
          </p>
          <small>Font-Family</small>

          <select
            className="form-control my-2"
            id="headerFont"
            value={localData[imageindex].parafont}
            onChange={(e) =>
              setLocalData((prevState) => {
                prevState[imageindex].parafont = e.target.value;
                return [...prevState];
              })
            }
          >
            <option>Choose...</option>
            {font?.map((font, index) => (
              <option value={font.classname} key={index}>
                {font.label}
              </option>
            ))}
          </select>

          <small>Text Color</small>
          <HexColorPicker
            color={localData[imageindex].paracolor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.paracolor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].paracolor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />
          <Divider />
          <Divider />
          <p variant="p" className="my-2">
            background Styles
          </p>

          <HexColorPicker
            color={localData[imageindex].backgroundColor}
            onChange={(color) =>
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = color;
                return [...prevState];
              })
            }
          />
          <input
            className="form-control my-2"
            value={localData[imageindex]?.backgroundColor}
            onChange={(e) => {
              setLocalData((prevState) => {
                prevState[imageindex].backgroundColor = e.target.value;
                return [...prevState];
              });
            }}
            maxLength={10}
          />

          <Divider />
        </div>
      ) : (
        <></>
      )}
      <div className="text-center m-2">
        <Button
          onClick={(e) => {
            setLocalData(defaultData);
            toggleDrawer(e, false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            toggleDrawer(e, false);
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
  const addCard = () => {
    toast.success("Card Added");
    let updatedData = {
      img: BannerImage1,
      header: "",
      para: "",
      id: localData.length,
    };
    setLocalData((prevState) => {
      return [...prevState, updatedData];
    });
  };
  const removeCard = (value) => {
    setLocalData((prevState) => {
      prevState = prevState.filter((item) => item.id !== value);
      return [...prevState];
    });
  };
  let editable = (
    <>
      <div className="position-relative">
        {localData?.map((details, index) => (
          <div key={index}>
            <Button
              onClick={(e) => {
                setStyle("card");
                setImageindex(index);
                setDefaultdata(localData);
                toggleDrawer(e, true);
              }}
              className=""
            >
              Change Styles
            </Button>
            <div
              className="d-sm-flex my-2 position-relative align-items-center justify-content-between w-100"
              style={{ height: "100vh", background: details?.backgroundColor }}
            >
              <input
                type="file"
                onChange={(e) => onImageChange(e, index)}
                className={classes.inputFile}
                id={details.id}
                name={details.header}
              />
              <label className={classes.inputLabel} htmlFor={details.id}>
                <i className="fa fa-upload"></i>
              </label>
              <div
                onClick={() => removeCard(details.id)}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: 20,
                  cursor: "pointer",
                }}
              >
                <DeleteIcon
                  style={{
                    width: "2rem",
                    height: "2rem",
                    fill: "#dc3545",
                    padding: "5px",
                  }}
                />
              </div>
              <div className="col-md-4 mx-auto p-0 mb-4 mb-sm-0 headline">
                <input
                  onChange={(e) => onChangeHandler(e, details, index)}
                  style={{
                    color: details?.titlecolor,
                    width: "75%",
                  }}
                  className={clsx(
                    "display-4 my-4 font-weight-bold",
                    details?.titlefont,
                    classes.introHeader
                  )}
                  id="header"
                  maxLength={100}
                  placeholder="Header"
                  value={details.header}
                />

                <textarea
                  onChange={(e) => onChangeHandler(e, details, index)}
                  style={{
                    color: details?.paracolor,
                    width: "75%",
                  }}
                  className={clsx(
                    details?.parafont,
                    classes.introText,
                    " text-uppercase mb-3 "
                  )}
                  id="para"
                  maxLength={300}
                  placeholder="text"
                  value={details.para}
                />
              </div>

              <div
                className="col-md-8 h-100 clipped"
                style={{
                  minHeight: "350px",
                  backgroundImage: `url(${details.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
        ))}
        {localData?.length < ctx.user?.Card_limit && (
          <div className={classes.addCard} onClick={addCard}>
            <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i> Add
            Card
          </div>
        )}
      </div>
    </>
  );
  const onSaveHandler = () => {
    setloading(true);
    ctx.updateData(localData, props.id);
    setTimeout(() => {
      setloading(false);
      toast.success("Sucessfully Updated");
    }, 2000);
  };
  return (
    <>
      {ctx.isEditable ? (
        <div className="row py-3 justify-content-end">
          <div className="row py-3 justify-content-end">
            <button
              className="btn px-5"
              onClick={onSaveHandler}
              style={{
                background: "#9e3a8ccc",
                fontSize: "20px",
                color: "white",
                borderRadius: "20px",
                boxShadow: "0 3px 6px #00000036",
              }}
            >
              Save<i className="fa fa-save mx-2"></i>{" "}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {loading && (
        <>
          <Loader />
        </>
      )}
      {ctx.isEditable && (
        <div>
          <React.Fragment>
            <SwipeableDrawer
              anchor="right"
              open={state.right}
              // onClose={(e) => {
              //   toggleDrawer(e, false);
              // }}
              onOpen={(e) => {
                toggleDrawer(e, true);
              }}
            >
              {state.right && list()}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      )}
      {ctx.isEditable ? (
        editable
      ) : (
        <OwlCarousel className="owl-theme" {...options} aut>
          {localData?.map((item, index) => (
            <div
              key={index}
              className="d-sm-flex align-items-center justify-content-between  w-100"
              style={{ height: "100vh", background: item.backgroundColor }}
            >
              <div className="col-lg-4 mx-auto p-0 mb-4 mb-sm-0 headline">
                <Typography
                  variant={item?.variant ? item?.variant : "h2"}
                  style={{
                    color: item?.titlecolor,
                  }}
                  className={clsx(
                    "display-4 my-4 font-weight-bold",
                    classes.introHeader,
                    item?.titlecolor
                  )}
                >
                  {item.header}{" "}
                </Typography>
                <p
                  style={{
                    color: item?.paracolor,
                  }}
                  className={clsx(item?.parafont, " text-uppercase mb-3 ")}
                >
                  {item.para}
                </p>
                {/* <a href="#" className="btn px-5 mt-3 py-3 text-white mt-3 mt-sm-0" style={{ borderRadius: "30px", backgroundColor: "#9B5DE5" }}>Get Started</a> */}
              </div>

              <div
                className="col-lg-8 h-100 clipped"
                style={{
                  minHeight: "350px",
                  backgroundImage: `url(${item.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          ))}
        </OwlCarousel>
      )}
    </>
  );
};
